import React, { Component } from "react";
import Authorisation from './../components/Authorisation'

class Savings extends Component {
    componentDidMount() { Authorisation.GetMain()?.onPathChanged(window.location.pathname); }
    render() {
        return (
            <div>
                <div className="summary-short">
                    Our reports can <strong>reduce CLO Feed Rate</strong>, <strong>reduce waste oil
                        disposal</strong>, increase the <strong>time between
                            overhauls</strong> and <strong>reduce fuel consumption</strong>.  In a competitive
                    market your company needs every advantage it can get.
                </div>
                <div className="column-left topoffset">
                    <h2>Cost Savings</h2>
                    <div className="primary-text">
                        <p>
                            As an industry, large two-stroke marine diesels used in over 30,000 ships worldwide,
                            waste in excess of <strong>US$2 billion</strong> in cylinder oil over lubrication
                            every year.&nbsp; In addition, over 1.25 million tonnes of unnecessary contaminated
                            drain oil need to be disposed.
                        </p>
                        <p>
                            Over-lubrication of cylinder lubricating oil in slow speed two stroke marine diesel
                            engines can cost ship managers and owners over US$100,000 per year per ship. The
                            Flame Diagnostic service from Flame Marine helps you prevent this loss by reducing
                            the cylinder lubricating oil feed rates in your ships to the lowest and safest levels;
                            fully in compliance with manufacturers guidelines. We do this by carefully
                            monitoring the performance characteristics of the engine and the precise chemical
                            composition of the cylinder drain oil and analyzing this information using novel
                            techniques proven by over 12 years of practical experience and currently used by
                            nearly 500 ships worldwide.
                        </p>
                        <p>
                            Working together with your chief engineers, we implement a gradual and safe process
                            of lubrication reduction and improving engine combustion. This is possible because
                            we not only look at the performance characteristics of the engine with the data
                            that you supply but also the chemistry of the combustion process itself using the
                            oil samples that you also supply. The combination of these allows us to build a
                            complex model of exactly what is happening within your engine and to advise you
                            on the best ways to improve combustion and reduce cylinder oil feed rates.
                        </p>
                        <p>
                            A byproduct of this whole process is a reduction in the amount of waste cylinder
                            drain oil that needs to be disposed and an increase in the reliability of your engines
                            together with the opportunity to increase the period between overhauls.
                            <br />
                        </p>
                        <p>
                            The Flame Diagnostic service is the world leader in this technology and is used
                            by the research departments of a number of the top names in the marine industry
                            as well as nearly 500 ships worldwide.
                        </p>
                    </div>
                    <h2>Cylinder Oil Over-Lubrication</h2>
                    <div className="primary-text">
                        <p>
                            To fully appreciate the impact on engines of cylinder oil over-lubrication when
                            combined with slightly imperfect combustion, we only need look at the pictures in
                            the right hand column. Over-lubrication by cylinder oil is one of the chief avoidable
                            costs. It causes piston deposits which decrease the efficiency of the combustion
                            process and increase engine wear as well as increasing the generation of NOx and
                            particulates which cause air pollution. Flame Diagnostic will also detect early
                            ring groove wear, blow by and skirt abrasion from minute traces of wear components
                            in the cylinder drain oil.
                        </p>
                        <p>
                            The Flame Diagnostic service from Flame Marine not only saves you money by reducing
                            cylinder oil feed rates to the lowest safe levels and scheduling maintenance when
                            your engines need it but by regularly monitoring the engine, it helps detect potential
                            problems long before they can be detected using conventional means by the engineering
                            crews on the ship. Flame Diagnostic works for the crews and allows you to deliver
                            the most cost effective service.
                        </p>
                    </div>
                </div>
                <div className="column-right">
                    <div className="topoffset">
                        <a href="/images/thumbs/OverhaulNew.jpg">
                            <img src="/images/thumbs/OverhaulNew.jpg" className="shadow-border" alt="Overhaul New" />
                        </a>
                        <div
                            className="caption small-text clear small-topoffset"
                        >This is a piston that has just been overhauled</div>
                    </div>

                    <div className="topoffset">
                        <a href="/images/thumbs/Overhaul66.jpg">
                            <img src="/images/thumbs/Overhaul66.jpg" className="shadow-border" alt="Overhaul after 66 hours" />
                        </a>
                        <div className="caption small-text clear small-topoffset">
                            A picture of the same piston after just 66 hours running with high cylinder lubricant
                            feed rate and unstable combustion. You can clearly see the start of build up of
                            deposits on the piston walls and within the piston ring grooves.
                        </div>
                    </div>
                    <div className="topoffset">
                        <a href="/images/thumbs/Piston30000.jpg" className="topoffset">
                            <img src="/images/thumbs/Piston30000.jpg" className="shadow-border" alt="Overhaul New" />
                        </a>
                        <div className="caption small-text clear small-topoffset">
                            This is a different piston but one which has been running using Flame Diagnostic
                            optimized feed rate and combustion. It has been in continual use for 30,000 hours
                            without an overhaul. Note the clean piston walls and absence of deposits in the
                            ring grooves.
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Savings;