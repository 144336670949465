import React, { Component } from "react";
import { NavLink, } from "react-router-dom";
import Authorisation from './../components/Authorisation'

class Home extends Component {
    componentDidMount() { Authorisation.GetMain()?.onPathChanged(window.location.pathname); }
    render() {
        return (
            <div>
                <div id="banner">
                    <img src="/images/banner-nodither.gif" alt="Flame Marine" />
                </div>
                <div className="column">
                    <h3>Save Costs</h3>
                    <p>
                        Engines that follow Flame Diagnostic's controlled reduction in cylinder
                        oil feed rates can save in excess of <strong>US$100,000 per year</strong> in direct lubricating oil costs as
                        well as reduced waste oil disposal and engine overhaul charges.
                    </p>
                    <NavLink to="/Savings">Learn More &gt;</NavLink>
                </div>
                <div className="column">
                    <h3>Environment</h3>
                    <p>
                        We can reduce your lubrication feed rate, and we can improve an engine's
                        overall combustion efficiency.
                        <br />Our reports can demonstrate your
                        compliance with NOx environmental regulations, <strong>without requiring any expensive third party products.</strong>
                    </p>
                    <NavLink to="/Environment">Learn More &gt;</NavLink>
                </div>
                <div className="column">
                    <h3>Who are we?</h3>
                    <p>
                        Flame Marine are the world leading providers of diagnostic services to
                        the marine and shipping industries. We work with <strong>ship owners, ship-managers, engine manufacturers and oil
                            suppliers
                        </strong>.
                    </p>
                    <NavLink to="/Company">Learn More &gt;</NavLink>
                </div>
            </div>
        );
    }
}

export default Home;