import React, { Component } from "react";
import { QueryCommand } from "@aws-sdk/client-dynamodb";
import Authorisation from '../components/Authorisation';
import { Guid } from 'js-guid';
import DisplayTable from '../components/DisplayTable';
import { decode } from 'uint8-to-base64';

export class FobasList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            msg: 'Loading...',
            items: [],
            listtype: this.props.listtype,
            loading: false,
        };
    }

    componentDidMount() {
        //console.log('componentDidMount: FobasList');
        Authorisation.GetMain()?.onPathChanged(window.location.pathname);
        this.loadItems(this.props.listtype);
        //return this.loadItems(this.props.listtype);
    }
    // async componentDidUpdate() {
    //     console.log('FobasList.componentDidUpdate: ' + window.location.search);

    //     await this.loadItems();
    // }
    async loadItems() {
        try {
            let s = Authorisation.ParseSearch();
            let type = this.state.listtype;
            let srchType = s.srchType;
            let srch = s.srch;

            if (this.state.locationSearch === window.location.search) {
                console.log('FobasList.loadItems.locationSearch: ' + window.location.search + ' [IGNORED]');
                return;
            }
            var client = Authorisation.GetDynamoDbClient();
            if (!client) {
                return;
            }
            console.log('FobasList.loadItems.locationSearch: ' + window.location.search + '[t="' + (srchType || '').toString() + '", srch="' + srch + '"]');

            this.setState({ loading: true, msg: 'Loading...', listtype: type, locationSearch: window.location.search });
            let from = new Date();
            from.setFullYear(from.getFullYear() - (type === 'feap' ? 1 : 3));
            var queryParams = {
                ScanIndexForward: false,
                ConsistentRead: false,
                ReturnConsumedCapacity: 'NONE',
                ProjectionExpression: 's,p,b,w,l,d8,d9,n10,d6,s8',
                TableName: 'FmsObjects8',
                ExpressionAttributeValues: {}
            };

            switch (srchType) {
                case 1:
                    let txt = decodeURIComponent(srch);
                    let parentid = decode(txt);
                    queryParams.KeyConditionExpression = 'p=:p';
                    queryParams.FilterExpression = 'q=:q';
                    queryParams.FilterExpression = 'q=:q1 or q=:q2';
                    queryParams.ExpressionAttributeValues[':q1'] = { 'N': '3' };
                    queryParams.ExpressionAttributeValues[':q2'] = { 'N': '0' };
                    queryParams.ExpressionAttributeValues[':p'] = { 'B': parentid };
                    break;
                default:
                    switch(type){
                        case 'feap':
                            queryParams.IndexName = 'IxQueue';
                            queryParams.KeyConditionExpression = 'q=:q';
                            queryParams.FilterExpression = 'a=:a AND s7=:s7 AND d1>=:d1';
                            queryParams.ExpressionAttributeValues[':a'] = { 'N': '1' };
                            queryParams.ExpressionAttributeValues[':s7'] = { 'S': 'FEAP' };
                            queryParams.ExpressionAttributeValues[':d1'] = { 'N': Authorisation.GetDateSeconds(from).toString() };
                            queryParams.ExpressionAttributeValues[':q'] = { 'N': '3' };
                            break;
                        case 'prog':
                            queryParams.IndexName = 'IxQueue';
                            queryParams.KeyConditionExpression = 'q=:q';
                            queryParams.FilterExpression = 'a=:a';
                            queryParams.ExpressionAttributeValues[':a'] = { 'N': '1' };
                            queryParams.ExpressionAttributeValues[':q'] = { 'N': '1283' };
                            break;
                        case 'setup':
                            queryParams.IndexName = 'IxQueue';
                            queryParams.KeyConditionExpression = 'q=:q';
                            queryParams.FilterExpression = 'a=:a';
                            queryParams.ExpressionAttributeValues[':a'] = { 'N': '1' };
                            queryParams.ExpressionAttributeValues[':q'] = { 'N':'515' };
                            break;
                        default:
                            queryParams.IndexName = 'IxQueue';
                            queryParams.KeyConditionExpression = 'q=:q';
                            queryParams.FilterExpression = 'a=:a AND s7=:s7 AND d1>=:d1';
                            queryParams.ExpressionAttributeValues[':a'] = { 'N': '1' };
                            queryParams.ExpressionAttributeValues[':s7'] = { 'S': 'Full' };
                            queryParams.ExpressionAttributeValues[':d1'] = { 'N': Authorisation.GetDateSeconds(from).toString() };
                            queryParams.ExpressionAttributeValues[':q'] = { 'N': '3' };
                            break;
                    }
                    break;
            }
            if (srchType === 2) {
                let index = 0;
                let s = '';
                srch.split(' ').forEach(S => {
                    s = S.trim().toUpperCase();
                    if (S.length > 1) {
                        queryParams.FilterExpression += " AND contains(s, :z" + index.toString() + ")";
                        queryParams.ExpressionAttributeValues[':z' + (index++).toString()] = { 'S': s };
                    }
                });
            }
            let items = [];
            let resp = null;
            do {
                resp = await client.send(new QueryCommand(queryParams));
                resp.Items.forEach(I => {
                    let vals = I.s.S.split('\t');
                    items.push({
                        BlobId: new Guid(I.b.B).toString().replace('-', ''),
                        ReportSampledDate: vals[NodeReport_SummarySampledDate],
                        ReportType: vals[NodeReport_SummaryReportType],
                        ShipName: vals[NodeShip_SummaryCurrent],
                        ShipDesignation: vals[NodeShip_SummaryEngineDesignation],
                        AddressName: vals[NodeShip_SummaryOrganisation],
                        ReportStatus: vals[NodeReport_SummaryStatus],
                        ReportId: new Guid(I.w.B),
                        ReportCompletionDate: Authorisation.GetDateFromSeconds(parseInt(I.l.N)).toISOString().substring(0, 10),
                        ReportLandedDate: I.d9 ? Authorisation.GetDateFromSeconds(parseInt(I.d9.N)).toISOString().substring(0, 10) : '',
                        ReportReceivedDate: I.d8 ? Authorisation.GetDateFromSeconds(parseInt(I.d8.N)).toISOString().substring(0, 10) : '',
                        ReportLaboratory: I.n10 && I.n10.N == 1 ? 'Shanghai' : 'Singapore',
                        OnHold: type === 'setup' && I.d6 && I.s8 && I.s8.S.length > 0 ? "[" + Authorisation.GetDateFromSeconds(parseInt(I.d6.N)).toISOString().substring(0, 10) + "] " + I.s8.S : ""
                    });
                });
                console.log('FobasList.loadItems: retrieved ' + resp.Items.length.toString() + ' rows');
                queryParams.ExclusiveStartKey = resp.LastEvaluatedKey;
            } while (queryParams.ExclusiveStartKey);
            items.sort(function (i1, i2) {
                if (i2.ReportSampledDate < i1.ReportSampledDate) { return -1; }
                if (i2.ReportSampledDate > i1.ReportSampledDate) { return 1; }
                return 0;
            });
            this.setState({
                msg: null,
                items: items,
                loading: false
            });
        }
        catch (err) {
            if (err) {
                if (err.message) {
                    let msg = err.stack || err.message;
                    this.setState(
                        {
                            msg: msg,
                            items: [],
                            loading: false,
                        });
                }
                else {
                    this.setState(
                        {
                            msg: 'Error:' + JSON.stringify(err),
                            items: [],
                            loading: false,
                        });
                }
            }
            else {
                this.setState(
                    {
                        msg: 'Error: Unknown error',
                        items: [],
                        loading: false,
                    });
            }

        }
    }
    sort = (e, name) => {
        let sortState = this.state?.sort || {};
        let s = sortState[this.props.ver];
        let descending = false;
        if (!s || s.name !== name) {
            if (name.endsWith('Date')) {
                descending = true;
            }
        }
        else {
            descending = !s.descending;
        }
        sortState[this.props.ver] = { name: name, descending: descending };
        this.setState({ sort: sortState });
        this.props.reports.sort(this.dynamicSort(name, descending));
    }
    sorticon = (name) => {
        let s = this.state?.sort[this.props.ver];
        if (!s || s.name !== name) {
            return <i className="fas fa-sort sortIconPosition" aria-hidden="true"></i>;
        }
        else {
            if (s.descending) {
                return <i className="fas fa-sort-up sortIconPosition" aria-hidden="true"></i>;
            }
            else {
                return <i className="fas fa-sort-down sortIconPosition" aria-hidden="true"></i>;
            }
        }
    }
    render() {
        return (
                <div>{this.state.loading
                    ? <p><span><span className='spinner-border spinner-border-sm' style={{ marginLeft: 10, marginRight: 5 }} role="status" aria-hidden="true"></span>Loading...</span></p>
                    : <DisplayTable className="DisplayTable" ver={this.state.listtype} items={this.state.items} msg={this.state.msg} />}
                </div>
            );
    }
}

export default FobasList;

const NodeShip_NumSummary = 9;
const NodeShip_SummaryOrganisation = 0;
const NodeShip_SummaryCurrent = 1;
const NodeShip_SummaryFormer = 2;
const NodeShip_SummaryEngineDesignation = 3;
const NodeShip_SummaryLubricator = 4;
const NodeShip_SummaryShipType = 5;
const NodeShip_SummaryNumCyls = 6;
const NodeShip_SummaryLastReport = 7;
const NodeShip_SummaryLastReportStatus = 8;

const NodeReport_SummarySampledDate = NodeShip_SummaryLastReport;
const NodeReport_SummaryStatus = NodeShip_SummaryLastReportStatus;
const NodeReport_SummaryOwner = NodeShip_NumSummary;
const NodeReport_SummaryCylinderLubricant = NodeShip_NumSummary + 1;
const NodeReport_SummarySystemOil = NodeShip_NumSummary + 2;
const NodeReport_SummaryLastAllocated = NodeShip_NumSummary + 3;
const NodeReport_SummaryLastAllocatedBy = NodeShip_NumSummary + 4;
const NodeReport_SummaryPriority = NodeShip_NumSummary + 5;
const NodeReport_SummaryNextReportDue = NodeShip_NumSummary + 6;
const NodeReport_SummaryReminderDate1 = NodeShip_NumSummary + 7;
const NodeReport_SummaryReminderDate2 = NodeShip_NumSummary + 8;
const NodeReport_SummaryReportType = NodeShip_NumSummary + 9;
const NodeReport_SummaryOnHoldDate = NodeShip_NumSummary + 10;
const NodeReport_SummaryOnHoldReason = NodeShip_NumSummary + 11;
const NodeReport_SummaryInterpretations = NodeShip_NumSummary + 12;
const NodeReport_SummaryInvoiceNo = NodeShip_NumSummary + 13;
const NodeReport_SummarySampleDeliveryAWB = NodeShip_NumSummary + 14;
const NodeReport_SummarySampleDeliveryInvoiceNo = NodeShip_NumSummary + 15;

