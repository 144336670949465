import React, { Component } from "react";
import { GenerateFuelMap } from '../../Support/GenerateFuelMap';

class FobasEngineFuel extends Component {
 
    render() {
        var r = this.props.data;
        var d = r ? new GenerateFuelMap(r) : {};
        return (
            <div id='fullscreen_scroller'>
                <div id='outer'>
                    <div id='inner'>
                        <table cellPadding="0" cellSpacing="0" className="t centreHoriz">
                            <colgroup>
                                <col style={{ width: '200px' }} />
                                <col style={{ width: '170px' }} />
                                <col style={{ width: '170px' }} />
                                <col style={{ width: '170px' }} />
                                <col style={{ width: '170px' }} />
                            </colgroup>
                            <tbody>
                                <tr className="r">
                                    <td colSpan="5" className="fm">{d.A1}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="5" className="fs">SAMPLE DETAILS: </td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Source:</td>
                                    <td className="ft">Before Fuel Oil Purifier</td>
                                    <td className="ft">After Fuel Oil Purifier #1</td>
                                    <td className="ft">Before Main Engine Fuel Pump</td>
                                    <td className="ft">After Fuel Oil Purifier #2</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Lab Number</td>
                                    <td className="fl">{d.B4}</td>
                                    <td className="fl">{d.C4}</td>
                                    <td className="fl">{d.D4}</td>
                                    <td className="fl">{d.E4}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Sample ID:</td>
                                    <td className="ft">FO</td>
                                    <td className="ft"></td>
                                    <td className="ft">FO</td>
                                    <td className="ft"></td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Date Samples Taken</td>
                                    <td className="fl">{(d.B6?.length > 10 ? d.B6.slice(0, 10) : d.B6)}</td>
                                    <td className="fl">{(d.C6?.length > 10 ? d.C6.slice(0, 10) : d.C6)}</td>
                                    <td className="fl">{(d.D6?.length > 10 ? d.D6.slice(0, 10) : d.D6)}</td>
                                    <td className="fl">{(d.E6?.length > 10 ? d.E6.slice(0, 10) : d.E6)}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Date Samples Landed</td>
                                    <td className="fl">{(d.B7?.length > 10 ? d.B7.slice(0, 10) : d.B7)}</td>
                                    <td className="fl">{(d.C7?.length > 10 ? d.C7.slice(0, 10) : d.C7)}</td>
                                    <td className="fl">{(d.D7?.length > 10 ? d.D7.slice(0, 10) : d.D7)}</td>
                                    <td className="fl">{(d.E7?.length > 10 ? d.E7.slice(0, 10) : d.E7)}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Place Landed</td>
                                    <td className="fl">{d.B8}</td>
                                    <td className="fl">{d.C8}</td>
                                    <td className="fl">{d.D8}</td>
                                    <td className="fl">{d.E8}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Date Samples Received</td>
                                    <td className="fl">{(d.B9?.length > 10 ? d.B9.slice(0, 10) : d.B9)}</td>
                                    <td className="fl">{(d.C9?.length > 10 ? d.C9.slice(0, 10) : d.C9)}</td>
                                    <td className="fl">{(d.D9?.length > 10 ? d.D9.slice(0, 10) : d.D9)}</td>
                                    <td className="fl">{(d.E9?.length > 10 ? d.E9.slice(0, 10) : d.E9)}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Date Sample Tested</td>
                                    <td className="fl">{(d.B10?.length > 10 ? d.B10.slice(0, 10) : d.B10)}</td>
                                    <td className="fl">{(d.C10?.length > 10 ? d.C10.slice(0, 10) : d.C10)}</td>
                                    <td className="fl">{(d.D10?.length > 10 ? d.D10.slice(0, 10) : d.D10)}</td>
                                    <td className="fl">{(d.E10?.length > 10 ? d.E10.slice(0, 10) : d.E10)}</td>
                                </tr>
                                <tr className="r">
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="5" className="fs">Analysis Results</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Density @15degC</td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Kinematic Viscosity@50°C</td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                    <td className="fl">{d.D14}</td>
                                    <td className="fg"></td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Water vol%</td>
                                    <td className="fl">{d.B15}</td>
                                    <td className="fl">{d.C15}</td>
                                    <td className="fl">{d.D15}</td>
                                    <td className="fl">{d.E15}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">MCR %(mass)</td>
                                    <td className="fl">{d.B16}</td>
                                    <td className="fl">{d.C16}</td>
                                    <td className="fl">{d.D16}</td>
                                    <td className="fl">{d.E16}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Sulphur (%mass)</td>
                                    <td className="fl">{d.B17}</td>
                                    <td className="fl">{d.C17}</td>
                                    <td className="fl">{d.D17}</td>
                                    <td className="fl">{d.E17}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">TSP %(mm)</td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">TSE %(mm)</td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">ASH %(mm)</td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Vanadium</td>
                                    <td className="fl">{d.B21}</td>
                                    <td className="fl">{d.C21}</td>
                                    <td className="fl">{d.D21}</td>
                                    <td className="fl">{d.E21}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Sodium</td>
                                    <td className="fl">{d.B22}</td>
                                    <td className="fl">{d.C22}</td>
                                    <td className="fl">{d.D22}</td>
                                    <td className="fl">{d.E22}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Aluminium</td>
                                    <td className="fl">{d.B23}</td>
                                    <td className="fl">{d.C23}</td>
                                    <td className="fl">{d.D23}</td>
                                    <td className="fl">{d.E23}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Silicon</td>
                                    <td className="fl">{d.B24}</td>
                                    <td className="fl">{d.C24}</td>
                                    <td className="fl">{d.D24}</td>
                                    <td className="fl">{d.E24}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Iron</td>
                                    <td className="fl">{d.B25}</td>
                                    <td className="fl">{d.C25}</td>
                                    <td className="fl">{d.D25}</td>
                                    <td className="fl">{d.E25}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Nickel</td>
                                    <td className="fl">{d.B26}</td>
                                    <td className="fl">{d.C26}</td>
                                    <td className="fl">{d.D26}</td>
                                    <td className="fl">{d.E26}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Calcium</td>
                                    <td className="fl">{d.B27}</td>
                                    <td className="fl">{d.C27}</td>
                                    <td className="fl">{d.D27}</td>
                                    <td className="fl">{d.E27}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Magnesium</td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Lead</td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Zinc</td>
                                    <td className="fl">{d.B30}</td>
                                    <td className="fl">{d.C30}</td>
                                    <td className="fl">{d.D30}</td>
                                    <td className="fl">{d.E30}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Phosphorus</td>
                                    <td className="fl">{d.B31}</td>
                                    <td className="fl">{d.C31}</td>
                                    <td className="fl">{d.D31}</td>
                                    <td className="fl">{d.E31}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Barium</td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Chromium</td>
                                    <td className="fl">{d.B33}</td>
                                    <td className="fl">{d.C33}</td>
                                    <td className="fl">{d.D33}</td>
                                    <td className="fl">{d.E33}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Copper</td>
                                    <td className="fl">{d.B34}</td>
                                    <td className="fl">{d.C34}</td>
                                    <td className="fl">{d.D34}</td>
                                    <td className="fl">{d.E34}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Tin</td>
                                    <td className="fl">{d.B35}</td>
                                    <td className="fl">{d.C35}</td>
                                    <td className="fl">{d.D35}</td>
                                    <td className="fl">{d.E35}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Molybdenum </td>
                                    <td className="fl">{d.B36}</td>
                                    <td className="fl">{d.C36}</td>
                                    <td className="fl">{d.D36}</td>
                                    <td className="fl">{d.E36}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Boron</td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Titanium</td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">CCAI</td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Al+Si</td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                    <td className="fg"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default FobasEngineFuel;
