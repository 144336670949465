import React, { Component } from "react";

class FobasEngineGraphs extends Component {
    render() {
        return (
            <div className="graphs_scroller" id='fullscreen_scroller'>
                <div id='outer'>
                    <div id='inner'>
                        <div onClick={(e) => this.show(e)} dangerouslySetInnerHTML={this.htmlTest()} />
                    </div>
                </div>
            </div>
        )
    }
    htmlTest() {
        return { __html: this.props.data.Graphs };
    }
    show(e) {
        const el = e.target;
        if (el && el.nodeName.toLowerCase() === "li" && el.id.length === 33) {
            this.selectGraphSet(el.id.slice(1, 33)); // remove leading 'i'
        }
    }
    selectGraphSet(id) {
        let items = document.querySelectorAll('.graphSetListItem');
        items.forEach(function (item) {
            item.classList.remove('selected');
        });
        items = document.querySelectorAll('.graphSetContainer');
        items.forEach(function (item) {
            item.classList.add('hidden');
        });
        document.getElementById(id).classList.remove('hidden');
        document.getElementById('i' + id).classList.add('selected');
    }
}

export default FobasEngineGraphs;
