import React, { Component } from "react";

class FobasEngineShip extends Component {
    render() {
        var r = this.props.data;
 
        var s = r.Ship;
        return (
            <div id='fullscreen_scroller'>
                <div id='outer'>
                    <div id='inner'>
                        <table cellPadding="0" cellSpacing="0" className="t centreHoriz">
                            <colgroup>
                                <col style={{ width: '86px' }} span="14" />
                            </colgroup>
                            <tbody>
                                <tr className="r">
                                    <td rowSpan="3" colSpan="14" className="sh">Ship Details</td>
                                    <td className="sd"></td>
                                </tr>
                                <tr className="r">
                                    <td className="sd"></td>
                                </tr>
                                <tr className="r">
                                    <td className="sd"></td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="8" className="st"></td>
                                    <td colSpan="6" className="st">Office Only</td>
                                    <td className="sd"></td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="3" className="sl">Ship Name</td>
                                    <td colSpan="3" className="sl">Former Names</td>
                                    <td colSpan="2" className="sl">IMO Number</td>
                                    <td colSpan="2" className="sl">Default Report Type</td>
                                    <td className="sl">Contract</td>
                                    <td className="sl">Status</td>
                                    <td className="sl">Off.Code</td>
                                    <td className="sl">Off.Ref.</td>
                                    <td className="sd"></td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="3" className="sd">{s.Ship_SSF_CurrentShipName}</td>
                                    <td colSpan="3" className="sd">{s.Ship_SSF_FormerShipNames}</td>
                                    <td colSpan="2" className="sd">{s.Ship_SSF_ImoNumber}</td>
                                    <td colSpan="2" className="sd">Full</td>
                                    <td className="sd"></td>
                                    <td className="sd">{s.Ship_Active}</td>
                                    <td className="sd">{s.Ship_OfficeCode}</td>
                                    <td className="sd">{s.Ship_OfficeReference}</td>
                                    <td className="sd"></td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="14" className="sl"></td>
                                    <td className="sd"></td>
                                </tr>
                                <tr className="r">
                                    <td className="sl">Num Cyls.</td>
                                    <td className="sl">Num T/C's</td>
                                    <td className="sl">Designer</td>
                                    <td colSpan="2" className="sl">Engine Designation</td>
                                    <td colSpan="2" className="sl">Lubricator</td>
                                    <td className="sl">MCR (kW)</td>
                                    <td className="sl">RPM@MCR</td>
                                    <td className="sl">Feed/Units</td>
                                    <td className="sl">Power/Units</td>
                                    <td className="sl">CLO Cons.</td>
                                    <td className="sl">Engine</td>
                                    <td className="sl">Serial No</td>
                                    <td className="sd"></td>
                                </tr>
                                <tr className="r">
                                    <td className="sd">{s.Ship_SSF_NoOfCylinders}</td>
                                    <td className="sd">{s.Ship_SSF_NoOfTurboChargers}</td>
                                    <td className="sd">{s.Ship_SSF_EngineDesigner}</td>
                                    <td colSpan="2" className="sd">{s.Ship_SSF_EngineDesignation}</td>
                                    <td colSpan="2" className="sd">{s.Ship_SSF_EngineLubricator}</td>
                                    <td className="sd">{s.Ship_SSF_MCR_KW?.toFixed(0)}</td>
                                    <td className="sd">{s.Ship_SSF_MCR_RPM.toFixed(1)}</td>
                                    <td className="sd">{s.Ship_SSF_CLOFeedRateCustUnits}</td>
                                    <td className="sd">{s.Ship_SSF_PowerCustUnits}</td>
                                    <td className="sd">{s.Ship_SSF_CLOConsumptionCustUnits}</td>
                                    <td className="sd">{s.Ship_SSF_EngineCode}</td>
                                    <td className="sd">{s.Ship_SSF_EngineSerialNo}</td>
                                    <td className="sd"></td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="14" className="sl"></td>
                                    <td className="sd"></td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="sl">Ship Type</td>
                                    <td colSpan="2" className="sl">Design Size</td>
                                    <td className="sl">Date of Build</td>
                                    <td colSpan="2" className="sl">Gross Tonnage</td>
                                    <td colSpan="2" className="sl">Ship Builder</td>
                                    <td colSpan="2" className="sl">Yard Number</td>
                                    <td colSpan="2" className="sl">Engine Builder</td>
                                    <td className="sl">Flag Country</td>
                                    <td className="sd"></td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="sd"></td>
                                    <td colSpan="2" className="sd"></td>
                                    <td className="sd">{(s.Ship_SSF_DateOfBuild?.length > 10 ? s.Ship_SSF_DateOfBuild.slice(0, 10) : s.Ship_SSF_DateOfBuild)}</td>
                                    <td colSpan="2" className="sd">{s.Ship_SSF_GrossTonnage}</td>
                                    <td colSpan="2" className="sd">{s.Ship_SSF_ShipBuilder}</td>
                                    <td colSpan="2" className="sd"></td>
                                    <td colSpan="2" className="sd">{s.Ship_SSF_EngineBuilder}</td>
                                    <td className="sd"></td>
                                    <td className="sd"></td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="5" className="st">Company</td>
                                    <td rowSpan="2" colSpan="9" className="sl"></td>
                                    <td className="sd"></td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="5" className="sd">{s.Ship_SSF_ManagerName}</td>
                                    <td className="sd"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default FobasEngineShip;
