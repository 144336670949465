export class GenerateOilMap {
    constructor(obj) { this.o = obj.Reports[0]; }
    GetCylinder(i) {
        return this.o.Report_Cylinders.length > i ? this.o.Report_Cylinders[i] : null;
    }
    get O10() { return this.o.Report_Oil_SystemUsed_AnalysisLabNo; };
    get O13() { return this.o.Report_Oil_SystemUsed_Viscosity; };
    get O14() { return this.o.Report_Oil_SystemUsed_Water; };
    get O15() { return this.o.Report_Oil_SystemUsed_TBN; };
    get O16() { return this.o.Report_Oil_SystemUsed_SootyInsolubles; };
    get O17() { return this.o.Report_Oil_SystemUsed_Dispersancy; };
    get O20() { return this.o.Report_Oil_SystemUsed_Iron; };
    get O21() { return this.o.Report_Oil_SystemUsed_Lead; };
    get O22() { return this.o.Report_Oil_SystemUsed_Copper; };
    get O23() { return this.o.Report_Oil_SystemUsed_Chromium; };
    get O24() { return this.o.Report_Oil_SystemUsed_Aluminium; };
    get O25() { return this.o.Report_Oil_SystemUsed_Nickel; };
    get O26() { return this.o.Report_Oil_SystemUsed_Silver; };
    get O27() { return this.o.Report_Oil_SystemUsed_Tin; };
    get O28() { return this.o.Report_Oil_SystemUsed_Silicon; };
    get O29() { return this.o.Report_Oil_SystemUsed_Boron; };
    get O30() { return this.o.Report_Oil_SystemUsed_Sodium; };
    get O31() { return this.o.Report_Oil_SystemUsed_Phosphorus; };
    get O32() { return this.o.Report_Oil_SystemUsed_Zinc; };
    get O33() { return this.o.Report_Oil_SystemUsed_Calcium; };
    get O34() { return this.o.Report_Oil_SystemUsed_Barium; };
    get O35() { return this.o.Report_Oil_SystemUsed_Magnesium; };
    get O36() { return this.o.Report_Oil_SystemUsed_Titanium; };
    get O37() { return this.o.Report_Oil_SystemUsed_Molybdenum; };
    get O38() { return this.o.Report_Oil_SystemUsed_Vanadium; };
    get P10() { return this.o.Report_Oil_SystemNew_AnalysisLabNo; };
    get P13() { return this.o.Report_Oil_SystemNew_Viscosity; };
    get P14() { return this.o.Report_Oil_SystemNew_Water; };
    get P15() { return this.o.Report_Oil_SystemNew_TBN; };
    get P16() { return this.o.Report_Oil_SystemNew_SootyInsolubles; };
    get P17() { return this.o.Report_Oil_SystemNew_Dispersancy; };
    get P20() { return this.o.Report_Oil_SystemNew_Iron; };
    get P21() { return this.o.Report_Oil_SystemNew_Lead; };
    get P22() { return this.o.Report_Oil_SystemNew_Copper; };
    get P23() { return this.o.Report_Oil_SystemNew_Chromium; };
    get P24() { return this.o.Report_Oil_SystemNew_Aluminium; };
    get P25() { return this.o.Report_Oil_SystemNew_Nickel; };
    get P26() { return this.o.Report_Oil_SystemNew_Silver; };
    get P27() { return this.o.Report_Oil_SystemNew_Tin; };
    get P28() { return this.o.Report_Oil_SystemNew_Silicon; };
    get P29() { return this.o.Report_Oil_SystemNew_Boron; };
    get P30() { return this.o.Report_Oil_SystemNew_Sodium; };
    get P31() { return this.o.Report_Oil_SystemNew_Phosphorus; };
    get P32() { return this.o.Report_Oil_SystemNew_Zinc; };
    get P33() { return this.o.Report_Oil_SystemNew_Calcium; };
    get P34() { return this.o.Report_Oil_SystemNew_Barium; };
    get P35() { return this.o.Report_Oil_SystemNew_Magnesium; };
    get P36() { return this.o.Report_Oil_SystemNew_Titanium; };
    get P37() { return this.o.Report_Oil_SystemNew_Molybdenum; };
    get P38() { return this.o.Report_Oil_SystemNew_Vanadium; };
    get B10() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_AnalysisLabNo; };
    get C10() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_AnalysisLabNo; };
    get D10() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_AnalysisLabNo; };
    get E10() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_AnalysisLabNo; };
    get F10() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_AnalysisLabNo; };
    get G10() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_AnalysisLabNo; };
    get H10() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_AnalysisLabNo; };
    get I10() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_AnalysisLabNo; };
    get J10() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_AnalysisLabNo; };
    get K10() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_AnalysisLabNo; };
    get L10() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_AnalysisLabNo; };
    get M10() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_AnalysisLabNo; };
    get B13() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_Viscosity_Raw; };
    get C13() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_Viscosity_Raw; };
    get D13() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_Viscosity_Raw; };
    get E13() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_Viscosity_Raw; };
    get F13() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_Viscosity_Raw; };
    get G13() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_Viscosity_Raw; };
    get H13() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_Viscosity_Raw; };
    get I13() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_Viscosity_Raw; };
    get J13() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_Viscosity_Raw; };
    get K13() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_Viscosity_Raw; };
    get L13() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_Viscosity_Raw; };
    get M13() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_Viscosity_Raw; };
    get B14() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_Water_Raw; };
    get C14() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_Water_Raw; };
    get D14() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_Water_Raw; };
    get E14() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_Water_Raw; };
    get F14() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_Water_Raw; };
    get G14() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_Water_Raw; };
    get H14() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_Water_Raw; };
    get I14() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_Water_Raw; };
    get J14() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_Water_Raw; };
    get K14() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_Water_Raw; };
    get L14() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_Water_Raw; };
    get M14() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_Water_Raw; };
    get B15() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_TBN_Raw; };
    get C15() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_TBN_Raw; };
    get D15() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_TBN_Raw; };
    get E15() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_TBN_Raw; };
    get F15() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_TBN_Raw; };
    get G15() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_TBN_Raw; };
    get H15() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_TBN_Raw; };
    get I15() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_TBN_Raw; };
    get J15() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_TBN_Raw; };
    get K15() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_TBN_Raw; };
    get L15() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_TBN_Raw; };
    get M15() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_TBN_Raw; };
    get B16() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_SootyInsolubles_Raw; };
    get C16() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_SootyInsolubles_Raw; };
    get D16() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_SootyInsolubles_Raw; };
    get E16() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_SootyInsolubles_Raw; };
    get F16() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_SootyInsolubles_Raw; };
    get G16() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_SootyInsolubles_Raw; };
    get H16() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_SootyInsolubles_Raw; };
    get I16() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_SootyInsolubles_Raw; };
    get J16() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_SootyInsolubles_Raw; };
    get K16() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_SootyInsolubles_Raw; };
    get L16() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_SootyInsolubles_Raw; };
    get M16() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_SootyInsolubles_Raw; };
    get B17() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_Dispersancy_Raw; };
    get C17() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_Dispersancy_Raw; };
    get D17() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_Dispersancy_Raw; };
    get E17() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_Dispersancy_Raw; };
    get F17() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_Dispersancy_Raw; };
    get G17() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_Dispersancy_Raw; };
    get H17() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_Dispersancy_Raw; };
    get I17() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_Dispersancy_Raw; };
    get J17() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_Dispersancy_Raw; };
    get K17() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_Dispersancy_Raw; };
    get L17() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_Dispersancy_Raw; };
    get M17() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_Dispersancy_Raw; };
    get B20() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_Iron_Raw; };
    get C20() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_Iron_Raw; };
    get D20() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_Iron_Raw; };
    get E20() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_Iron_Raw; };
    get F20() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_Iron_Raw; };
    get G20() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_Iron_Raw; };
    get H20() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_Iron_Raw; };
    get I20() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_Iron_Raw; };
    get J20() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_Iron_Raw; };
    get K20() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_Iron_Raw; };
    get L20() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_Iron_Raw; };
    get M20() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_Iron_Raw; };
    get B21() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_Lead_Raw; };
    get C21() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_Lead_Raw; };
    get D21() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_Lead_Raw; };
    get E21() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_Lead_Raw; };
    get F21() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_Lead_Raw; };
    get G21() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_Lead_Raw; };
    get H21() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_Lead_Raw; };
    get I21() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_Lead_Raw; };
    get J21() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_Lead_Raw; };
    get K21() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_Lead_Raw; };
    get L21() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_Lead_Raw; };
    get M21() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_Lead_Raw; };
    get B22() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_Copper_Raw; };
    get C22() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_Copper_Raw; };
    get D22() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_Copper_Raw; };
    get E22() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_Copper_Raw; };
    get F22() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_Copper_Raw; };
    get G22() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_Copper_Raw; };
    get H22() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_Copper_Raw; };
    get I22() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_Copper_Raw; };
    get J22() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_Copper_Raw; };
    get K22() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_Copper_Raw; };
    get L22() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_Copper_Raw; };
    get M22() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_Copper_Raw; };
    get B23() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_Chromium_Raw; };
    get C23() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_Chromium_Raw; };
    get D23() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_Chromium_Raw; };
    get E23() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_Chromium_Raw; };
    get F23() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_Chromium_Raw; };
    get G23() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_Chromium_Raw; };
    get H23() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_Chromium_Raw; };
    get I23() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_Chromium_Raw; };
    get J23() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_Chromium_Raw; };
    get K23() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_Chromium_Raw; };
    get L23() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_Chromium_Raw; };
    get M23() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_Chromium_Raw; };
    get B24() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_Aluminium_Raw; };
    get C24() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_Aluminium_Raw; };
    get D24() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_Aluminium_Raw; };
    get E24() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_Aluminium_Raw; };
    get F24() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_Aluminium_Raw; };
    get G24() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_Aluminium_Raw; };
    get H24() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_Aluminium_Raw; };
    get I24() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_Aluminium_Raw; };
    get J24() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_Aluminium_Raw; };
    get K24() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_Aluminium_Raw; };
    get L24() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_Aluminium_Raw; };
    get M24() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_Aluminium_Raw; };
    get B25() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_Nickel_Raw; };
    get C25() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_Nickel_Raw; };
    get D25() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_Nickel_Raw; };
    get E25() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_Nickel_Raw; };
    get F25() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_Nickel_Raw; };
    get G25() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_Nickel_Raw; };
    get H25() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_Nickel_Raw; };
    get I25() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_Nickel_Raw; };
    get J25() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_Nickel_Raw; };
    get K25() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_Nickel_Raw; };
    get L25() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_Nickel_Raw; };
    get M25() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_Nickel_Raw; };
    get B26() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_Silver_Raw; };
    get C26() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_Silver_Raw; };
    get D26() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_Silver_Raw; };
    get E26() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_Silver_Raw; };
    get F26() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_Silver_Raw; };
    get G26() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_Silver_Raw; };
    get H26() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_Silver_Raw; };
    get I26() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_Silver_Raw; };
    get J26() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_Silver_Raw; };
    get K26() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_Silver_Raw; };
    get L26() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_Silver_Raw; };
    get M26() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_Silver_Raw; };
    get B27() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_Tin_Raw; };
    get C27() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_Tin_Raw; };
    get D27() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_Tin_Raw; };
    get E27() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_Tin_Raw; };
    get F27() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_Tin_Raw; };
    get G27() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_Tin_Raw; };
    get H27() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_Tin_Raw; };
    get I27() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_Tin_Raw; };
    get J27() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_Tin_Raw; };
    get K27() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_Tin_Raw; };
    get L27() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_Tin_Raw; };
    get M27() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_Tin_Raw; };
    get B28() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_Silicon_Raw; };
    get C28() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_Silicon_Raw; };
    get D28() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_Silicon_Raw; };
    get E28() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_Silicon_Raw; };
    get F28() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_Silicon_Raw; };
    get G28() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_Silicon_Raw; };
    get H28() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_Silicon_Raw; };
    get I28() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_Silicon_Raw; };
    get J28() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_Silicon_Raw; };
    get K28() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_Silicon_Raw; };
    get L28() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_Silicon_Raw; };
    get M28() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_Silicon_Raw; };
    get B29() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_Boron_Raw; };
    get C29() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_Boron_Raw; };
    get D29() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_Boron_Raw; };
    get E29() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_Boron_Raw; };
    get F29() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_Boron_Raw; };
    get G29() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_Boron_Raw; };
    get H29() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_Boron_Raw; };
    get I29() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_Boron_Raw; };
    get J29() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_Boron_Raw; };
    get K29() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_Boron_Raw; };
    get L29() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_Boron_Raw; };
    get M29() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_Boron_Raw; };
    get B30() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_Sodium_Raw; };
    get C30() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_Sodium_Raw; };
    get D30() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_Sodium_Raw; };
    get E30() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_Sodium_Raw; };
    get F30() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_Sodium_Raw; };
    get G30() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_Sodium_Raw; };
    get H30() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_Sodium_Raw; };
    get I30() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_Sodium_Raw; };
    get J30() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_Sodium_Raw; };
    get K30() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_Sodium_Raw; };
    get L30() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_Sodium_Raw; };
    get M30() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_Sodium_Raw; };
    get B31() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_Phosphorus_Raw; };
    get C31() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_Phosphorus_Raw; };
    get D31() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_Phosphorus_Raw; };
    get E31() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_Phosphorus_Raw; };
    get F31() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_Phosphorus_Raw; };
    get G31() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_Phosphorus_Raw; };
    get H31() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_Phosphorus_Raw; };
    get I31() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_Phosphorus_Raw; };
    get J31() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_Phosphorus_Raw; };
    get K31() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_Phosphorus_Raw; };
    get L31() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_Phosphorus_Raw; };
    get M31() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_Phosphorus_Raw; };
    get B32() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_Zinc_Raw; };
    get C32() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_Zinc_Raw; };
    get D32() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_Zinc_Raw; };
    get E32() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_Zinc_Raw; };
    get F32() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_Zinc_Raw; };
    get G32() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_Zinc_Raw; };
    get H32() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_Zinc_Raw; };
    get I32() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_Zinc_Raw; };
    get J32() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_Zinc_Raw; };
    get K32() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_Zinc_Raw; };
    get L32() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_Zinc_Raw; };
    get M32() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_Zinc_Raw; };
    get B33() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_Calcium_Raw; };
    get C33() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_Calcium_Raw; };
    get D33() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_Calcium_Raw; };
    get E33() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_Calcium_Raw; };
    get F33() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_Calcium_Raw; };
    get G33() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_Calcium_Raw; };
    get H33() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_Calcium_Raw; };
    get I33() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_Calcium_Raw; };
    get J33() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_Calcium_Raw; };
    get K33() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_Calcium_Raw; };
    get L33() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_Calcium_Raw; };
    get M33() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_Calcium_Raw; };
    get B34() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_Barium_Raw; };
    get C34() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_Barium_Raw; };
    get D34() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_Barium_Raw; };
    get E34() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_Barium_Raw; };
    get F34() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_Barium_Raw; };
    get G34() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_Barium_Raw; };
    get H34() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_Barium_Raw; };
    get I34() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_Barium_Raw; };
    get J34() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_Barium_Raw; };
    get K34() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_Barium_Raw; };
    get L34() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_Barium_Raw; };
    get M34() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_Barium_Raw; };
    get B35() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_Magnesium_Raw; };
    get C35() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_Magnesium_Raw; };
    get D35() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_Magnesium_Raw; };
    get E35() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_Magnesium_Raw; };
    get F35() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_Magnesium_Raw; };
    get G35() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_Magnesium_Raw; };
    get H35() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_Magnesium_Raw; };
    get I35() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_Magnesium_Raw; };
    get J35() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_Magnesium_Raw; };
    get K35() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_Magnesium_Raw; };
    get L35() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_Magnesium_Raw; };
    get M35() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_Magnesium_Raw; };
    get B36() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_Titanium_Raw; };
    get C36() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_Titanium_Raw; };
    get D36() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_Titanium_Raw; };
    get E36() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_Titanium_Raw; };
    get F36() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_Titanium_Raw; };
    get G36() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_Titanium_Raw; };
    get H36() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_Titanium_Raw; };
    get I36() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_Titanium_Raw; };
    get J36() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_Titanium_Raw; };
    get K36() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_Titanium_Raw; };
    get L36() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_Titanium_Raw; };
    get M36() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_Titanium_Raw; };
    get B37() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_Molybdenum_Raw; };
    get C37() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_Molybdenum_Raw; };
    get D37() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_Molybdenum_Raw; };
    get E37() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_Molybdenum_Raw; };
    get F37() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_Molybdenum_Raw; };
    get G37() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_Molybdenum_Raw; };
    get H37() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_Molybdenum_Raw; };
    get I37() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_Molybdenum_Raw; };
    get J37() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_Molybdenum_Raw; };
    get K37() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_Molybdenum_Raw; };
    get L37() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_Molybdenum_Raw; };
    get M37() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_Molybdenum_Raw; };
    get B38() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_Vanadium_Raw; };
    get C38() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_Vanadium_Raw; };
    get D38() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_Vanadium_Raw; };
    get E38() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_Vanadium_Raw; };
    get F38() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_Vanadium_Raw; };
    get G38() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_Vanadium_Raw; };
    get H38() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_Vanadium_Raw; };
    get I38() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_Vanadium_Raw; };
    get J38() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_Vanadium_Raw; };
    get K38() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_Vanadium_Raw; };
    get L38() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_Vanadium_Raw; };
    get M38() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_Vanadium_Raw; };
    get N10() { return this.o.Report_Oil_CylinderNew_AnalysisLabNo; };
    get N13() { return this.o.Report_Oil_CylinderNew_Viscosity; };
    get N14() { return this.o.Report_Oil_CylinderNew_Water; };
    get N15() { return this.o.Report_Oil_CylinderNew_TBN; };
    get N16() { return this.o.Report_Oil_CylinderNew_SootyInsolubles; };
    get N17() { return this.o.Report_Oil_CylinderNew_Dispersancy; };
    get N20() { return this.o.Report_Oil_CylinderNew_Iron; };
    get N21() { return this.o.Report_Oil_CylinderNew_Lead; };
    get N22() { return this.o.Report_Oil_CylinderNew_Copper; };
    get N23() { return this.o.Report_Oil_CylinderNew_Chromium; };
    get N24() { return this.o.Report_Oil_CylinderNew_Aluminium; };
    get N25() { return this.o.Report_Oil_CylinderNew_Nickel; };
    get N26() { return this.o.Report_Oil_CylinderNew_Silver; };
    get N27() { return this.o.Report_Oil_CylinderNew_Tin; };
    get N28() { return this.o.Report_Oil_CylinderNew_Silicon; };
    get N29() { return this.o.Report_Oil_CylinderNew_Boron; };
    get N30() { return this.o.Report_Oil_CylinderNew_Sodium; };
    get N31() { return this.o.Report_Oil_CylinderNew_Phosphorus; };
    get N32() { return this.o.Report_Oil_CylinderNew_Zinc; };
    get N33() { return this.o.Report_Oil_CylinderNew_Calcium; };
    get N34() { return this.o.Report_Oil_CylinderNew_Barium; };
    get N35() { return this.o.Report_Oil_CylinderNew_Magnesium; };
    get N36() { return this.o.Report_Oil_CylinderNew_Titanium; };
    get N37() { return this.o.Report_Oil_CylinderNew_Molybdenum; };
    get N38() { return this.o.Report_Oil_CylinderNew_Vanadium; };
    get B4() { return this.o.Report_SSF_Collection_LandedDate; };
    get B5() { return this.o.Report_SSF_Collection_LandedPort; };
    get B3() { return this.o.Report_SSF_Collection_SampledDate; };
    get P3() { return this.o.Report_SSF_Collection_SampledDate; };
    get P6() { return this.o.Report_Tracking_Oil_ReceivedByLabDate; };
    get P7() { return this.o.Report_Tracking_Oil_TestedByLabDate; };
    get O3() { return this.o.Report_SSF_Collection_SampledDate; };
    get O6() { return this.o.Report_Tracking_Oil_ReceivedByLabDate; };
    get O7() { return this.o.Report_Tracking_Oil_TestedByLabDate; };
    get N3() { return this.o.Report_SSF_Collection_SampledDate; };
    get N6() { return this.o.Report_Tracking_Oil_ReceivedByLabDate; };
    get N7() { return this.o.Report_Tracking_Oil_TestedByLabDate; };
    get H3() { return this.o.Report_SSF_Collection_SampledDate; };
    get B6() { return this.o.Report_Tracking_Oil_ReceivedByLabDate; };
    get B7() { return this.o.Report_Tracking_Oil_TestedByLabDate; };
    get B39() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_Iron_Magnetic_Raw; };
    get C39() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_Iron_Magnetic_Raw; };
    get D39() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_Iron_Magnetic_Raw; };
    get E39() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_Iron_Magnetic_Raw; };
    get F39() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_Iron_Magnetic_Raw; };
    get G39() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_Iron_Magnetic_Raw; };
    get H39() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_Iron_Magnetic_Raw; };
    get I39() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_Iron_Magnetic_Raw; };
    get J39() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_Iron_Magnetic_Raw; };
    get K39() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_Iron_Magnetic_Raw; };
    get L39() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_Iron_Magnetic_Raw; };
    get M39() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_Iron_Magnetic_Raw; };
    get N39() { return this.o.Report_Oil_CylinderNew_Iron_Magnetic; };
    get O39() { return this.o.Report_Oil_SystemUsed_Iron_Magnetic; };
    get P39() { return this.o.Report_Oil_SystemNew_Iron_Magnetic; };
    get B41() { return this.GetCylinder(0)?.Cylinder_Oil_Drain_Iron_PQ_Raw; };
    get C41() { return this.GetCylinder(1)?.Cylinder_Oil_Drain_Iron_PQ_Raw; };
    get D41() { return this.GetCylinder(2)?.Cylinder_Oil_Drain_Iron_PQ_Raw; };
    get E41() { return this.GetCylinder(3)?.Cylinder_Oil_Drain_Iron_PQ_Raw; };
    get F41() { return this.GetCylinder(4)?.Cylinder_Oil_Drain_Iron_PQ_Raw; };
    get G41() { return this.GetCylinder(5)?.Cylinder_Oil_Drain_Iron_PQ_Raw; };
    get H41() { return this.GetCylinder(6)?.Cylinder_Oil_Drain_Iron_PQ_Raw; };
    get I41() { return this.GetCylinder(7)?.Cylinder_Oil_Drain_Iron_PQ_Raw; };
    get J41() { return this.GetCylinder(8)?.Cylinder_Oil_Drain_Iron_PQ_Raw; };
    get K41() { return this.GetCylinder(9)?.Cylinder_Oil_Drain_Iron_PQ_Raw; };
    get L41() { return this.GetCylinder(10)?.Cylinder_Oil_Drain_Iron_PQ_Raw; };
    get M41() { return this.GetCylinder(11)?.Cylinder_Oil_Drain_Iron_PQ_Raw; };
    get N41() { return this.o.Report_Oil_CylinderNew_Iron_PQ; };
    get O41() { return this.o.Report_Oil_SystemUsed_Iron_PQ; };
    get P41() { return this.o.Report_Oil_SystemNew_Iron_PQ; };
}
