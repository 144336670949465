import React, { Component } from "react";
import Authorisation from './../components/Authorisation'

class Maintenance extends Component {
    componentDidMount() { Authorisation.GetMain()?.onPathChanged(window.location.pathname); }
    render() {
        return (
            <div>
                <div className="summary-short">
                    Our reports enable you to only <strong>overhaul the engine when it is
                        absolutely required</strong>, as well as notifying you of <strong>emerging
                            problems</strong>. As a result, you can <strong>plan your maintenance
                                schedules</strong>, and will  typically have around <strong>five
                                    years</strong> between overhauls.
                </div>
                <div className="column-left topoffset">
                    <h2>Maintenance</h2>
                    <div className="primary-text">
                        <p>
                            Engine Condition Monitoring and Condition Based Maintenance are new industry buzz
                            words that mean that engines should be overhauled when they need to be overhauled
                            rather than when the manufacturer declares it should be done. So why is this possible?
                            Because manufacturers need to specify the service interval based upon the worst
                            scenario. In reality, the majority of engines do not operate under worst conditions,
                            but are carefuly monitored by diligent engineers.
                        </p>
                        <p>
                            Flame Diagnostic works with information and samples provided by those engineers
                            to provide a detailed diagnosis of wear, combustion, lubrication feed rate and performance.
                            As part of our interpretation, we build a complex model of the interactions within
                            the engine taking into account the performance characteristics and the chemistry
                            of the combustion process which we deduce from the cylinder drain oil (CDO or scapedown)
                            analysis together with other samples. The combination of these two specialities
                            is unique to Flame Marine. We have found that it is not possible to fully diagnose
                            the condition of the engine without this information.
                        </p>
                        <p>
                            <strong>
                                All this information is collected under normal operation without stopping the
                                engine or reducing power.
                            </strong>
                        </p>
                        <p>
                            With Flame Diagnostic, we tell when components need checking or replacement, whether
                            deposits are building up on injectors, piston top crowns or ring grooves, if the
                            stuffing box is leaking or whether you've got piston misalignment causing skirt
                            abrasion. We can tell if your settling tanks are operating efficiently, if your
                            fuel temperature is optimal and even if you're producing too much soot or hydrocarbon
                            pollution.
                        </p>
                    </div>
                    <h2>How does this help?</h2>
                    <div className="primary-text">
                        <p>
                            Because Flame Diagnostic monitors so much of what is happening within the engine,
                            we can tell without stopping the engine, whether any units need to be overhauled
                            (or are near to be needing to be overhauled) to maintain adequate performance.
                        </p>
                        <p>
                            Flame Diagnostic closely monitors the condition of the engine and advises on overhaul
                            requirement. We have been doing this for over 10 years and currently monitor over
                            300 ships worldwide.
                        </p>
                        <p>
                            We are used by industry leading research departments to assess the condition of
                            their engines.
                        </p>
                    </div>
                </div>
                <div className="column-right">
                    <div className="topoffset">
                        <img src="/images/thumbs/OverhaulNew.jpg" className="shadow-border" alt="New Overhaul" />
                        <div
                            className="caption small-text clear small-topoffset"
                        >This is a newly overhauled piston, note the shiny surface</div>
                    </div>

                    <div className="topoffset">
                        <img src="/images/thumbs/Overhaul66.jpg" className="shadow-border" alt="Overhaul after 66 hours" />
                        <div className="caption small-text clear small-topoffset">
                            This is a picture of the newly overhauled piston above after only 66 hours of use.
                            This has had a high lub oil feed rate and relatively poor combustion. It has significant
                            deposits on the piston walls and ring grooves and the start of deposits on the piston
                            top land which further diminish combustion performance. Note how quickly the piston
                            has stopped being shiny.
                        </div>
                    </div>
                    <div className="topoffset">
                        <a href="/images/thumbs/Piston30000.jpg">
                            <img src="/images/thumbs/Piston30000.jpg" className="shadow-border" alt="Piston 30000 hours" />
                        </a>
                        <div className="caption small-text clear small-topoffset">
                            This is a piston from a ship using the Flame Diagnostic service. It has been running
                            for over 30,000 hours (5 years) without needing to be overhauled. Note how clean
                            and shiny it is. This is achieved by carefully monitoring combustion, wear and cylinder
                            oil feed rate.
                        </div>
                    </div>
                    <div className="topoffset">
                        <a href="/images/thumbs/PistonBlowBy.jpg">
                            <img src="/images/thumbs/PistonBlowBy.jpg" className="shadow-border" alt="Piston Blow By" />
                        </a>
                        <div className="caption small-text clear small-topoffset">
                            This picture shows a piston with severe deposits in the ring grooves. This has caused
                            the rings to sieze in the grooves and allowed 'blow by'. We can tell this from the
                            burning of the deposits between the rings. Flame Diagnostic detects 'blow by' in
                            its earliest stages and forewarns of potential ring fracture and ring groove wear.
                            Fully adopting the recommendations of Flame Diagnostic reports would have stopped
                            these deposits.
                        </div>
                    </div>
                    <div className="topoffset">
                        <a href="/images/thumbs/PistonCrownDeposits.jpg">
                            <img
                                src="/images/thumbs/PistonCrownDeposits.jpg"
                                className="shadow-border"
                                alt="Piston Crown Deposits"
                            />
                        </a>
                        <div className="caption small-text clear small-topoffset">
                            Here we see deposits on the piston crown caused by unstable combustion and cylinder
                            oil over lubrication. Using Flame Diagnostic would have prevented the build up of
                            deposits. In this case, the remedial action recommended by Flame Diagnostic stabilized
                            combustion, reduced lub oil feed and avoided a piston overhaul.
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Maintenance;