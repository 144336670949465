export class GenerateSSFMap {
    constructor(ship, rep) {
        this.s = ship;
        this.r = rep;
    }
    GetCylinder(index) { return index >= this.r.Report_Cylinders.Length ? null : this.r.Report_Cylinders[index]; };
    GetTurbo(index) { return index >= this.r.Report_TurboChargers.Length ? null : this.r.Report_TurboChargers[index]; };

    get H6() { return this.r.Report_SSF_Collection_LandedDate; };
    get L5() { return this.r.Report_SSF_Collection_LandedPort; };
    get C8() { return this.r.Report_SSF_Conditions_EngineHours?.toFixed(0); };
    get C9() { return this.r.Report_SSF_Conditions_ShipCondition; };
    get C10() { return this.r.Report_SSF_Conditions_Displacement?.toFixed(0); };
    get C11() { return this.r.Report_SSF_Conditions_LoadCondition; };
    get C12() { return this.r.Report_SSF_Conditions_EngineRPM?.toFixed(1); };
    get H8() { return this.r.Report_SSF_Conditions_SeaCondition; };
    get H9() { return this.r.Report_SSF_Conditions_DeckTemperature?.toFixed(0); };
    get H10() { return this.r.Report_SSF_Conditions_EngineRoomTemperature?.toFixed(0); };
    get H11() { return this.r.Report_SSF_Conditions_ScavengeAirTemperature?.toFixed(0); };
    get H12() { return this.r.Report_SSF_Conditions_SeaWaterTemperature?.toFixed(0); };
    get L8() { return this.r.Report_SSF_Conditions_DeckHumidity?.toFixed(1); };
    get L9() { return this.r.Report_SSF_Conditions_AtmosphericPressure?.toFixed(1); };
    get L10() { return this.r.Report_SSF_Conditions_ScavengeAirPressure_Bar?.toFixed(2); };
    get L11() { return this.r.Report_SSF_Conditions_ExhaustGasPressureReceiver_Bar?.toFixed(2); };
    get L12() { return this.r.Report_SSF_Conditions_ExhaustGasPressureAfterTurbine_Bar?.toFixed(2); };
    get C17() { return this.r.Report_SSF_Power_SourceTorqueMeter_KW?.toFixed(0); };
    get D17() { return this.r.Report_SSF_Power_SourceTorqueMeterUnits; };
    get C18() { return this.r.Report_SSF_Power_SourceLoadIndicator_KW?.toFixed(0); };
    get D18() { return this.r.Report_SSF_Power_SourceLoadIndicatorUnits; };
    get C19() { return this.r.Report_SSF_Power_SourceTurboChargerRPM?.toFixed(0); };
    get D19() { return this.r.Report_SSF_Power_SourceTurboChargerRPMUnits; };
    get C20() { return this.r.Report_SSF_Power_SourceFuelConsumption_KW?.toFixed(0); };
    get D20() { return this.r.Report_SSF_Power_SourceFuelConsumptionUnits; };
    get C21() { return this.r.Report_SSF_Power_SourceIndicatorDiagram_KW?.toFixed(0); };
    get D21() { return this.r.Report_SSF_Power_SourceIndicatorDiagramUnits; };
    get I15() { return this.r.Report_SSF_Consumption_CylinderOilName; };
    get I16() { return this.r.Report_SSF_Consumption_CylinderOilBN; };
    get I17() { return this.r.Report_SSF_Consumption_CylinderOilSG_15C?.toFixed(3); };
    get I18() { return this.r.Report_SSF_Consumption_CylinderOilTemperature?.toFixed(1); };
    get I19() { return this.r.Report_SSF_Consumption_CylinderOilConsumption_LHr?.toFixed(3); };
    get I20() { return this.r.Report_SSF_FeedRate_CylinderOilSpecificFeedRate?.toFixed(3); };
    get J20() { return this.r.Report_SSF_FeedRate_CylinderOilFeedRateUnits; };
    get J19() { return this.r.Report_SSF_Consumption_CylinderOilConsumptionUnits; };
    get M15() { return this.r.Report_SSF_Consumption_FuelOilConsumption?.toFixed(3); };
    get N15() { return this.r.Report_SSF_Consumption_FuelOilConsumptionUnits; };
    get M16() { return this.r.Report_SSF_Consumption_FuelOilFlowMeterTemperature?.toFixed(1); };
    get M17() { return this.r.Report_SSF_Consumption_FuelOilBeforeEngineTemperature?.toFixed(1); };
    get M18() { return this.r.Report_SSF_Consumption_FuelOilViscometerTemperature?.toFixed(1); };
    get M19() { return this.r.Report_SSF_Consumption_FuelOilViscometerReading?.toFixed(3); };
    get M21() { return this.r.Report_SSF_Consumption_SystemOilName; };
    get M22() { return this.r.Report_SSF_Consumption_SystemOilConsumption?.toFixed(2); };
    get C24() { return this.r.Report_SSF_FuelBunker_AnalysisLab; };
    get C25() { return this.r.Report_SSF_FuelBunker_PortBunkered; };
    get C26() { return this.r.Report_SSF_FuelBunker_BunkeredDate; };
    get C27() { return this.r.Report_SSF_FuelBunker_ISOGrade; };
    get C28() { return this.r.Report_SSF_FuelBunker_Density15C?.toFixed(3); };
    get C29() { return this.r.Report_SSF_FuelBunker_Viscosity50C?.toFixed(2); };
    get C30() { return this.r.Report_SSF_FuelBunker_Water?.toFixed(3); };
    get C31() { return this.r.Report_SSF_FuelBunker_MCR?.toFixed(3); };
    get C32() { return this.r.Report_SSF_FuelBunker_Sulphur?.toFixed(3); };
    get C33() { return this.r.Report_SSF_FuelBunker_Vanadium?.toFixed(0); };
    get C34() { return this.r.Report_SSF_FuelBunker_Sodium?.toFixed(0); };
    get C35() { return this.r.Report_SSF_FuelBunker_Aluminium?.toFixed(0); };
    get C36() { return this.r.Report_SSF_FuelBunker_Silicon?.toFixed(0); };
    get C37() { return this.r.Report_SSF_FuelBunker_Iron?.toFixed(0); };
    get C38() { return this.r.Report_SSF_FuelBunker_Nickel?.toFixed(0); };
    get C39() { return this.r.Report_SSF_FuelBunker_Calcium?.toFixed(0); };
    get C40() { return this.r.Report_SSF_FuelBunker_Zinc?.toFixed(0); };
    get C41() { return this.r.Report_SSF_FuelBunker_Phosphorus?.toFixed(0); };
    get C43() { return this.r.Report_SSF_FuelBunker_HeatingTempfor10cSt?.toFixed(1); };
    get E43() { return this.r.Report_SSF_FuelBunker_HeatingTempfor15cSt?.toFixed(1); };
    get B49() { return this.GetCylinder(0)?.Cylinder_SSF_Performance_Ptdc_Bar?.toFixed(2); };
    get B50() { return this.GetCylinder(1)?.Cylinder_SSF_Performance_Ptdc_Bar?.toFixed(2); };
    get B51() { return this.GetCylinder(2)?.Cylinder_SSF_Performance_Ptdc_Bar?.toFixed(2); };
    get B52() { return this.GetCylinder(3)?.Cylinder_SSF_Performance_Ptdc_Bar?.toFixed(2); };
    get B53() { return this.GetCylinder(4)?.Cylinder_SSF_Performance_Ptdc_Bar?.toFixed(2); };
    get B54() { return this.GetCylinder(5)?.Cylinder_SSF_Performance_Ptdc_Bar?.toFixed(2); };
    get B55() { return this.GetCylinder(6)?.Cylinder_SSF_Performance_Ptdc_Bar?.toFixed(2); };
    get B56() { return this.GetCylinder(7)?.Cylinder_SSF_Performance_Ptdc_Bar?.toFixed(2); };
    get B57() { return this.GetCylinder(8)?.Cylinder_SSF_Performance_Ptdc_Bar?.toFixed(2); };
    get B58() { return this.GetCylinder(9)?.Cylinder_SSF_Performance_Ptdc_Bar?.toFixed(2); };
    get B59() { return this.GetCylinder(10)?.Cylinder_SSF_Performance_Ptdc_Bar?.toFixed(2); };
    get B60() { return this.GetCylinder(11)?.Cylinder_SSF_Performance_Ptdc_Bar?.toFixed(2); };
    get C49() { return this.GetCylinder(0)?.Cylinder_SSF_Performance_Pmax_Bar?.toFixed(2); };
    get C50() { return this.GetCylinder(1)?.Cylinder_SSF_Performance_Pmax_Bar?.toFixed(2); };
    get C51() { return this.GetCylinder(2)?.Cylinder_SSF_Performance_Pmax_Bar?.toFixed(2); };
    get C52() { return this.GetCylinder(3)?.Cylinder_SSF_Performance_Pmax_Bar?.toFixed(2); };
    get C53() { return this.GetCylinder(4)?.Cylinder_SSF_Performance_Pmax_Bar?.toFixed(2); };
    get C54() { return this.GetCylinder(5)?.Cylinder_SSF_Performance_Pmax_Bar?.toFixed(2); };
    get C55() { return this.GetCylinder(6)?.Cylinder_SSF_Performance_Pmax_Bar?.toFixed(2); };
    get C56() { return this.GetCylinder(7)?.Cylinder_SSF_Performance_Pmax_Bar?.toFixed(2); };
    get C57() { return this.GetCylinder(8)?.Cylinder_SSF_Performance_Pmax_Bar?.toFixed(2); };
    get C58() { return this.GetCylinder(9)?.Cylinder_SSF_Performance_Pmax_Bar?.toFixed(2); };
    get C59() { return this.GetCylinder(10)?.Cylinder_SSF_Performance_Pmax_Bar?.toFixed(2); };
    get C60() { return this.GetCylinder(11)?.Cylinder_SSF_Performance_Pmax_Bar?.toFixed(2); };
    get D49() { return this.GetCylinder(0)?.Cylinder_SSF_Performance_MIP_Bar?.toFixed(2); };
    get D50() { return this.GetCylinder(1)?.Cylinder_SSF_Performance_MIP_Bar?.toFixed(2); };
    get D51() { return this.GetCylinder(2)?.Cylinder_SSF_Performance_MIP_Bar?.toFixed(2); };
    get D52() { return this.GetCylinder(3)?.Cylinder_SSF_Performance_MIP_Bar?.toFixed(2); };
    get D53() { return this.GetCylinder(4)?.Cylinder_SSF_Performance_MIP_Bar?.toFixed(2); };
    get D54() { return this.GetCylinder(5)?.Cylinder_SSF_Performance_MIP_Bar?.toFixed(2); };
    get D55() { return this.GetCylinder(6)?.Cylinder_SSF_Performance_MIP_Bar?.toFixed(2); };
    get D56() { return this.GetCylinder(7)?.Cylinder_SSF_Performance_MIP_Bar?.toFixed(2); };
    get D57() { return this.GetCylinder(8)?.Cylinder_SSF_Performance_MIP_Bar?.toFixed(2); };
    get D58() { return this.GetCylinder(9)?.Cylinder_SSF_Performance_MIP_Bar?.toFixed(2); };
    get D59() { return this.GetCylinder(10)?.Cylinder_SSF_Performance_MIP_Bar?.toFixed(2); };
    get D60() { return this.GetCylinder(11)?.Cylinder_SSF_Performance_MIP_Bar?.toFixed(2); };
    get E49() { return this.GetCylinder(0)?.Cylinder_SSF_Performance_FuelRack?.toFixed(2); };
    get E50() { return this.GetCylinder(1)?.Cylinder_SSF_Performance_FuelRack?.toFixed(2); };
    get E51() { return this.GetCylinder(2)?.Cylinder_SSF_Performance_FuelRack?.toFixed(2); };
    get E52() { return this.GetCylinder(3)?.Cylinder_SSF_Performance_FuelRack?.toFixed(2); };
    get E53() { return this.GetCylinder(4)?.Cylinder_SSF_Performance_FuelRack?.toFixed(2); };
    get E54() { return this.GetCylinder(5)?.Cylinder_SSF_Performance_FuelRack?.toFixed(2); };
    get E55() { return this.GetCylinder(6)?.Cylinder_SSF_Performance_FuelRack?.toFixed(2); };
    get E56() { return this.GetCylinder(7)?.Cylinder_SSF_Performance_FuelRack?.toFixed(2); };
    get E57() { return this.GetCylinder(8)?.Cylinder_SSF_Performance_FuelRack?.toFixed(2); };
    get E58() { return this.GetCylinder(9)?.Cylinder_SSF_Performance_FuelRack?.toFixed(2); };
    get E59() { return this.GetCylinder(10)?.Cylinder_SSF_Performance_FuelRack?.toFixed(2); };
    get E60() { return this.GetCylinder(11)?.Cylinder_SSF_Performance_FuelRack?.toFixed(2); };
    get F49() { return this.GetCylinder(0)?.Cylinder_SSF_Performance_VIT?.toFixed(2); };
    get F50() { return this.GetCylinder(1)?.Cylinder_SSF_Performance_VIT?.toFixed(2); };
    get F51() { return this.GetCylinder(2)?.Cylinder_SSF_Performance_VIT?.toFixed(2); };
    get F52() { return this.GetCylinder(3)?.Cylinder_SSF_Performance_VIT?.toFixed(2); };
    get F53() { return this.GetCylinder(4)?.Cylinder_SSF_Performance_VIT?.toFixed(2); };
    get F54() { return this.GetCylinder(5)?.Cylinder_SSF_Performance_VIT?.toFixed(2); };
    get F55() { return this.GetCylinder(6)?.Cylinder_SSF_Performance_VIT?.toFixed(2); };
    get F56() { return this.GetCylinder(7)?.Cylinder_SSF_Performance_VIT?.toFixed(2); };
    get F57() { return this.GetCylinder(8)?.Cylinder_SSF_Performance_VIT?.toFixed(2); };
    get F58() { return this.GetCylinder(9)?.Cylinder_SSF_Performance_VIT?.toFixed(2); };
    get F59() { return this.GetCylinder(10)?.Cylinder_SSF_Performance_VIT?.toFixed(2); };
    get F60() { return this.GetCylinder(11)?.Cylinder_SSF_Performance_VIT?.toFixed(2); };
    get G49() { return this.GetCylinder(0)?.Cylinder_SSF_Performance_LocalExhaustTemperature?.toFixed(1); };
    get G50() { return this.GetCylinder(1)?.Cylinder_SSF_Performance_LocalExhaustTemperature?.toFixed(1); };
    get G51() { return this.GetCylinder(2)?.Cylinder_SSF_Performance_LocalExhaustTemperature?.toFixed(1); };
    get G52() { return this.GetCylinder(3)?.Cylinder_SSF_Performance_LocalExhaustTemperature?.toFixed(1); };
    get G53() { return this.GetCylinder(4)?.Cylinder_SSF_Performance_LocalExhaustTemperature?.toFixed(1); };
    get G54() { return this.GetCylinder(5)?.Cylinder_SSF_Performance_LocalExhaustTemperature?.toFixed(1); };
    get G55() { return this.GetCylinder(6)?.Cylinder_SSF_Performance_LocalExhaustTemperature?.toFixed(1); };
    get G56() { return this.GetCylinder(7)?.Cylinder_SSF_Performance_LocalExhaustTemperature?.toFixed(1); };
    get G57() { return this.GetCylinder(8)?.Cylinder_SSF_Performance_LocalExhaustTemperature?.toFixed(1); };
    get G58() { return this.GetCylinder(9)?.Cylinder_SSF_Performance_LocalExhaustTemperature?.toFixed(1); };
    get G59() { return this.GetCylinder(10)?.Cylinder_SSF_Performance_LocalExhaustTemperature?.toFixed(1); };
    get G60() { return this.GetCylinder(11)?.Cylinder_SSF_Performance_LocalExhaustTemperature?.toFixed(1); };
    get H49() { return this.GetCylinder(0)?.Cylinder_SSF_Performance_LinerWallTemperature?.toFixed(1); };
    get H50() { return this.GetCylinder(1)?.Cylinder_SSF_Performance_LinerWallTemperature?.toFixed(1); };
    get H51() { return this.GetCylinder(2)?.Cylinder_SSF_Performance_LinerWallTemperature?.toFixed(1); };
    get H52() { return this.GetCylinder(3)?.Cylinder_SSF_Performance_LinerWallTemperature?.toFixed(1); };
    get H53() { return this.GetCylinder(4)?.Cylinder_SSF_Performance_LinerWallTemperature?.toFixed(1); };
    get H54() { return this.GetCylinder(5)?.Cylinder_SSF_Performance_LinerWallTemperature?.toFixed(1); };
    get H55() { return this.GetCylinder(6)?.Cylinder_SSF_Performance_LinerWallTemperature?.toFixed(1); };
    get H56() { return this.GetCylinder(7)?.Cylinder_SSF_Performance_LinerWallTemperature?.toFixed(1); };
    get H57() { return this.GetCylinder(8)?.Cylinder_SSF_Performance_LinerWallTemperature?.toFixed(1); };
    get H58() { return this.GetCylinder(9)?.Cylinder_SSF_Performance_LinerWallTemperature?.toFixed(1); };
    get H59() { return this.GetCylinder(10)?.Cylinder_SSF_Performance_LinerWallTemperature?.toFixed(1); };
    get H60() { return this.GetCylinder(11)?.Cylinder_SSF_Performance_LinerWallTemperature?.toFixed(1); };
    get I49() { return this.GetCylinder(0)?.Cylinder_MAN_CoolingWaterOutletTemp?.toFixed(1); };
    get I50() { return this.GetCylinder(1)?.Cylinder_MAN_CoolingWaterOutletTemp?.toFixed(1); };
    get I51() { return this.GetCylinder(2)?.Cylinder_MAN_CoolingWaterOutletTemp?.toFixed(1); };
    get I52() { return this.GetCylinder(3)?.Cylinder_MAN_CoolingWaterOutletTemp?.toFixed(1); };
    get I53() { return this.GetCylinder(4)?.Cylinder_MAN_CoolingWaterOutletTemp?.toFixed(1); };
    get I54() { return this.GetCylinder(5)?.Cylinder_MAN_CoolingWaterOutletTemp?.toFixed(1); };
    get I55() { return this.GetCylinder(6)?.Cylinder_MAN_CoolingWaterOutletTemp?.toFixed(1); };
    get I56() { return this.GetCylinder(7)?.Cylinder_MAN_CoolingWaterOutletTemp?.toFixed(1); };
    get I57() { return this.GetCylinder(8)?.Cylinder_MAN_CoolingWaterOutletTemp?.toFixed(1); };
    get I58() { return this.GetCylinder(9)?.Cylinder_MAN_CoolingWaterOutletTemp?.toFixed(1); };
    get I59() { return this.GetCylinder(10)?.Cylinder_MAN_CoolingWaterOutletTemp?.toFixed(1); };
    get I60() { return this.GetCylinder(11)?.Cylinder_MAN_CoolingWaterOutletTemp?.toFixed(1); };
    get K49() { return this.GetCylinder(0)?.Cylinder_SSF_RunningHours_LinerHoursSinceNew?.toFixed(0); };
    get K50() { return this.GetCylinder(1)?.Cylinder_SSF_RunningHours_LinerHoursSinceNew?.toFixed(0); };
    get K51() { return this.GetCylinder(2)?.Cylinder_SSF_RunningHours_LinerHoursSinceNew?.toFixed(0); };
    get K52() { return this.GetCylinder(3)?.Cylinder_SSF_RunningHours_LinerHoursSinceNew?.toFixed(0); };
    get K53() { return this.GetCylinder(4)?.Cylinder_SSF_RunningHours_LinerHoursSinceNew?.toFixed(0); };
    get K54() { return this.GetCylinder(5)?.Cylinder_SSF_RunningHours_LinerHoursSinceNew?.toFixed(0); };
    get K55() { return this.GetCylinder(6)?.Cylinder_SSF_RunningHours_LinerHoursSinceNew?.toFixed(0); };
    get K56() { return this.GetCylinder(7)?.Cylinder_SSF_RunningHours_LinerHoursSinceNew?.toFixed(0); };
    get K57() { return this.GetCylinder(8)?.Cylinder_SSF_RunningHours_LinerHoursSinceNew?.toFixed(0); };
    get K58() { return this.GetCylinder(9)?.Cylinder_SSF_RunningHours_LinerHoursSinceNew?.toFixed(0); };
    get K59() { return this.GetCylinder(10)?.Cylinder_SSF_RunningHours_LinerHoursSinceNew?.toFixed(0); };
    get K60() { return this.GetCylinder(11)?.Cylinder_SSF_RunningHours_LinerHoursSinceNew?.toFixed(0); };
    get L49() { return this.GetCylinder(0)?.Cylinder_SSF_RunningHours_LinerHoursSinceLastOverhaul?.toFixed(0); };
    get L50() { return this.GetCylinder(1)?.Cylinder_SSF_RunningHours_LinerHoursSinceLastOverhaul?.toFixed(0); };
    get L51() { return this.GetCylinder(2)?.Cylinder_SSF_RunningHours_LinerHoursSinceLastOverhaul?.toFixed(0); };
    get L52() { return this.GetCylinder(3)?.Cylinder_SSF_RunningHours_LinerHoursSinceLastOverhaul?.toFixed(0); };
    get L53() { return this.GetCylinder(4)?.Cylinder_SSF_RunningHours_LinerHoursSinceLastOverhaul?.toFixed(0); };
    get L54() { return this.GetCylinder(5)?.Cylinder_SSF_RunningHours_LinerHoursSinceLastOverhaul?.toFixed(0); };
    get L55() { return this.GetCylinder(6)?.Cylinder_SSF_RunningHours_LinerHoursSinceLastOverhaul?.toFixed(0); };
    get L56() { return this.GetCylinder(7)?.Cylinder_SSF_RunningHours_LinerHoursSinceLastOverhaul?.toFixed(0); };
    get L57() { return this.GetCylinder(8)?.Cylinder_SSF_RunningHours_LinerHoursSinceLastOverhaul?.toFixed(0); };
    get L58() { return this.GetCylinder(9)?.Cylinder_SSF_RunningHours_LinerHoursSinceLastOverhaul?.toFixed(0); };
    get L59() { return this.GetCylinder(10)?.Cylinder_SSF_RunningHours_LinerHoursSinceLastOverhaul?.toFixed(0); };
    get L60() { return this.GetCylinder(11)?.Cylinder_SSF_RunningHours_LinerHoursSinceLastOverhaul?.toFixed(0); };
    get M49() { return this.GetCylinder(0)?.Cylinder_SSF_RunningHours_InjectorHoursSinceLastOverhaul?.toFixed(0); };
    get M50() { return this.GetCylinder(1)?.Cylinder_SSF_RunningHours_InjectorHoursSinceLastOverhaul?.toFixed(0); };
    get M51() { return this.GetCylinder(2)?.Cylinder_SSF_RunningHours_InjectorHoursSinceLastOverhaul?.toFixed(0); };
    get M52() { return this.GetCylinder(3)?.Cylinder_SSF_RunningHours_InjectorHoursSinceLastOverhaul?.toFixed(0); };
    get M53() { return this.GetCylinder(4)?.Cylinder_SSF_RunningHours_InjectorHoursSinceLastOverhaul?.toFixed(0); };
    get M54() { return this.GetCylinder(5)?.Cylinder_SSF_RunningHours_InjectorHoursSinceLastOverhaul?.toFixed(0); };
    get M55() { return this.GetCylinder(6)?.Cylinder_SSF_RunningHours_InjectorHoursSinceLastOverhaul?.toFixed(0); };
    get M56() { return this.GetCylinder(7)?.Cylinder_SSF_RunningHours_InjectorHoursSinceLastOverhaul?.toFixed(0); };
    get M57() { return this.GetCylinder(8)?.Cylinder_SSF_RunningHours_InjectorHoursSinceLastOverhaul?.toFixed(0); };
    get M58() { return this.GetCylinder(9)?.Cylinder_SSF_RunningHours_InjectorHoursSinceLastOverhaul?.toFixed(0); };
    get M59() { return this.GetCylinder(10)?.Cylinder_SSF_RunningHours_InjectorHoursSinceLastOverhaul?.toFixed(0); };
    get M60() { return this.GetCylinder(11)?.Cylinder_SSF_RunningHours_InjectorHoursSinceLastOverhaul?.toFixed(0); };
    get N49() { return this.GetCylinder(0)?.Cylinder_SSF_RunningHours_MaxWearAtLastOverhaul?.toFixed(0); };
    get N50() { return this.GetCylinder(1)?.Cylinder_SSF_RunningHours_MaxWearAtLastOverhaul?.toFixed(0); };
    get N51() { return this.GetCylinder(2)?.Cylinder_SSF_RunningHours_MaxWearAtLastOverhaul?.toFixed(0); };
    get N52() { return this.GetCylinder(3)?.Cylinder_SSF_RunningHours_MaxWearAtLastOverhaul?.toFixed(0); };
    get N53() { return this.GetCylinder(4)?.Cylinder_SSF_RunningHours_MaxWearAtLastOverhaul?.toFixed(0); };
    get N54() { return this.GetCylinder(5)?.Cylinder_SSF_RunningHours_MaxWearAtLastOverhaul?.toFixed(0); };
    get N55() { return this.GetCylinder(6)?.Cylinder_SSF_RunningHours_MaxWearAtLastOverhaul?.toFixed(0); };
    get N56() { return this.GetCylinder(7)?.Cylinder_SSF_RunningHours_MaxWearAtLastOverhaul?.toFixed(0); };
    get N57() { return this.GetCylinder(8)?.Cylinder_SSF_RunningHours_MaxWearAtLastOverhaul?.toFixed(0); };
    get N58() { return this.GetCylinder(9)?.Cylinder_SSF_RunningHours_MaxWearAtLastOverhaul?.toFixed(0); };
    get N59() { return this.GetCylinder(10)?.Cylinder_SSF_RunningHours_MaxWearAtLastOverhaul?.toFixed(0); };
    get N60() { return this.GetCylinder(11)?.Cylinder_SSF_RunningHours_MaxWearAtLastOverhaul?.toFixed(0); };
    get B65() { return this.GetTurbo(0)?.Turbo_SSF_RPM?.toFixed(1); };
    get B66() { return this.GetTurbo(1)?.Turbo_SSF_RPM?.toFixed(1); };
    get B67() { return this.GetTurbo(2)?.Turbo_SSF_RPM?.toFixed(1); };
    get B68() { return this.GetTurbo(3)?.Turbo_SSF_RPM?.toFixed(1); };
    get C65() { return this.GetTurbo(0)?.Turbo_SSF_GasInTemperature?.toFixed(1); };
    get C66() { return this.GetTurbo(1)?.Turbo_SSF_GasInTemperature?.toFixed(1); };
    get C67() { return this.GetTurbo(2)?.Turbo_SSF_GasInTemperature?.toFixed(1); };
    get C68() { return this.GetTurbo(3)?.Turbo_SSF_GasInTemperature?.toFixed(1); };
    get D65() { return this.GetTurbo(0)?.Turbo_SSF_GasOutTemperature?.toFixed(1); };
    get D66() { return this.GetTurbo(1)?.Turbo_SSF_GasOutTemperature?.toFixed(1); };
    get D67() { return this.GetTurbo(2)?.Turbo_SSF_GasOutTemperature?.toFixed(1); };
    get D68() { return this.GetTurbo(3)?.Turbo_SSF_GasOutTemperature?.toFixed(1); };
    get E65() { return this.GetTurbo(0)?.Turbo_SSF_BlowerInTemperature?.toFixed(1); };
    get E66() { return this.GetTurbo(1)?.Turbo_SSF_BlowerInTemperature?.toFixed(1); };
    get E67() { return this.GetTurbo(2)?.Turbo_SSF_BlowerInTemperature?.toFixed(1); };
    get E68() { return this.GetTurbo(3)?.Turbo_SSF_BlowerInTemperature?.toFixed(1); };
    get F65() { return this.GetTurbo(0)?.Turbo_SSF_AirCooler_BeforeCoolerTemperature?.toFixed(1); };
    get F66() { return this.GetTurbo(1)?.Turbo_SSF_AirCooler_BeforeCoolerTemperature?.toFixed(1); };
    get F67() { return this.GetTurbo(2)?.Turbo_SSF_AirCooler_BeforeCoolerTemperature?.toFixed(1); };
    get F68() { return this.GetTurbo(3)?.Turbo_SSF_AirCooler_BeforeCoolerTemperature?.toFixed(1); };
    get G65() { return this.GetTurbo(0)?.Turbo_SSF_AirCooler_AfterCoolerTemperature?.toFixed(1); };
    get G66() { return this.GetTurbo(1)?.Turbo_SSF_AirCooler_AfterCoolerTemperature?.toFixed(1); };
    get G67() { return this.GetTurbo(2)?.Turbo_SSF_AirCooler_AfterCoolerTemperature?.toFixed(1); };
    get G68() { return this.GetTurbo(3)?.Turbo_SSF_AirCooler_AfterCoolerTemperature?.toFixed(1); };
    get H65() { return this.GetTurbo(0)?.Turbo_SSF_AirCooler_CoolingWaterInTemperature?.toFixed(1); };
    get H66() { return this.GetTurbo(1)?.Turbo_SSF_AirCooler_CoolingWaterInTemperature?.toFixed(1); };
    get H67() { return this.GetTurbo(2)?.Turbo_SSF_AirCooler_CoolingWaterInTemperature?.toFixed(1); };
    get H68() { return this.GetTurbo(3)?.Turbo_SSF_AirCooler_CoolingWaterInTemperature?.toFixed(1); };
    get I65() { return this.GetTurbo(0)?.Turbo_SSF_AirCooler_CoolingWaterOutTemperature?.toFixed(1); };
    get I66() { return this.GetTurbo(1)?.Turbo_SSF_AirCooler_CoolingWaterOutTemperature?.toFixed(1); };
    get I67() { return this.GetTurbo(2)?.Turbo_SSF_AirCooler_CoolingWaterOutTemperature?.toFixed(1); };
    get I68() { return this.GetTurbo(3)?.Turbo_SSF_AirCooler_CoolingWaterOutTemperature?.toFixed(1); };
    get J65() { return this.GetTurbo(0)?.Turbo_SSF_AirCooler_PressureDropAcrossFilter?.toFixed(2); };
    get J66() { return this.GetTurbo(1)?.Turbo_SSF_AirCooler_PressureDropAcrossFilter?.toFixed(2); };
    get J67() { return this.GetTurbo(2)?.Turbo_SSF_AirCooler_PressureDropAcrossFilter?.toFixed(2); };
    get J68() { return this.GetTurbo(3)?.Turbo_SSF_AirCooler_PressureDropAcrossFilter?.toFixed(2); };
    get K65() { return this.GetTurbo(0)?.Turbo_SSF_AirCooler_PressureDropAcrossCooler?.toFixed(2); };
    get K66() { return this.GetTurbo(1)?.Turbo_SSF_AirCooler_PressureDropAcrossCooler?.toFixed(2); };
    get K67() { return this.GetTurbo(2)?.Turbo_SSF_AirCooler_PressureDropAcrossCooler?.toFixed(2); };
    get K68() { return this.GetTurbo(3)?.Turbo_SSF_AirCooler_PressureDropAcrossCooler?.toFixed(2); };
    get M63() { return this.r.Report_SSF_RecordedBy_Name; };
    get M64() { return this.r.Report_SSF_RecordedBy_Rank; };
    get L66() { return this.r.Report_SSF_RecordedBy_ChiefEngineer; };
    get L68() { return this.r.Report_SSF_RecordedBy_Superintendent; };
    get A74() { return this.r.Report_SSF_RecordedBy_Comments; };
    get K27() { return this.r.Report_SSF_FeedRate_Wartsila_Conventional_LeverPosition; };
    get K28() { return this.r.Report_SSF_FeedRate_Wartsila_Conventional_PumpFactor; };
    get K29() { return this.r.Report_SSF_FeedRate_Wartsila_Conventional_MotorFrequency; };

    get J32() { return this.GetCylinder(0)?.Cylinder_SSF_FeedRate_Wartsila_Conventional_Upper; };
    get J33() { return this.GetCylinder(1)?.Cylinder_SSF_FeedRate_Wartsila_Conventional_Upper; };
    get J34() { return this.GetCylinder(2)?.Cylinder_SSF_FeedRate_Wartsila_Conventional_Upper; };
    get J35() { return this.GetCylinder(3)?.Cylinder_SSF_FeedRate_Wartsila_Conventional_Upper; };
    get J36() { return this.GetCylinder(4)?.Cylinder_SSF_FeedRate_Wartsila_Conventional_Upper; };
    get J37() { return this.GetCylinder(5)?.Cylinder_SSF_FeedRate_Wartsila_Conventional_Upper; };
    get J38() { return this.GetCylinder(6)?.Cylinder_SSF_FeedRate_Wartsila_Conventional_Upper; };
    get J39() { return this.GetCylinder(7)?.Cylinder_SSF_FeedRate_Wartsila_Conventional_Upper; };
    get J40() { return this.GetCylinder(8)?.Cylinder_SSF_FeedRate_Wartsila_Conventional_Upper; };
    get J41() { return this.GetCylinder(9)?.Cylinder_SSF_FeedRate_Wartsila_Conventional_Upper; };
    get J42() { return this.GetCylinder(10)?.Cylinder_SSF_FeedRate_Wartsila_Conventional_Upper; };
    get J43() { return this.GetCylinder(11)?.Cylinder_SSF_FeedRate_Wartsila_Conventional_Upper; };

    get K32() { return this.GetCylinder(0)?.Cylinder_SSF_FeedRate_Wartsila_Conventional_Lower; };
    get K33() { return this.GetCylinder(1)?.Cylinder_SSF_FeedRate_Wartsila_Conventional_Lower; };
    get K34() { return this.GetCylinder(2)?.Cylinder_SSF_FeedRate_Wartsila_Conventional_Lower; };
    get K35() { return this.GetCylinder(3)?.Cylinder_SSF_FeedRate_Wartsila_Conventional_Lower; };
    get K36() { return this.GetCylinder(4)?.Cylinder_SSF_FeedRate_Wartsila_Conventional_Lower; };
    get K37() { return this.GetCylinder(5)?.Cylinder_SSF_FeedRate_Wartsila_Conventional_Lower; };
    get K38() { return this.GetCylinder(6)?.Cylinder_SSF_FeedRate_Wartsila_Conventional_Lower; };
    get K39() { return this.GetCylinder(7)?.Cylinder_SSF_FeedRate_Wartsila_Conventional_Lower; };
    get K40() { return this.GetCylinder(8)?.Cylinder_SSF_FeedRate_Wartsila_Conventional_Lower; };
    get K41() { return this.GetCylinder(9)?.Cylinder_SSF_FeedRate_Wartsila_Conventional_Lower; };
    get K42() { return this.GetCylinder(10)?.Cylinder_SSF_FeedRate_Wartsila_Conventional_Lower; };
    get K43() { return this.GetCylinder(11)?.Cylinder_SSF_FeedRate_Wartsila_Conventional_Lower; };
    get D43() { return this.r.Report_SSF_FuelBunker_HeatingTempfor12cSt?.toFixed(1); };
    get C15() { return this.r.Report_SSF_Power_EngineLoad?.toFixed(1); };
    get E46() { return this.r.Report_SSF_Performance_LoadIndicator?.toFixed(2); };
    get N29() { return this.r.Report_SSF_FeedRate_SIP_Setting?.toFixed(2); };

    get N32() { return this.GetCylinder(0)?.Cylinder_SSF_FeedRate_SIP_Individual; };
    get N33() { return this.GetCylinder(1)?.Cylinder_SSF_FeedRate_SIP_Individual; };
    get N34() { return this.GetCylinder(2)?.Cylinder_SSF_FeedRate_SIP_Individual; };
    get N35() { return this.GetCylinder(3)?.Cylinder_SSF_FeedRate_SIP_Individual; };
    get N36() { return this.GetCylinder(4)?.Cylinder_SSF_FeedRate_SIP_Individual; };
    get N37() { return this.GetCylinder(5)?.Cylinder_SSF_FeedRate_SIP_Individual; };
    get N38() { return this.GetCylinder(6)?.Cylinder_SSF_FeedRate_SIP_Individual; };
    get N39() { return this.GetCylinder(7)?.Cylinder_SSF_FeedRate_SIP_Individual; };
    get N40() { return this.GetCylinder(8)?.Cylinder_SSF_FeedRate_SIP_Individual; };
    get N41() { return this.GetCylinder(9)?.Cylinder_SSF_FeedRate_SIP_Individual; };
    get N42() { return this.GetCylinder(10)?.Cylinder_SSF_FeedRate_SIP_Individual; };
    get N43() { return this.GetCylinder(11)?.Cylinder_SSF_FeedRate_SIP_Individual; };
    get N10() { return this.r.Report_SSF_Conditions_ScavengeAirPressureUnits; };
    get N11() { return this.r.Report_SSF_Conditions_ExhaustGasPressureReceiverUnits; };
    get N12() { return this.r.Report_SSF_Conditions_ExhaustGasPressureAfterTurbineUnits; };
    get B48() { return this.r.Report_SSF_Performance_PtdcUnits; };
    get C48() { return this.r.Report_SSF_Performance_PmaxUnits; };
    get D48() { return this.r.Report_SSF_Performance_MIPUnits; };
    get A72() { return this.r.Report_SSF_SamplesLanded_TotalBottles?.toFixed(0); };
    get C72() { return this.r.Report_SSF_SamplesLanded_DrainOil?.toFixed(0); };
    get G70() { return this.r.Report_SSF_SamplesLanded_SystemOilUsed?.toFixed(0); };
    get G71() { return this.r.Report_SSF_SamplesLanded_CylinderOilNew?.toFixed(0); };
    get G72() { return this.r.Report_SSF_SamplesLanded_SystemOilNew?.toFixed(0); };
    get K70() { return this.r.Report_SSF_SamplesLanded_FuelBeforePurifier?.toFixed(0); };
    get K71() { return this.r.Report_SSF_SamplesLanded_FuelAfterPurifier?.toFixed(0); };
    get K72() { return this.r.Report_SSF_SamplesLanded_FuelBeforeEngine?.toFixed(0); };
    get E45() { return this.r.Report_SSF_Performance_Source; };
    get L29() { return this.r.Report_SSF_FeedRate_Wartsila_JetPulse_FeedSetting; };
    get M29() { return this.r.Report_SSF_FeedRate_Wartsila_JetPulse_FeedSettingUnits; };
    get L32() { return this.GetCylinder(0)?.Cylinder_SSF_FeedRate_Wartsila_JetPulse_Individual; };
    get L33() { return this.GetCylinder(1)?.Cylinder_SSF_FeedRate_Wartsila_JetPulse_Individual; };
    get L34() { return this.GetCylinder(2)?.Cylinder_SSF_FeedRate_Wartsila_JetPulse_Individual; };
    get L35() { return this.GetCylinder(3)?.Cylinder_SSF_FeedRate_Wartsila_JetPulse_Individual; };
    get L36() { return this.GetCylinder(4)?.Cylinder_SSF_FeedRate_Wartsila_JetPulse_Individual; };
    get L37() { return this.GetCylinder(5)?.Cylinder_SSF_FeedRate_Wartsila_JetPulse_Individual; };
    get L38() { return this.GetCylinder(6)?.Cylinder_SSF_FeedRate_Wartsila_JetPulse_Individual; };
    get L39() { return this.GetCylinder(7)?.Cylinder_SSF_FeedRate_Wartsila_JetPulse_Individual; };
    get L40() { return this.GetCylinder(8)?.Cylinder_SSF_FeedRate_Wartsila_JetPulse_Individual; };
    get L41() { return this.GetCylinder(9)?.Cylinder_SSF_FeedRate_Wartsila_JetPulse_Individual; };
    get L42() { return this.GetCylinder(10)?.Cylinder_SSF_FeedRate_Wartsila_JetPulse_Individual; };
    get L43() { return this.GetCylinder(11)?.Cylinder_SSF_FeedRate_Wartsila_JetPulse_Individual; };
    get G32() { return this.GetCylinder(0)?.Cylinder_SSF_FeedRate_IndividualFeedSetting?.toFixed(3); };
    get G33() { return this.GetCylinder(1)?.Cylinder_SSF_FeedRate_IndividualFeedSetting?.toFixed(3); };
    get G34() { return this.GetCylinder(2)?.Cylinder_SSF_FeedRate_IndividualFeedSetting?.toFixed(3); };
    get G35() { return this.GetCylinder(3)?.Cylinder_SSF_FeedRate_IndividualFeedSetting?.toFixed(3); };
    get G36() { return this.GetCylinder(4)?.Cylinder_SSF_FeedRate_IndividualFeedSetting?.toFixed(3); };
    get G37() { return this.GetCylinder(5)?.Cylinder_SSF_FeedRate_IndividualFeedSetting?.toFixed(3); };
    get G38() { return this.GetCylinder(6)?.Cylinder_SSF_FeedRate_IndividualFeedSetting?.toFixed(3); };
    get G39() { return this.GetCylinder(7)?.Cylinder_SSF_FeedRate_IndividualFeedSetting?.toFixed(3); };
    get G40() { return this.GetCylinder(8)?.Cylinder_SSF_FeedRate_IndividualFeedSetting?.toFixed(3); };
    get G41() { return this.GetCylinder(9)?.Cylinder_SSF_FeedRate_IndividualFeedSetting?.toFixed(3); };
    get G42() { return this.GetCylinder(10)?.Cylinder_SSF_FeedRate_IndividualFeedSetting?.toFixed(3); };
    get G43() { return this.GetCylinder(11)?.Cylinder_SSF_FeedRate_IndividualFeedSetting?.toFixed(3); };
    get G29() { return this.r.Report_SSF_FeedRate_MAN_Alpha_HMI?.toFixed(0); };
    get E21() { return this.r.Report_SSF_Power_SourceIndicatorDiagramType; };
    get E22() { return this.r.Report_SSF_Power_SourceIndicatorDiagramDrawnBy; };
    get G27() { return this.r.Report_SSF_FeedRate_MAN_Alpha_AccFactor?.toFixed(3); };
    get G28() { return this.r.Report_SSF_FeedRate_MAN_Alpha_AccFactorUnits; };
    get H32() { return this.GetCylinder(0)?.Cylinder_SSF_FeedRate_MAN_Conventional_Stroke; };
    get H33() { return this.GetCylinder(1)?.Cylinder_SSF_FeedRate_MAN_Conventional_Stroke; };
    get H34() { return this.GetCylinder(2)?.Cylinder_SSF_FeedRate_MAN_Conventional_Stroke; };
    get H35() { return this.GetCylinder(3)?.Cylinder_SSF_FeedRate_MAN_Conventional_Stroke; };
    get H36() { return this.GetCylinder(4)?.Cylinder_SSF_FeedRate_MAN_Conventional_Stroke; };
    get H37() { return this.GetCylinder(5)?.Cylinder_SSF_FeedRate_MAN_Conventional_Stroke; };
    get H38() { return this.GetCylinder(6)?.Cylinder_SSF_FeedRate_MAN_Conventional_Stroke; };
    get H39() { return this.GetCylinder(7)?.Cylinder_SSF_FeedRate_MAN_Conventional_Stroke; };
    get H40() { return this.GetCylinder(8)?.Cylinder_SSF_FeedRate_MAN_Conventional_Stroke; };
    get H41() { return this.GetCylinder(9)?.Cylinder_SSF_FeedRate_MAN_Conventional_Stroke; };
    get H42() { return this.GetCylinder(10)?.Cylinder_SSF_FeedRate_MAN_Conventional_Stroke; };
    get H43() { return this.GetCylinder(11)?.Cylinder_SSF_FeedRate_MAN_Conventional_Stroke; };
    get I32() { return this.GetCylinder(0)?.Cylinder_SSF_FeedRate_MAN_Conventional_LCD; };
    get I33() { return this.GetCylinder(1)?.Cylinder_SSF_FeedRate_MAN_Conventional_LCD; };
    get I34() { return this.GetCylinder(2)?.Cylinder_SSF_FeedRate_MAN_Conventional_LCD; };
    get I35() { return this.GetCylinder(3)?.Cylinder_SSF_FeedRate_MAN_Conventional_LCD; };
    get I36() { return this.GetCylinder(4)?.Cylinder_SSF_FeedRate_MAN_Conventional_LCD; };
    get I37() { return this.GetCylinder(5)?.Cylinder_SSF_FeedRate_MAN_Conventional_LCD; };
    get I38() { return this.GetCylinder(6)?.Cylinder_SSF_FeedRate_MAN_Conventional_LCD; };
    get I39() { return this.GetCylinder(7)?.Cylinder_SSF_FeedRate_MAN_Conventional_LCD; };
    get I40() { return this.GetCylinder(8)?.Cylinder_SSF_FeedRate_MAN_Conventional_LCD; };
    get I41() { return this.GetCylinder(9)?.Cylinder_SSF_FeedRate_MAN_Conventional_LCD; };
    get I42() { return this.GetCylinder(10)?.Cylinder_SSF_FeedRate_MAN_Conventional_LCD; };
    get I43() { return this.GetCylinder(11)?.Cylinder_SSF_FeedRate_MAN_Conventional_LCD; };
    get C5() { return this.s.Ship_SSF_CurrentShipName; };
    get C6() { return this.s.Ship_SSF_ManagerName; };
    get H5() { return this.r.Report_SSF_Collection_SampledDate; };
    get L6() { return this.s.Ship_SSF_EngineDesignation; };
    get I21() { return this.s.Ship_SSF_EngineLubricator; };
}
