import React, { Component } from "react";
import sha256 from 'js-sha256'
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { S3Client } from "@aws-sdk/client-s3";

class Authorisation extends Component {
    //#region Statics
    static #_authorisation;
    static #_main;
    static #_db;
    static #_s3;
    static #_storeName = "flameNetcoreReactAuth.1";
    static #_refDate = Date.parse('2000-01-01');
    static #_lastStoreCred = null;
    static CARGO_MARKER = "@@@CARGO@@@";
    
    static checkError2(res) {
        var ret = { isError: true, Error: "Unknown error" };
        if (res && res.target && res.target.response) {
            let json = null, data = null;
            let parse = res.target.response.split(this.CARGO_MARKER);
            if (parse.length > 1) {
                json = parse[0];
                data = parse[1];
            }
            else {
                json = res.target.response;
                data = null;
            }
            ret = JSON.parse(json);
            ret.isError = ret.Code !== "200";
        }
        return ret;
    }
    static Get() {
        return Authorisation.#_authorisation;
    }
    static Set(authorisation) {
        Authorisation.#_authorisation = authorisation;
    }
    static GetMain() {
        return Authorisation.#_main;
    }
    static SetMain(main) {
        Authorisation.#_main = main;
    }
    static GetCredentials() {
        let storedCreds = Authorisation.GetLocalStorageCredentials();
        let authCreds = Authorisation.Get()?.state?.credentials;
        console.log('Authorisation.GetCredentials: stored[' + JSON.stringify(storedCreds) + '], cached[' + JSON.stringify(authCreds)+']');
        return ((Authorisation.Get()?.onUpdateState(storedCreds, authCreds) || authCreds) || storedCreds);
    }
    static GetDynamoDbClient() {

        if(Authorisation.#_db){
            return this.#_db;
        }

        let cred = Authorisation.GetCredentials();
        if (!cred) {
            return null;
        }
        let client = new DynamoDBClient({
            region: cred.awsRegion, 
            credentials:
            {
                'accessKeyId': cred.awsAccessKey,
                'secretAccessKey': cred.awsSecretKey,
                'sessionToken': cred.awsSessionId
            }
        });
        return Authorisation.#_db = client;
    }
    static GetS3Client() {

        if(Authorisation.#_s3){
            return this.#_s3;
        }

        let cred = Authorisation.GetCredentials();
        if (!cred) {
            return null;
        }

        let client = new S3Client({
            region: cred.awsRegion, 
            credentials:
            {
                'accessKeyId': cred.awsAccessKey,
                'secretAccessKey': cred.awsSecretKey,
                'sessionToken': cred.awsSessionId
            }
        });
        return Authorisation.#_s3 = client;
    }
    static GetLocalStorageCredentials() {
        let sn = Authorisation.#_storeName;
        let sc = window.localStorage.getItem(sn);
        if (sc) {
            let cred = JSON.parse(sc);
            if (cred?.expiry) {
                cred.expiry = new Date(cred.expiry);
            }
            return Authorisation.#_lastStoreCred = cred;
        }
        return Authorisation.#_lastStoreCred;
    }
    static ParseSearch(){
        let locsrch = window.location.search.trim();
        if (locsrch[0] === '?') {
            locsrch = locsrch.substring(1).trim();
        }
        let tokens = locsrch.split('&');
        let srchType = 0, srch = null;
        tokens.forEach(I => {
            let elems = I.split('=');
            if (elems.length === 2) {
                switch (elems[0].trim().toLowerCase()) {
                    case 't':
                        srchType = parseInt(elems[1].trim());
                        break;
                    case 's':
                        srch = elems[1].trim();
                        break;
                        default:
                            break;
                }

            }
        });
        return {srchType: srchType, srch: srch};
    }
    static ParseFobasEngineCommandLine() {
        let url = new URL(document.location.href);

        return {
            Path: url.pathname,
            ShipName: url.searchParams.get("sn"),
            SampledDate: url.searchParams.get("sd"),
            DataURL: url.searchParams.get("du"),
            blobid: url.searchParams.get("blobid"),
            reportid: url.searchParams.get("reportid"),
            laboratory: url.searchParams.get("la")
        };
    }

    static SignOut() {
        Authorisation.Get()?.onSignOut();
    }
    //#endregion Statics
    //#region Helpers

    static GetDateSeconds(dt) {
        if (dt) {
            switch (typeof dt) {
                case 'object':
                    if (!(dt = dt.getTime ? dt.getTime() : null)) {
                        throw new Error('GetDateSeconds: Unsupported object');
                    }
                    break;
                    default:
                        break;

            }
        }
        else {
            dt = Date.now();
        }
        return Math.round((dt - Authorisation.#_refDate) / 1000);
    }
    static GetDateFromSeconds(sec) {
        return new Date(Authorisation.#_refDate + (sec * 1000));
    }
    static niceBytes(x) {
        let l = 0, n = parseInt(x, 10) || 0;
        while (n >= 1024 && ++l) {
            n = n / 1024;
        }
        return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + this.units[l]);
    }
    static units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    static shortGuid(id) {
        return id.replace(/-/g, '');
    }

    //#endregion
    constructor(props) {

        super(props);

        this.state = {
            email: '',
            password: '',
            loading: false,
            error: null,
        };
        Authorisation.Set(this);
    }

    //#region Events
    componentDidMount() {
        this.onUpdateState(Authorisation.GetLocalStorageCredentials(), null);
        window.addEventListener('storage', this.onStorageChanged);
    }
    componentWillUnmount() {
        window.removeEventListener('storage', this.onStorageChanged);
    }
    onInputchange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    onSignOut = () => {
        this.onUpdateState(null, null);
        this.setAuthorising();
    }
    onStorageChanged = (event) => {
        if (event.storageArea !== localStorage) return;
        if (event.key === Authorisation.#_storeName) {
            if (event.newValue) {
                Authorisation.Get()?.onUpdateState(JSON.parse(event.newValue), Authorisation.Get()?.state.credentials);
            }
            else {
                Authorisation.Get()?.onUpdateState(null, Authorisation.Get()?.state.credentials);
            }
        }
    }
    onEnterPressed = (event) => {
        if (event.key === "Enter") {
            this.onSubmit(event);
        }
    }
    onSubmit = (event) => {
        let self = this;
        event.preventDefault();

        this.setState({ loading: true, error: null });

        // aws-flame-auth
        let url = 'https://4ya5llughpihmkfclcjk4cozqu0lfdgz.lambda-url.eu-west-2.on.aws/?v=5&d=7200&p=1'
            + '&uid=' + encodeURIComponent(this.state.email)
            + '&pwd=' + encodeURIComponent(this.#_makeHash(this.state.password));
        let xhr = new XMLHttpRequest();
        xhr.self = this;
        xhr.addEventListener("load", function (res) {
            if (res && res.target && res.target.response) {
                let ret = res.target.response;
                if (ret[0] === 'O' && ret[1] === 'K') {
                    //self.setState({ loading: false, credentials: ret });

                    //var version = ret.substring(2, 3);
                    //var tmAWS = parseInt(ret.substring(3, 7));
                    //let data = ret.substring(7);
                    let data = ret.substring(2);
                    let retArr = data.split('\t');

                    let parsed_data = {
                        uid: encodeURIComponent(self.state.email),
                        pwd: encodeURIComponent(self.#_makeHash(self.state.password)),
                        expiry: new Date(Date.now() + (90 * 60 * 1000)),
                        awsAccessKey: retArr[0],
                        awsSecretKey: retArr[1],
                        awsRegion: retArr[2],
                        awsSessionId: retArr[3]
                    };

                    self.onUpdateState(Authorisation.GetLocalStorageCredentials(), parsed_data);

                    window.location.reload();
                }
                else {
                    self.onUpdateState(Authorisation.GetLocalStorageCredentials(), null);
                    self.setState({ error: ret });
                }
            }
            else {
                self.onUpdateState(Authorisation.GetLocalStorageCredentials(), null);
                self.setState({ error: 'Unknown error!!' });
            }
        });
        xhr.addEventListener("error", function (e) {
            self.onUpdateState(Authorisation.GetLocalStorageCredentials(), null);
            self.setState({ error: JSON.stringify(e) });
        });

        xhr.open('get', url, true);
        xhr.send();
    }
    onUpdateState(storeCredentials, stateCredentials) {
        let setitem = !storeCredentials;
        if (stateCredentials && storeCredentials) {
            // unchanged?
            if (stateCredentials.expiry === storeCredentials.expiry) {
                // has it expired
                if (storeCredentials.expiry < Date.now()) {
                    storeCredentials = null;
                    stateCredentials = null;
                    setitem = true;
                }
                else {
                    return stateCredentials;
                }
            }
            else if (stateCredentials.expiry < storeCredentials.expiry) {
                stateCredentials = null;
            }
            else {
                storeCredentials = null;
                setitem = true;
            }
        }
        if (storeCredentials) {
            // expired
            if (storeCredentials.expiry < Date.now()) {
                storeCredentials = null;
                setitem = true;
            }
            stateCredentials = storeCredentials;
        }
        else if (stateCredentials) {
            if (stateCredentials.expiry < Date.now()) {
                stateCredentials = null;
            }
            storeCredentials = stateCredentials;
            setitem = true;
        }
        if (setitem) {
            let sn = Authorisation.#_storeName;
            if (storeCredentials) {
                window.localStorage.setItem(sn, JSON.stringify(storeCredentials));
            }
            else {
                window.localStorage.removeItem(sn);
                console.log('window.localStorage[' + sn + '] = ' + window.localStorage.getItem(sn) || '<null>')
            }
        }
        Authorisation.Get()?.setState({ loading: false, credentials: stateCredentials });
        return stateCredentials;
    }
    setAuthorising() {
        let main = Authorisation.GetMain();
        if (main) {
            if (this.state.credentials) {
                if (main.isAuthorising()) {
                    main.setAuthorised();
                }
            }
            else {
                if (!main.isAuthorising()) {
                    main.setAuthorising();
                }
            }
        }
    }
    render() {
        if(this.state.credentials){
            Authorisation.GetMain()?.setAuthorised();
        }
        else{
            Authorisation.GetMain()?.setAuthorising();
        }
        return (
            (this.state.credentials
                ? <div>
                    {this.props.children}
                </div>
                :
                <div className="container">
                    <div className="content">
                        <div className="Login">
                            <div className="FobasMainMenuText">Please enter your login credentials</div>
                            <div className="form">
                                <div className="form-group">
                                    <label htmlFor="uid">Email address</label>
                                    <input type="email" className="form-control" id="email" placeholder="Enter email" name="email" value={this.state.email} disabled={this.state.loading} onChange={this.onInputchange} onKeyDown={this.onEnterPressed} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="pwd">Password</label>
                                    <input type="password" className="form-control" id="password" placeholder="Password" name="password" value={this.state.password} disabled={this.state.loading} onChange={this.onInputchange} onKeyDown={this.onEnterPressed} />
                                </div>
                                <div className="form-group">
                                    <div className="loginError">{this.state.error}</div>
                                </div>
                                <button type="submit" className="btn btn-outline-dark" disabled={this.state.loading} onClick={this.onSubmit}>
                                    Sign In
                                    <span hidden={!this.state.loading} className="spinner-border spinner-border-sm" style={{ marginLeft: 10 }} role="status" aria-hidden="true"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ));
    }

    //#endregion

    //#region Private

    #_makeHash(pwd) {

        if (!pwd) {
            return null;
        }
        let datepart = (new Date()).toISOString().replace(/[^0-9]/g, "").slice(2, 14);
        let hash = sha256.create();
        hash.update(datepart + pwd);
        return datepart + hash.hex();
    }
    //#endregion
}



export default Authorisation;