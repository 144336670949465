import React, { Component } from "react";
import Authorisation from './../components/Authorisation'

class Downloads extends Component {
    componentDidMount() { Authorisation.GetMain()?.onPathChanged(window.location.pathname); }
    render() {
        return (
            <div >
                <div
                    className="summary"
                >Please find below files for Flame Marine articles, forms and instruction manuals.</div>
                <div className="c-box">
                    <div className="c-box-left">
                        <img src="images/s-instructions1.gif" alt="" />
                    </div>
                    <div className="c-box-right">
                        <h3>
                            <a href="https://flame-support.s3.eu-west-2.amazonaws.com/files/Procedure_S.pdf" target="_blank" rel="noreferrer">Sampling Instructions</a>
                        </h3>A large file for printing out.
                    </div>
                </div>
                <div className="c-box">
                    <div className="c-box-left">
                        <img src="/images/thumb-flame-submission-form-new.gif" alt="" />
                    </div>
                    <div className="c-box-right">
                        <h3>
                            <a href="https://flame-support.s3.eu-west-2.amazonaws.com/files/SampleSubmissionForm_V6_150221L.xls" target="_blank" rel="noreferrer">
                                Flame Diagnostic Sample Submission
                                Form
                            </a>
                        </h3>Spreadsheet Sample Submission Form (.xls)
                    </div>
                </div>
                <div className="c-box">
                    <div className="c-box-left">
                        <img src="/images/thumb-fobas-submission-form-new.gif" alt="" />
                    </div>
                    <div className="c-box-right">
                        <h3>
                            <a href="https://flame-support.s3.eu-west-2.amazonaws.com/files/SampleSubmissionForm_V6_Fobas_150221L.xls" target="_blank" rel="noreferrer">
                                FOBAS Engine Sample Submission
                                Form
                            </a>
                        </h3>Spreadsheet Sample Submission Form for FOBAS Engine (.xls)
                    </div>
                </div>
                <div className="c-box">
                    <div className="c-box-left">
                        <img src="/images/ss-form.gif" alt="" />
                    </div>
                    <div className="c-box-right">
                        <h3>
                            <a href="https://flame-support.s3.eu-west-2.amazonaws.com/files/PFI.pdf" target="_blank" rel="noreferrer">Pro Forma Invoice (for sending the samples by courier)</a>
                        </h3>For printing out blank forms.
                    </div>
                </div>
                <div className="c-box">
                    <div className="c-box-left">
                        <img src="/images/pfixls.gif" alt="" />
                    </div>
                    <div className="c-box-right">
                        <h3>
                            <a href="https://flame-support.s3.eu-west-2.amazonaws.com/files/PFI.xlsx" target="_blank" rel="noreferrer">Pro Forma Invoice (for sending the samples by courier)</a>
                        </h3>Spreadsheet to print directly from your computer.
                    </div>
                </div>
                <div className="c-box">
                    <h1>Sample Flame Marine Report</h1>
                    <ul>
                        <li>
                            <a href="https://flame-support.s3.eu-west-2.amazonaws.com/files/FlameDiagnosticSampleReportContainerMBW12K90MC.pdf" target="_blank" rel="noreferrer">
                                Flame Diagnostic
                                Sample Report for Container MBW12K90M
                            </a>
                        </li>
                    </ul>
                    <h1>Articles</h1>
                    <ul>
                        <li>
                            <a href="https://flame-support.s3.eu-west-2.amazonaws.com/files/martech2000.pdf" target="_blank" rel="noreferrer">Martech 2000, Singapore</a>
                        </li>
                        <li>
                            <a href="https://flame-support.s3.eu-west-2.amazonaws.com/files/cimac2001.pdf" target="_blank" rel="noreferrer">
                                23rd CIMAC World Congress on Combustion Engine Technology
                                2001, Hamburg
                            </a>
                        </li>
                        <li>
                            <a href="https://flame-support.s3.eu-west-2.amazonaws.com/files/MotorshipMay03.pdf" target="_blank" rel="noreferrer">
                                The Motor Ship Marine Propulsion Conference
                                2003, Hamburg
                            </a>
                        </li>
                        <li>
                            <a href="https://flame-support.s3.eu-west-2.amazonaws.com/files/CIMAC2004.pdf" target="_blank" rel="noreferrer">
                                24th CIMAC World Congress on Combustion Engine Technology
                                2004, Kyoto
                            </a>
                        </li>
                    </ul>
                    <h1>Technical Papers</h1>
                    <ul>
                        <li>
                            <a href="https://flame-support.s3.eu-west-2.amazonaws.com/files/AMCpaper.pdf" target="_blank" rel="noreferrer">
                                Design of ship engines for reduced emissions of oxides
                                of nitrogen - Australian Maritime College
                            </a>
                        </li>
                        <li>
                            <a href="https://flame-support.s3.eu-west-2.amazonaws.com/files/ManBW.pdf" target="_blank" rel="noreferrer">Emission Control MAN B&W Two-stroke Diesel Engines</a>
                        </li>
                        <li>
                            <a href="https://flame-support.s3.eu-west-2.amazonaws.com/files/MarpolAnnex.pdf" target="_blank" rel="noreferrer">MARPOL 73/78 ANNEX VI - NOx and SOx Controls</a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default Downloads;