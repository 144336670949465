import React, { Component } from "react";

class DisplayTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            completed: props.ver !== 'prog' && props.ver !== 'setup',
            setup: props.ver === 'setup',
            sort: {
                feap: { name: 'ReportSampledDate', descending: true },
                setup: { name: 'ReportSampledDate', descending: true },
                full: { name: 'ReportSampledDate', descending: true },
                prog: { name: 'ReportSampledDate', descending: true }
            }
        };
    }
    dynamicSort(property, desc) {
        var sortOrder = 1;
        if (desc) {
            sortOrder = -1;
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }
     sort(e, name) {
        let sortState = this.state?.sort || {};
        let s = sortState[this.props.ver];
        let descending = false;
        if (!s || s.name !== name) {
            if (name.endsWith('Date')) {
                descending = true;
            }
        }
        else {
            descending = !s.descending;
        }
        sortState[this.props.ver] = { name: name, descending: descending };
        this.setState({ sort: sortState });
        this.props.items.sort(this.dynamicSort(name, descending));
    }
    sorticon(name) {
        let s = this.state?.sort[this.props.ver];
        if (!s || s.name !== name) {
            return <i className="fas fa-sort sortIconPosition" aria-hidden="true"></i>;
        }
        else {
            if (s.descending) {
                return <i className="fas fa-sort-up sortIconPosition" aria-hidden="true"></i>;
            }
            else {
                return <i className="fas fa-sort-down sortIconPosition" aria-hidden="true"></i>;
            }
        }
    }
    render() {
        // if (this.props.items == null || this.props.items.length === 0) {
        //     return (<div className="FobasListNoData">No Reports</div>);
        // }

        if (this.state.completed) {
            return (
                <table className="table table-hover FobasListTable">
                    <thead>
                        <tr key="compl">
                            <th scope="col" onClick={(e) => this.sort(e, 'ReportSampledDate')}>Sampled{this.sorticon('ReportSampledDate')}</th>
                            <th scope="col" onClick={(e) => this.sort(e, 'ReportType')}>Type{this.sorticon('ReportType')}</th>
                            <th scope="col" onClick={(e) => this.sort(e, 'ShipName')}>Ship{this.sorticon('ShipName')}</th>
                            <th scope="col" onClick={(e) => this.sort(e, 'AddressName')}>Customer{this.sorticon('AddressName')}</th>
                            <th scope="col" onClick={(e) => this.sort(e, 'ShipDesignation')}>Engine{this.sorticon('ShipDesignation')}</th>
                            <th scope="col" onClick={(e) => this.sort(e, 'ReportCompletionDate')}>Completed{this.sorticon('ReportCompletionDate')}</th>
                        </tr>
                    </thead>
                    {
                        this.props.msg
                            ? <tbody>
                                <tr key="nodata">
                                    <td colSpan="6" className={this.props.msg.toLowerCase().startsWith("error:") ? "FobasListError" : "FobasListNoData"}>{this.props.msg}</td>
                                </tr>
                            </tbody>
                            : (this.props.items == null || this.props.items.length === 0
                                ?
                                <tbody>
                                    <tr key="nodata">
                                        <td colSpan="6" className="FobasListNoData">No Reports</td>
                                    </tr>
                                </tbody>
                                : <tbody>
                                    {(this.props.items || []).map((i) =>
                                        <tr key={i.BlobId} id={i.ReportId}>
                                            <td><a target={"_blank"} rel={"noreferrer"} href={this.itemQuery(i)}>{i.ReportSampledDate.substring(0, 10)}</a></td>
                                            <td>{i.ReportType}</td>
                                            <td>{i.ShipName}</td>
                                            <td width="350">{i.AddressName}</td>
                                            <td>{i.ShipDesignation}</td>
                                            <td>{i.ReportCompletionDate}</td>
                                        </tr>
                                    )}
                                </tbody>)}
                </table>
            );
        }
        else if(this.state.setup){
            return (
                <table className="table table-hover FobasListTable">
                    <thead>
                        <tr key={"newRep"}>
                            <th scope="col"></th>
                            <th scope="col" onClick={(e) => this.sort(e, 'ReportSampledDate')}>Sampled{this.sorticon('ReportSampledDate')}</th>
                            <th scope="col" onClick={(e) => this.sort(e, 'ReportLandedDate')}>Landed{this.sorticon('ReportLandedDate')}</th>
                            <th scope="col" onClick={(e) => this.sort(e, 'ReportReceivedDate')}>Received{this.sorticon('ReportReceivedDate')}</th>
                            <th scope="col" onClick={(e) => this.sort(e, 'ReportLaboratory')}>Laboratory{this.sorticon('ReportLaboratory')}</th>
                            <th scope="col" onClick={(e) => this.sort(e, 'ReportType')}>Type{this.sorticon('ReportType')}</th>
                            <th scope="col" onClick={(e) => this.sort(e, 'ShipName')}>Ship{this.sorticon('ShipName')}</th>
                            <th scope="col" onClick={(e) => this.sort(e, 'ShipDesignation')}>Engine{this.sorticon('ShipDesignation')}</th>
                        </tr>
                    </thead>
                    {this.props.items == null || this.props.items.length === 0
                        ? <tbody><tr><td colSpan="6" className="FobasListNoData">No Reports</td></tr></tbody>
                        : <tbody>
                            {this.props.items.map((i) =>
                                <tr key={i.BlobId} id={i.ReportId}>
                                    <td>{i.OnHold.length > 0 ? <i title={i.OnHold} className="fas fa-info-circle blue"></i> : ""}</td>
                                    <td><a target={"_blank"} rel={"noreferrer"} href={this.itemQuery(i)}>{i.ReportSampledDate.substring(0, 10)}</a></td>
                                    <td>{i.ReportLandedDate.length >= 10 ? i.ReportLandedDate.substring(0, 10) : ''}</td>
                                    <td>{i.ReportReceivedDate.length >= 10 ? i.ReportReceivedDate.substring(0, 10) : ''}</td>
                                    <td>{i.ReportReceivedDate.length >= 10 ? i.ReportLaboratory : ''}</td>
                                    <td>{i.ReportType}</td>
                                    <td>{i.ShipName}</td>
                                    <td>{i.ShipDesignation}</td>
                                </tr>
                            )}
                        </tbody>}
                </table>
            );    
        }
        return (
            <table className="table table-hover FobasListTable">
                <thead>
                    <tr key={"newRep"}>
                        <th scope="col" onClick={(e) => this.sort(e, 'ReportSampledDate')}>Sampled{this.sorticon('ReportSampledDate')}</th>
                        <th scope="col" onClick={(e) => this.sort(e, 'ReportType')}>Type{this.sorticon('ReportType')}</th>
                        <th scope="col" onClick={(e) => this.sort(e, 'ShipName')}>Ship{this.sorticon('ShipName')}</th>
                        <th scope="col" onClick={(e) => this.sort(e, 'ShipDesignation')}>Engine{this.sorticon('ShipDesignation')}</th>
                    </tr>
                </thead>
                {this.props.items == null || this.props.items.length === 0
                    ? <tbody><tr><td colSpan="6" className="FobasListNoData">No Reports</td></tr></tbody>
                    : <tbody>
                        {this.props.items.map((i) =>
                            <tr key={i.BlobId} id={i.ReportId}>
                                <td><a target={"_blank"} rel={"noreferrer"} href={this.itemQuery(i)}>{i.ReportSampledDate.substring(0, 10)}</a></td>
                                <td>{i.ReportType}</td>
                                <td>{i.ShipName}</td>
                                <td>{i.ShipDesignation}</td>
                            </tr>
                        )}
                    </tbody>}
            </table>
        );
    }
    itemQuery = (item) => {
        return "/FobasEngine?sn=" + encodeURIComponent(item.ShipName)
            + "&sd=" + encodeURIComponent(item.ReportSampledDate.substring(0, 10))
            + (this.state.setup && item.ReportReceivedDate.length >= 10 ?  "&la=" + encodeURIComponent(item.ReportLaboratory) : "")
            + "&blobid=" + encodeURIComponent(item.BlobId)
            + "&reportid=" + encodeURIComponent(item.ReportId);

    }
}

export default DisplayTable;
