import React, { Component } from "react";
import {
    BrowserRouter,
    NavLink,
    Route,
    Routes,
    Navigate
} from "react-router-dom";
import { QueryCommand } from "@aws-sdk/client-dynamodb";
import { ListObjectsCommand, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

import Home from "./Views/Home";
import Savings from "./Views/Savings";
import Maintenance from "./Views/Maintenance";
import Environment from "./Views/Environment";
import InDetail from "./Views/InDetail";
import Lloyds from "./Views/Lloyds";
import Company from "./Views/Company";
import FAQ from "./Views/FAQ";
import Downloads from "./Views/Downloads";
import FobasSetup from "./Views/FobasSetup";
import FobasInProgressEngine from "./Views/FobasInProgressEngine";
import FobasCompletedEngine from "./Views/FobasCompletedEngine";
import FobasCompletedFEAP from "./Views/FobasCompletedFEAP";
import Authorisation from "./components/Authorisation";
import { encode } from 'uint8-to-base64';

import FobasEngineDilution from "./Views/Engine/FobasEngineDilution"
import FobasEngineFiles from "./Views/Engine/FobasEngineFiles"
import FobasEngineFuel from "./Views/Engine/FobasEngineFuel"
import FobasEngineGraphs from "./Views/Engine/FobasEngineGraphs"
import FobasEngineOil from "./Views/Engine/FobasEngineOil"
import FobasEngineSSF from "./Views/Engine/FobasEngineSSF"
import FobasEngineSSF2 from "./Views/Engine/FobasEngineSSF2"
import FobasEngineShip from "./Views/Engine/FobasEngineShip"
import FobasEngineComplete from "./Views/Engine/FobasEngineComplete"

class Main extends Component {
    constructor(props) {
        super(props);

        Authorisation.SetMain(this);

        this.state = {
            navigate: null,
            authorising: false,
            showSelect: false,
            showSelectFeap: false,
            showSelectFull: false,
            isFobas: window.location.pathname.toLowerCase().startsWith('/fobas'),
            fobasInProgress: window.location.pathname.toLowerCase().startsWith('/fobasinprogress'),
            isFobasEngine: window.location.pathname.toLowerCase().startsWith('/fobasengine'),
            shipMatchName: '',
            shipNames: [],
            shipLookup: [],
            searchText: null,
            searchClear: false,
            fobasEngineCmd: Authorisation.ParseFobasEngineCommandLine(),
            fobasEngineData: null,
            fobasEngineDataPreviousShipURL: null,
            loading: false,
            msg: null,
        };
        window.addEventListener('storage', (event) => {
            if (event.storageArea !== localStorage) return;
            if (event.key === 'fobasUpdated' && event.newValue === 'true') {
                // Do something with event.newValue
                window.localStorage.setItem("fobasUpdated", "false");
                window.location.reload();
            }
        });

    }
    async componentDidMount() {
        console.log('Main.componentDidMount');
        let p = window.location.pathname.toLowerCase();
        if (p.startsWith("/fobasengine")) {
            // load data
            await this.loadFobasEngineData();
        }
        else if (p.startsWith("/fobas")) {
            return this.loadShipNames();
        }
    }
    componentDidUpdate() {
        if (this.state.navigate && this.navigateLink) {
            this.setState({ navigate: null });
            this.navigateLink.click();
        }
    }
    onPathChanged(path) {
        console.log('Main.onPathChanged:' + path);

        let p = window.location.pathname.toLowerCase();
        let isFobas = p.startsWith("/fobas");
        let isFobasEngine = p.startsWith("/fobasengine");

        let showSelectFeap = p.startsWith("/fobascompletedfeap"), showSelectFull = p.startsWith("/fobascompletedengine");
        let showSearch = window.location.search;
        let shipName = '';
        let srch = Authorisation.ParseSearch();
        switch (srch.srchType) {
            case 1: // search by ship. set 
                for (const [key, value] of Object.entries(this.state.shipLookup)) {
                    if (value === srch.srch) {
                        shipName = key;
                        break;
                    }
                }
                break;
            case 2:
                shipName = srch.srch;
                break;
            default:
                break;
        }

        if (showSelectFeap !== this.state.showSelectFeap || showSelectFull !== this.state.showSelectFull || showSearch !== this.state.showSearch || showSelectFeap !== this.state.showSelectFeap || isFobas !== this.state.isFobas || isFobasEngine !== this.state.isFobasEngine) {
            this.setState(
                {
                    searchClear: (shipName || '').length > 0,
                    shipMatchName: shipName,
                    showSelect: showSelectFeap || showSelectFull,
                    showSelectFeap: showSelectFeap,
                    showSelectFull: showSelectFull,
                    showSearch: showSearch,
                    isFobas: isFobas,
                    isFobasEngine: isFobasEngine,
                });
        }
    }
    async loadShipNames() {
        try {

            if (this.state.shipNames?.length > 0) {
                return;
            }
            let client = Authorisation.GetDynamoDbClient();
            if (!client) {
                return;
            }
            let srch = Authorisation.ParseSearch();

            var queryParams = {
                IndexName: 'IxQueue',
                ScanIndexForward: true,
                ConsistentRead: false,
                ReturnConsumedCapacity: 'NONE',
                KeyConditionExpression: 'q=:q',
                FilterExpression: 'a=:a',
                ProjectionExpression: 's2,w,n3',
                TableName: 'FmsObjects8',
                ExpressionAttributeValues: {
                    ':a': { 'N': '1' },
                    ':q': { 'N': '4' }
                }
            };
            let items = queryParams.ExclusiveStartKey ? this.state.shipNames : [];
            let lookup = queryParams.ExclusiveStartKey ? this.state.shipLookup : {};
            let self = this;
            let shipMatchName = null;
            let resp = null;

            do {
                resp = await client.send(new QueryCommand(queryParams));
                resp.Items.forEach((I) => {
                    let v = encodeURIComponent(encode(I.w.B));
                    let feap = I.n3.N === '41';
                    let name = I.s2.S;

                    items.push({ name: name, feap: feap });
                    lookup[name] = v;
                    if (srch.srchType === 1 && v === srch.srch) {
                        shipMatchName = name;
                    }
                });
                console.log('Main.loadShipNames: retrieved ' + resp.Items.length.toString() + ' rows');
                queryParams.ExclusiveStartKey = resp.LastEvaluatedKey;
            } while (queryParams.ExclusiveStartKey);
            if (srch.srchType === 2) {
                shipMatchName = decodeURIComponent(srch.srch);
            }
            self.setState({
                shipNames: items,
                shipLookup: lookup,
                shipMatchName: shipMatchName,
                searchClear: (shipMatchName || '').length > 0
            });
        }
        catch (err) {
            this.setState(
                {
                    msg: 'Error:' + JSON.stringify(err),
                    shipNames: [],
                    shipLookup: {},
                    searchClear: false,
                });

        }
    }
    async loadFobasEngineData() {
        try {

            if (this.state.fobasEngineData) {
                return;
            }
            let client = Authorisation.GetS3Client();
            if (!client) {
                return;
            }
            this.setState({ loading: true });
            if (!this.state.fobasEngineCmd?.blobid) {
                throw new Error('Main.loadFobasEngineData: missing blobid');
            }
            var getObject = {
                Bucket: 'flame-fms-summary',
                Key: this.state.fobasEngineCmd.blobid.replaceAll('-', '')
            };
            var resp = await client.send(new GetObjectCommand(getObject));

            const response = new Response(resp.Body);

            let parse = (await response.text()).split(Authorisation.CARGO_MARKER);
            let retData = null;
            if (parse.length > 0) {
                retData = JSON.parse(parse[0]);
                retData.Graphs = parse.length > 1 ? parse[1] : '';
            }

            var respList = await client.send(new ListObjectsCommand({ Bucket: 'flame-fms-files', Prefix: retData.Reports[0].Report_Id.replaceAll('-', '').toLowerCase() }));
            let files = [];
            for (var i = 0; i < respList.Contents.length; i++) {
                const key = respList.Contents[i].Key;
                const url = await getSignedUrl(client, new GetObjectCommand({ Bucket: 'flame-fms-files', Key: key }), { expiresIn: 3600 * 8 });
                const tokens=key.split('/');
                files.push({
                    Type: tokens[1],
                    Name: tokens[2],
                    Url: url,
                    Size: Authorisation.niceBytes(respList.Contents[i].Size)
                });
            }
            retData.Files = files;

            // let dbclient = Authorisation.GetDynamoDbClient();
            // if (!client) {
            //     return;
            // }
            // var queryParams = {
            //     ScanIndexForward: false,
            //     ConsistentRead: false,
            //     ReturnConsumedCapacity: 'NONE',
            //     KeyConditionExpression: 'p=:p',
            //     FilterExpression: 'a=:a',
            //     ProjectionExpression: 's,w,b',
            //     TableName: 'FmsObjects8',
            //     ExpressionAttributeValues: {
            //         ':a': { 'N': '1' },
            //         ':p': { 'B':  }
            //     }
            // };




            this.setState({
                loading: false,
                fobasEngineData: retData,
                //fobasEngineDataPreviousShipURL: "http://localhost:3000/FobasEngine?sn=CORATO&sd=2022-07-15&blobid=c4aa90c9994d-4ae8-b8df-f590c1a35bd4&reportid=26f8922c-dda1-4f07-9573-dac1fa8966e7"
            });
        }
        catch (err) {
            this.setState(
                {
                    msg: 'Error:' + JSON.stringify(err),
                    fobasEngineData: null,
                    loading: false,
                });

        }
    }
    isAuthorising() {
        return this.state?.authorising || false;
    }
    setAuthorising() {
        if (!this.state.authorising) {
            this.setState({ authorising: true });
        }
    }
    setAuthorised() {
        if (this.state.authorising) {
            this.setState({ authorising: false });
        }
    }
    getBrand(isFobas, isFobasEngine) {
        if (isFobas || isFobasEngine) {
            return (<img className="BrandImage" width="80" alt="LR Logo" src="/images/LR_Green_253.png" />);
        }
        return (<img src="/images/flame-marine-logo.gif" width="150" alt="Flame" />);
    }
    selectChanged = (e) => {

        console.log('Main.selectChanged');
        e.target.previousElementSibling.value = e.target.value;
        e.target.previousElementSibling.focus();

        this.checkClear(e);

        let path = window.location.pathname.toLowerCase();
        if (path.startsWith('/fobascompletedengine')) {
            path = '/FobasCompletedEngine';
        }
        else if (path.startsWith('/fobascompletedfeap')) {
            path = '/FobasCompletedFEAP';
        }
        else {
            return;
        }
        path += '?t=1&s=' + this.state.shipLookup[e.target.value];
        console.log('Main.selectChanged: Call Navigate[' + path + ']')
        this.setState({ navigate: path });
    };
    inputKeydown = (e) => {
        this.checkClear(e);
        console.log('Main.inputKeydown:' + e.keyCode?.toString());
        if (e.keyCode === 13) {
            let path = window.location.pathname.toLowerCase();
            if (path.startsWith('/fobascompletedengine')) {
                path = '/FobasCompletedEngine';
            }
            else if (path.startsWith('/fobascompletedfeap')) {
                path = '/FobasCompletedFEAP';
            }
            else {
                return;
            }
            path += '?t=2&s=' + encodeURIComponent(e.target.value);
            console.log('Main.selectChanged: Call Navigate[' + path + ']')
            this.setState({ navigate: path });
        }
    }
    inputOnChange = (e) => {
        console.log('Main.inputOnChange: [' + (e.target.value || '-nul-') + ']');
        this.checkClear(e);
    }
    checkClear = (e) => {
        let text = !!e.target.value;
        if (text !== this.state.searchClear) {
            this.setState({ searchClear: text });
        }
    }
    clearSearch = (e) => {
        let path = window.location.pathname.toLowerCase();
        if (path.startsWith('/fobascompletedengine')) {
            path = '/FobasCompletedEngine';
        }
        else if (path.startsWith('/fobascompletedfeap')) {
            path = '/FobasCompletedFEAP';
        }
        else {
            return;
        }
        console.log('Main.clearSearch: Call Navigate[' + path + ']')
        this.setState({ shipMatchName: '', searchClear: false, navigate: path });

    }
    getMenu(showSearch, searchClear, isFobas, isFobasEngine, shipNames, shipMatchName, loading, feInProgress) {
        let index = 0;
        if (isFobas || isFobasEngine) {
            if (isFobas && !isFobasEngine) {
                return (
                    <div className="navbar-nav flex-grow-1 FobasTitle">
                        <div className="FobasListTitle">FOBAS engine</div>
                        <nav hidden={this.state.authorising} className="navbar navbar-expand-lg navbar-light bg-light FobasMergeBar">
                            <ul id="mainMenu" className="navbar-nav mr-auto">
                            <li className="nav-item"><NavLink className="nav-link text-dark" to="/FobasSetup"><i>Setup</i></NavLink></li>
                            <li className="nav-item"><NavLink className="nav-link text-dark" to="/FobasInProgressEngine"><i>Awaiting Completion</i></NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link text-dark" to="/FobasCompletedEngine">FOBAS Engine</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link text-dark" to="/FobasCompletedFEAP">FEAP</NavLink></li>
                            </ul>
                            <div hidden={!showSearch} className="searchBox">
                                <button onClick={this.clearSearch} className={searchClear ? "searchClearButton" : "searchClearButton transparent"}><i className="fas fa-times"></i></button>
                                <div className="dropdown">
                                    <input type="text" onKeyDown={this.inputKeydown} placeholder="Search" defaultValue={shipMatchName} onChange={this.inputOnChange}></input>
                                    <select defaultValue={shipMatchName} onChange={this.selectChanged}>
                                        <option key={-1}></option>
                                        {shipNames.map((i) => <option key={index++}>{i.name}</option>)}
                                    </select>
                                </div>
                            </div>
                        </nav>
                    </div>
                );
            }
            else {
                let data = Authorisation.ParseFobasEngineCommandLine();
                return (
                    <div className="navbar-nav flex-grow-1 FobasTitle">
                        <div className="FobasListTitle">{data.ShipName}/{data.SampledDate}{data.laboratory && data.laboratory.length > 0 ? '/' + data.laboratory : ''}</div>
                        <nav hidden={this.state.authorising || this.state.loading} className="navbar navbar-expand-lg navbar-light bg-light FobasMergeBar">
                            <ul id="mainMenu" className="navbar-nav mr-auto">
                                <li className="nav-item"><NavLink className="nav-link text-dark" to={"/FobasEngineSSF" + window.location.search}>SSF</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link text-dark" to={"/FobasEngineFuel" + window.location.search}>Fuel</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link text-dark" to={"/FobasEngineOil" + window.location.search}>Oil</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link text-dark" to={"/FobasEngineGraphs" + window.location.search}>Graphs</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link text-dark" to={"/FobasEngineDilution" + window.location.search}>Dilution</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link text-dark" to={"/FobasEngineFiles" + window.location.search}>Files</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link text-dark" to={"/FobasEngineShip" + window.location.search}>Ship</NavLink></li>
                                <li hidden={!feInProgress} className="nav-item"><NavLink className="nav-link text-dark" to={"/FobasEngineComplete" + window.location.search}>Complete</NavLink></li>
                                {/* <li hidden={!fobasEngineDataPreviousShipURL} className="nav-item"><a className="nav-link text-dark" target="_blank" rel="noreferrer" href={this.state.fobasEngineDataPreviousShipURL}>Previous</a></li> */}
                            </ul>
                            <div hidden={!showSearch} className="searchBox">
                                <button onClick={this.clearSearch} className={searchClear ? "searchClearButton" : "searchClearButton transparent"}><i className="fas fa-times"></i></button>
                                <div className="dropdown">
                                    <input type="text" onKeyDown={this.inputKeydown} placeholder="Search" defaultValue={shipMatchName} onChange={this.inputOnChange}></input>
                                    <select defaultValue={shipMatchName} onChange={this.selectChanged}>
                                        <option key={-1}></option>
                                        {shipNames.map((i) => <option key={index++}>{i.name}</option>)}
                                    </select>
                                </div>
                            </div>
                        </nav>
                        <div hidden={this.state.authorising || !this.state.loading}>
                            <span><span className='spinner-border spinner-border-sm' style={{ marginLeft: 10, marginRight: 5 }} role="status" aria-hidden="true"></span>Loading...</span>
                        </div>
                    </div>
                );
            }
        }
        return (<ul id="mainMenu" className="navbar-nav mr-auto">
            <li className="nav-item"><NavLink className="nav-link text-dark" to="/">Home</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link text-dark" to="/Savings?id=1">Savings</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link text-dark" to="/Maintenance">Maintenance</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link text-dark" to="/Environment">Environment</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link text-dark" to="/InDetail">In Detail</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link text-dark" to="/Lloyds">Lloyds</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link text-dark" to="/Company">Company</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link text-dark" to="/FAQ">FAQ</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link text-dark" to="/Downloads">Downloads</NavLink></li>
        </ul>);
    }
    render() {
        if (this.state.navigate) {
            console.log('Main.render: Navigate[' + this.state.navigate + ']')
            return (<a ref={a => this.navigateLink = a} href={this.state.navigate}>navigate</a>);
        }
        let isDataInitialised = !!this.state.fobasEngineData;
        let isFEAP = this.state.fobasEngineData?.Reports[0].Report_Type === 'FEAP';
        return (
            <BrowserRouter>
                <header>
                    <nav className="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3 headerContainer">
                        <div className="container headerContainer">
                            <div className="navbar-brand">
                                {this.getBrand(this.state.isFobas, this.state.isFobasEngine)}
                            </div>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainMenuContainer" aria-controls="mainMenuContainer" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div id="mainMenuContainer" className="collapse navbar-collapse">
                                {this.getMenu(  this.state.showSelect, 
                                                this.state.searchClear, 
                                                this.state.isFobas, 
                                                this.state.isFobasEngine, 
                                                this.state.showSelect ? this.state.shipNames.filter(i => i.feap === this.state.showSelectFeap) : [],
                                                this.state.shipMatchName, this.state.loading,
                                                this.state.fobasInProgress 
                                                    && this.state.fobasEngineData?.Reports[0].Report_Type === 'Full' 
                                                    && this.state.fobasEngineData?.Reports[0].Report_Tracking_Status === 'InProgress')}
                            </div>
                        </div>
                    </nav>
                </header>
                <div className="container">
                    <div className="content">
                        <Routes>
                            {/* Redirects */}
                            <Route exact path="/" element={<Home />} />
                            <Route exact path="/Fobas" element={<Navigate to="/FobasInProgressEngine" />} />
                            <Route exact path="/FobasEngine" element={<Navigate to={"/FobasEngineSSF" + window.location.search} />} />
                            {/* Unauthorised access */}
                            <Route path="/Savings" element={<Savings />} />
                            <Route path="/Maintenance" element={<Maintenance />} />
                            <Route path="/Environment" element={<Environment />} />
                            <Route path="/InDetail" element={<InDetail />} />
                            <Route path="/Lloyds" element={<Lloyds />} />
                            <Route path="/Company" element={<Company />} />
                            <Route path="/FAQ" element={<FAQ />} />
                            <Route path="/Downloads" element={<Downloads />} />

                            {/* Authorised access */}
                            {/* Lists */}
                            <Route path="/FobasSetup" element={<Authorisation><FobasSetup /></Authorisation>} />
                            <Route path="/FobasInProgressEngine" element={<Authorisation><FobasInProgressEngine /></Authorisation>} />
                            <Route path="/FobasCompletedEngine" element={<Authorisation><FobasCompletedEngine /></Authorisation>} />
                            <Route path="/FobasCompletedFEAP" element={<Authorisation><FobasCompletedFEAP /></Authorisation>} />
                            {/* Detail */}
                            <Route path="/FobasEngineDilution" element={<Authorisation><FobasEngineDilution data={this.state.fobasEngineData} /></Authorisation>} />
                            <Route path="/FobasEngineFiles" element={<Authorisation><FobasEngineFiles data={this.state.fobasEngineData} /></Authorisation>} />
                            <Route path="/FobasEngineFuel" element={<Authorisation><FobasEngineFuel data={this.state.fobasEngineData} /></Authorisation>} />
                            <Route path="/FobasEngineGraphs" element={<Authorisation><FobasEngineGraphs data={this.state.fobasEngineData} /></Authorisation>} />
                            <Route path="/FobasEngineOil" element={<Authorisation><FobasEngineOil data={this.state.fobasEngineData} /></Authorisation>} />
                            <Route path="/FobasEngineSSF" element={isDataInitialised ? (isFEAP ? <Authorisation><FobasEngineSSF2 data={this.state.fobasEngineData} /></Authorisation> : <Authorisation><FobasEngineSSF data={this.state.fobasEngineData} /></Authorisation>) : <div></div>} />
                            <Route path="/FobasEngineShip" element={<Authorisation><FobasEngineShip data={this.state.fobasEngineData} /></Authorisation>} />
                            <Route path="/FobasEngineComplete" element={<Authorisation><FobasEngineComplete data={this.state.fobasEngineData} /></Authorisation>} />

                        </Routes>
                    </div>
                </div>
                <footer className="footer text-muted">
                    <div className="container">Flame Marine Ltd, its website and all related files are copyright &copy; 1998-{new Date().getFullYear().toString()}</div>
                </footer>
            </BrowserRouter>
        );
    }

    signOut = (e) => {
        Authorisation.SignOut();
        //window.location.reload();
    }
}

export default Main;