import React, { Component } from "react";
import Authorisation from './../components/Authorisation'

class Environment extends Component {
    componentDidMount() { Authorisation.GetMain()?.onPathChanged(window.location.pathname); }
    render() {
        return (
            <div>
                <div className="summary-short">
                    Flame Diagnostic helps ensure <strong>NOx compliance</strong> regulations are upheld,
                    without requiring any expensive third party equipmen. Lower feed
                    rate means <strong>less waste oil disposal</strong>, and efficient combustion means fewer emissions.
                </div>
                <div className="column-left topoffset">
                    <h2>Environmental Compliance</h2>
                    <div className="primary-text">
                        In November 1997, the International Maritime Organisation (IMO) issued Annex VI
                        of the International Convention for the Pollution from Ships (otherwise known as
                        Marpol 73/78) dealing with Air Pollution. Although adopted in November 1997, the
                        regulations didn't come into force until 19th May 2005. Specifically (from IMO documentation):
                        <p className="bold">
                            Annex VI sets limits on emissions of nitrogen oxides (NOx) from diesel engines.
                            A NOx Technical Code, developed by IMO, defines how this is to be done.
                        </p>Work carried out by leading industry research departments have shown that engines
                        operating efficiently and within published guidelines meet the reguatory requirements
                        of Annex VI. These have also declared that engines meeting the evaluation criteria
                        of the Unified Technical File will be compliant with the NOx requirements of IMO
                        Marpol 73/78 Annex VI. Flame Diagnostic monitors the NOx parameters defined by the
                        Unified Technical File and provides an environmental section within the report that
                        can be included within the ship technical file to demonstrate compliance with Annex
                        VI.
                    </div>
                    <h2>Why is this important?</h2>
                    <div className="primary-text">
                        <p>
                            Ships are required to carry a compliance certificate issued by their flag state.
                            The compliance inspectors need to determine comliance with the NOx regulations defined
                            in Annex VI. Although there are third party 'add ons' which can monitor the NOx
                            being emitted from the stack, MAN B&amp;W have determined after extensive testing
                            that <strong>no third party equipment</strong> need be installed or maintained in
                            order to meet compliance. The collection of engine performance data together with
                            verification of engine fuel injector cleanliness is sufficient to meet the regulations.
                        </p>
                        <p>
                            As part of a Flame Diagnostic report, the defined parameters and the condition of
                            the fuel injectors are checked without needing to stop the engine or remove the
                            injectors. Furthermore, the values reported by the ship in the engine performance
                            data are verified using 'multi-phenomena'. This means that values used in the environmental
                            compliance section are verified by looking at the combustion characteristics of
                            the engine to give confidence in the reported figures.
                        </p>
                        <p>
                            This is best illustrated with a simple example: if the ship reports a good Pmax
                            but we see combustion is poor, there is a very high probability that the Pmax figure
                            is wrong. Why? Because poor combustion leads to a reduction in the maximum pressure
                            created during the engine cycle. A Flame Diagnostic report looks at over 1000 different
                            combinations like this when weighing its interpretations.
                        </p>
                        <p>
                            It is possible for a chief engineer to simply write the reported values for Pmax,
                            Ptdc, etc. into the technical file for the ship but diligent ship and classification
                            society inspectors will ask the chief to prove that the figures he has written are
                            accurate. <strong>Flame Diagnostic helps provide that proof.</strong>
                        </p>
                        <p>
                            But, of course, there is much more to Annex VI than simple compliance. The spirit
                            of Annex VI and the next revision, which came into force in 2010,
                            looks at other pollutants. The Flame Diagnostic report already comments upon hydrocarbon
                            and particulate pollution.
                        </p>
                        <div className="padding clear">
                            <img src="/images/EnvPollution.gif" className="shadow-border" alt="EnvPmaxPtdc" />
                        </div>
                    </div>
                </div>
                <div className="column-right">
                    <div className="topoffset">
                        <img src="/images/EnvPmaxPtdc-thumb.gif" className="shadow-border" alt="EnvPmaxPtdc" />
                        <div className="caption small-text clear small-topoffset">
                            Values used in the environmental compliance section are verified by looking at the
                            combustion characteristics of the engine to give confidence in the reported figures.
                        </div>
                    </div>
                    <div className="topoffset">
                        <img src="/images/EnvInjectors-thumb.gif" className="shadow-border" alt="EnvPmaxPtdc" />
                        <div
                            className="caption small-text clear small-topoffset"
                        >Monitoring injector function to ensure efficient combustion.</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Environment;