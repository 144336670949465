import React, { Component } from "react";
import { GenerateSSFMap } from '../../Support/GenerateSSFMap';

class FobasEngineSSF extends Component {
    render() {
        var r = this.props.data;
        var d = r ? new GenerateSSFMap(r.Ship, r.Reports[0]) : {};

        return (
            <div id='fullscreen_scroller'>
                <div id='outer'>
                    <div id='inner'>
                        <table cellPadding="0" cellSpacing="0" className="t centreHoriz">
                            <colgroup>
                                <col style={{ width: "64px" }} />
                                <col style={{ width: "75px" }} />
                                <col style={{ width: "64px" }} />
                                <col style={{ width: "71px" }} />
                                <col style={{ width: "97px" }} />
                                <col style={{ width: "67px" }} />
                                <col style={{ width: "73px" }} />
                                <col style={{ width: "73px" }} />
                                <col style={{ width: "66px" }} />
                                <col style={{ width: "79px" }} />
                                <col style={{ width: "93px" }} />
                                <col style={{ width: "64px" }} />
                                <col style={{ width: "64px" }} />
                                <col style={{ width: "82px" }} />
                            </colgroup>
                            <tbody>
                                <tr className="r">
                                    <td colSpan="14" className="dt">Collection/ Landing Information</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Ship Name</td>
                                    <td colSpan="3" className="da">{d.C5}</td>
                                    <td colSpan="2" className="dl">Date Sampled</td>
                                    <td colSpan="2" className="da">{(d.H5?.length > 10 ? d.H5.slice(0,10) : d.H5)}</td>
                                    <td colSpan="2" className="dl">Port Landed</td>
                                    <td colSpan="3" className="da">{d.L5}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Owner Manager</td>
                                    <td colSpan="3" className="da">{d.C6}</td>
                                    <td colSpan="2" className="dl">Date Landed</td>
                                    <td colSpan="2" className="da">{(d.H6?.length > 10 ? d.H6.slice(0, 10) : d.H6)}</td>
                                    <td colSpan="2" className="dl">Engine Model</td>
                                    <td colSpan="3" className="da">{d.L6}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="14" className="dt">Conditions during Sampling and Recording of Performance</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Total Engine Hours</td>
                                    <td colSpan="3" className="da">{d.C8}</td>
                                    <td colSpan="2" className="dl">Sea Condition</td>
                                    <td colSpan="2" className="da">{d.H8}</td>
                                    <td colSpan="2" className="dl">Deck Humidity</td>
                                    <td colSpan="2" className="da">{d.L8}</td>
                                    <td className="du">% RH</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Ship Load Condition</td>
                                    <td colSpan="3" className="da">{d.C9}</td>
                                    <td colSpan="2" className="dl">Deck Temp</td>
                                    <td className="da">{d.H9}</td>
                                    <td className="du">ºC</td>
                                    <td colSpan="2" className="dl">Atmospheric Pressure</td>
                                    <td colSpan="2" className="da">{d.L9}</td>
                                    <td className="du">mBar</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Ship Displacement</td>
                                    <td colSpan="2" className="da">{d.C10}</td>
                                    <td className="du">MT</td>
                                    <td colSpan="2" className="dl">Engine Room Temp</td>
                                    <td className="da">{d.H10}</td>
                                    <td className="du">ºC</td>
                                    <td colSpan="2" className="dl">Scav Air Press</td>
                                    <td colSpan="2" className="da">{d.L10}</td>
                                    <td className="da">{d.N10}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Engine Load Condition</td>
                                    <td colSpan="3" className="da">{d.C11}</td>
                                    <td colSpan="2" className="dl">Scav Air Temp</td>
                                    <td className="da">{d.H11}</td>
                                    <td className="du">ºC</td>
                                    <td colSpan="2" className="dl">Exh Gas Press - Receiver</td>
                                    <td colSpan="2" className="da">{d.L11}</td>
                                    <td className="da">{d.N11}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Engine speed</td>
                                    <td colSpan="2" className="da">{d.C12}</td>
                                    <td className="du">rpm</td>
                                    <td colSpan="2" className="dl">Sea Water Temp</td>
                                    <td className="da">{d.H12}</td>
                                    <td className="du">ºC</td>
                                    <td colSpan="2" className="dl">Exh Gas Press - After Turbine</td>
                                    <td colSpan="2" className="da">{d.L12}</td>
                                    <td className="da">{d.N12}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="5" className="dt">Power Information</td>
                                    <td colSpan="9" className="dt">Consumption</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="5" className="dsm">Please ensure that all available sources of power are correctly entered. <br />Power can have a significant effect on the calculated specific consumption.</td>
                                    <td colSpan="5" className="ds">Cylinder Oil</td>
                                    <td colSpan="4" className="ds">Fuel Oil</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Engine Load</td>
                                    <td colSpan="2" className="da">{d.C15}</td>
                                    <td className="du">% MCR</td>
                                    <td colSpan="3" className="dl">Cylinder Lub Name</td>
                                    <td colSpan="2" className="da">{d.I15}</td>
                                    <td colSpan="2" className="dl">FO Consumption</td>
                                    <td className="da">{d.M15}</td>
                                    <td className="da">{d.N15}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="ds">Power Source</td>
                                    <td className="ds">Power</td>
                                    <td className="ds">Unit</td>
                                    <td className="ds">Type</td>
                                    <td colSpan="3" className="dl">Cylinder Lub BN</td>
                                    <td colSpan="2" className="da">{d.I16}</td>
                                    <td colSpan="2" className="dl">Temp at Flow Meter</td>
                                    <td className="da">{d.M16}</td>
                                    <td className="du">ºC</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Power by Torque Meter</td>
                                    <td className="da">{d.C17}</td>
                                    <td className="da">{d.D17}</td>
                                    <td className="du">Effective</td>
                                    <td colSpan="3" className="dl">SG @ 15ºC</td>
                                    <td colSpan="2" className="da">{d.I17}</td>
                                    <td colSpan="2" className="dl">Temp Before Engine</td>
                                    <td className="da">{d.M17}</td>
                                    <td className="du">ºC</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Power by Li x rpm</td>
                                    <td className="da">{d.C18}</td>
                                    <td className="da">{d.D18}</td>
                                    <td className="du">Effective</td>
                                    <td colSpan="3" className="dl">Oil Temp @ Meter</td>
                                    <td className="da">{d.I18}</td>
                                    <td className="du">ºC</td>
                                    <td colSpan="2" className="dl">Temp at Viscometer</td>
                                    <td className="da">{d.M18}</td>
                                    <td className="du">ºC</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Power by T/C rpm</td>
                                    <td className="da">{d.C19}</td>
                                    <td className="da">{d.D19}</td>
                                    <td className="du">Effective</td>
                                    <td colSpan="3" className="dl">Consumption</td>
                                    <td className="da">{d.I19}</td>
                                    <td className="da">{d.J19}</td>
                                    <td colSpan="2" className="dl">Viscometer Reading</td>
                                    <td className="da">{d.M19}</td>
                                    <td className="du">cSt</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Power by Fuel Cons</td>
                                    <td className="da">{d.C20}</td>
                                    <td className="da">{d.D20}</td>
                                    <td className="du">Effective</td>
                                    <td colSpan="3" className="dl">Specific Feed Rate</td>
                                    <td className="da">{d.I20}</td>
                                    <td className="da">{d.J20}</td>
                                    <td colSpan="4" className="ds">System Oil</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Power by Ind Diagram</td>
                                    <td className="da">{d.C21}</td>
                                    <td className="da">{d.D21}</td>
                                    <td className="da">{d.E21}</td>
                                    <td rowSpan="2" colSpan="3" className="dl">Lubricator Type</td>
                                    <td rowSpan="2" colSpan="2" className="da">{d.I21}</td>
                                    <td colSpan="2" className="dl">Lubricant Name</td>
                                    <td colSpan="2" className="da">{d.M21}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="4" className="dl">Please select Manually drawn or Electronic</td>
                                    <td className="da">{d.E22}</td>
                                    <td colSpan="2" className="dl">Consumption</td>
                                    <td className="da">{d.M22}</td>
                                    <td className="du">Ltrs/day</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="5" className="dt">Fuel Oil Bunker Information</td>
                                    <td colSpan="9" className="dt">Cylinder Lubricator Setting</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Analysis Laboratory</td>
                                    <td colSpan="3" className="da">{d.C24}</td>
                                    <td rowSpan="2" colSpan="4" className="ds">MAN Diesel</td>
                                    <td rowSpan="2" colSpan="4" className="ds">Wartsila</td>
                                    <td rowSpan="2" className="ds">Others</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Bunker Port</td>
                                    <td colSpan="3" className="da">{d.C25}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Date Bunkered</td>
                                    <td colSpan="3" className="da">{(d.C26?.length > 10 ? d.C26.slice(0, 10) : d.C26)}</td>
                                    <td colSpan="2" className="ds"></td>
                                    <td colSpan="2" className="ds"></td>
                                    <td colSpan="2" className="ds">Conventional</td>
                                    <td colSpan="2" className="ds">Jet Pulse</td>
                                    <td className="ds">SIP</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Fuel Specification</td>
                                    <td colSpan="3" className="da">{d.C27}</td>
                                    <td className="dl">Factor</td>
                                    <td className="da">{d.G27}</td>
                                    <td className="dl"></td>
                                    <td className="dl"></td>
                                    <td className="dl">Lever Pos</td>
                                    <td className="da">{d.K27}</td>
                                    <td rowSpan="2" colSpan="2" className="ds">Specific Feed Rate Setting</td>
                                    <td rowSpan="2" className="ds">Common Setting</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Density @ 15ºC</td>
                                    <td colSpan="2" className="da">{d.C28}</td>
                                    <td className="du">kg/m³</td>
                                    <td className="dl">Unit</td>
                                    <td className="da">{d.G28}</td>
                                    <td className="dl"></td>
                                    <td className="dl"></td>
                                    <td className="dl">Pump Factor</td>
                                    <td className="da">{d.K28}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Viscosity @ 50ºC</td>
                                    <td colSpan="2" className="da">{d.C29}</td>
                                    <td className="du">cSt</td>
                                    <td className="dl">HMI/Basic</td>
                                    <td className="da">{d.G29}</td>
                                    <td className="dl">LCD</td>
                                    <td className="da">{d.I29}</td>
                                    <td className="dl">Motor Freq</td>
                                    <td className="da">{d.K29}</td>
                                    <td className="da">{d.L29}</td>
                                    <td className="da">{d.M29}</td>
                                    <td className="da">{d.N29}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Water </td>
                                    <td colSpan="2" className="da">{d.C30}</td>
                                    <td className="du">% v/v</td>
                                    <td rowSpan="2" className="ds">Unit No</td>
                                    <td rowSpan="2" className="ds">HMI % / Adj Factor</td>
                                    <td rowSpan="2" className="ds">Individual Stroke/Feed</td>
                                    <td rowSpan="2" className="ds">Individual LCD</td>
                                    <td colSpan="2" className="ds">Individual</td>
                                    <td rowSpan="2" colSpan="2" className="ds">Individual Specific Feed Rate Setting</td>
                                    <td rowSpan="2" className="ds">Individual Setting</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">MCR</td>
                                    <td colSpan="2" className="da">{d.C31}</td>
                                    <td className="du">%m/m</td>
                                    <td className="ds">Upper</td>
                                    <td className="ds">Lower</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Sulphur</td>
                                    <td colSpan="2" className="da">{d.C32}</td>
                                    <td className="du">%m/m</td>
                                    <td className="ds">1</td>
                                    <td className="da">{d.G32}</td>
                                    <td className="da">{d.H32}</td>
                                    <td className="da">{d.I32}</td>
                                    <td className="da">{d.J32}</td>
                                    <td className="da">{d.K32}</td>
                                    <td className="da">{d.L32}</td>
                                    <td className="da">{d.M32}</td>
                                    <td className="da">{d.N32}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Vanadium</td>
                                    <td colSpan="2" className="da">{d.C33}</td>
                                    <td className="du">mg/kg</td>
                                    <td className="ds">2</td>
                                    <td className="dp">{d.G33}</td>
                                    <td className="dp">{d.H33}</td>
                                    <td className="dp">{d.I33}</td>
                                    <td className="dp">{d.J33}</td>
                                    <td className="dp">{d.K33}</td>
                                    <td className="dp">{d.L33}</td>
                                    <td className="dp">{d.M33}</td>
                                    <td className="dp">{d.N33}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Sodium</td>
                                    <td colSpan="2" className="da">{d.C34}</td>
                                    <td className="du">mg/kg</td>
                                    <td className="ds">3</td>
                                    <td className="da">{d.G34}</td>
                                    <td className="da">{d.H34}</td>
                                    <td className="da">{d.I34}</td>
                                    <td className="da">{d.J34}</td>
                                    <td className="da">{d.K34}</td>
                                    <td className="da">{d.L34}</td>
                                    <td className="da">{d.M34}</td>
                                    <td className="da">{d.N34}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Aluminium</td>
                                    <td colSpan="2" className="da">{d.C35}</td>
                                    <td className="du">mg/kg</td>
                                    <td className="ds">4</td>
                                    <td className="dp">{d.G35}</td>
                                    <td className="dp">{d.H35}</td>
                                    <td className="dp">{d.I35}</td>
                                    <td className="dp">{d.J35}</td>
                                    <td className="dp">{d.K35}</td>
                                    <td className="dp">{d.L35}</td>
                                    <td className="dp">{d.M35}</td>
                                    <td className="dp">{d.N35}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Silicon</td>
                                    <td colSpan="2" className="da">{d.C36}</td>
                                    <td className="du">mg/kg</td>
                                    <td className="ds">5</td>
                                    <td className="da">{d.G36}</td>
                                    <td className="da">{d.H36}</td>
                                    <td className="da">{d.I36}</td>
                                    <td className="da">{d.J36}</td>
                                    <td className="da">{d.K36}</td>
                                    <td className="da">{d.L36}</td>
                                    <td className="da">{d.M36}</td>
                                    <td className="da">{d.N36}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Iron</td>
                                    <td colSpan="2" className="da">{d.C37}</td>
                                    <td className="du">mg/kg</td>
                                    <td className="ds">6</td>
                                    <td className="dp">{d.G37}</td>
                                    <td className="dp">{d.H37}</td>
                                    <td className="dp">{d.I37}</td>
                                    <td className="dp">{d.J37}</td>
                                    <td className="dp">{d.K37}</td>
                                    <td className="dp">{d.L37}</td>
                                    <td className="dp">{d.M37}</td>
                                    <td className="dp">{d.N37}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Nickel</td>
                                    <td colSpan="2" className="da">{d.C38}</td>
                                    <td className="du">mg/kg</td>
                                    <td className="ds">7</td>
                                    <td className="da">{d.G38}</td>
                                    <td className="da">{d.H38}</td>
                                    <td className="da">{d.I38}</td>
                                    <td className="da">{d.J38}</td>
                                    <td className="da">{d.K38}</td>
                                    <td className="da">{d.L38}</td>
                                    <td className="da">{d.M38}</td>
                                    <td className="da">{d.N38}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Calcium</td>
                                    <td colSpan="2" className="da">{d.C39}</td>
                                    <td className="du">mg/kg</td>
                                    <td className="ds">8</td>
                                    <td className="dp">{d.G39}</td>
                                    <td className="dp">{d.H39}</td>
                                    <td className="dp">{d.I39}</td>
                                    <td className="dp">{d.J39}</td>
                                    <td className="dp">{d.K39}</td>
                                    <td className="dp">{d.L39}</td>
                                    <td className="dp">{d.M39}</td>
                                    <td className="dp">{d.N39}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Zinc</td>
                                    <td colSpan="2" className="da">{d.C40}</td>
                                    <td className="du">mg/kg</td>
                                    <td className="ds">9</td>
                                    <td className="da">{d.G40}</td>
                                    <td className="da">{d.H40}</td>
                                    <td className="da">{d.I40}</td>
                                    <td className="da">{d.J40}</td>
                                    <td className="da">{d.K40}</td>
                                    <td className="da">{d.L40}</td>
                                    <td className="da">{d.M40}</td>
                                    <td className="da">{d.N40}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Phosphorus</td>
                                    <td colSpan="2" className="da">{d.C41}</td>
                                    <td className="du">mg/kg</td>
                                    <td className="ds">10</td>
                                    <td className="dp">{d.G41}</td>
                                    <td className="dp">{d.H41}</td>
                                    <td className="dp">{d.I41}</td>
                                    <td className="dp">{d.J41}</td>
                                    <td className="dp">{d.K41}</td>
                                    <td className="dp">{d.L41}</td>
                                    <td className="dp">{d.M41}</td>
                                    <td className="dp">{d.N41}</td>
                                </tr>
                                <tr className="r">
                                    <td rowSpan="2" colSpan="2" className="ds">Recommended Heating Temp</td>
                                    <td className="ds">10cSt</td>
                                    <td className="ds">12cSt</td>
                                    <td className="ds">15cSt</td>
                                    <td className="ds">11</td>
                                    <td className="da">{d.G42}</td>
                                    <td className="da">{d.H42}</td>
                                    <td className="da">{d.I42}</td>
                                    <td className="da">{d.J42}</td>
                                    <td className="da">{d.K42}</td>
                                    <td className="da">{d.L42}</td>
                                    <td className="da">{d.M42}</td>
                                    <td className="da">{d.N42}</td>
                                </tr>
                                <tr className="r">
                                    <td className="da">{d.C43}</td>
                                    <td className="da">{d.D43}</td>
                                    <td className="da">{d.E43}</td>
                                    <td className="ds">12</td>
                                    <td className="dp">{d.G43}</td>
                                    <td className="dp">{d.H43}</td>
                                    <td className="dp">{d.I43}</td>
                                    <td className="dp">{d.J43}</td>
                                    <td className="dp">{d.K43}</td>
                                    <td className="dp">{d.L43}</td>
                                    <td className="dp">{d.M43}</td>
                                    <td className="dp">{d.N43}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="10" className="dt">Individual Cylinder Performance Values</td>
                                    <td colSpan="4" className="dt">Running Hours and Wear</td>
                                </tr>
                                <tr className="r">
                                    <td rowSpan="4" className="ds">Unit No.</td>
                                    <td colSpan="3" className="dl">Performance Source</td>
                                    <td colSpan="4" className="da">{d.E45}</td>
                                    <td rowSpan="2" colSpan="2" className="ds"></td>
                                    <td rowSpan="2" colSpan="3" className="ds">Running Hours</td>
                                    <td rowSpan="2" className="ds">Wear</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="3" className="dl">Load Indicator</td>
                                    <td className="da">{d.E46}</td>
                                    <td colSpan="3" className="dl"></td>
                                </tr>
                                <tr className="r">
                                    <td className="ds">Ptdc</td>
                                    <td className="ds">Pmax</td>
                                    <td className="ds">MIP</td>
                                    <td rowSpan="2" className="ds">Fuel Rack</td>
                                    <td rowSpan="2" className="ds">VIT Index</td>
                                    <td rowSpan="2" className="ds">Local Exhaust (ºC)</td>
                                    <td rowSpan="2" className="ds">Liner Wall Temp (ºC)</td>
                                    <td rowSpan="2" className="ds">Jacket Outlet Temp (ºC)</td>
                                    <td rowSpan="2" className="ds"> </td>
                                    <td rowSpan="2" className="ds">Liner Total Hours</td>
                                    <td rowSpan="2" className="ds">Hours Since Last O/H</td>
                                    <td rowSpan="2" className="ds">Injector Hours</td>
                                    <td rowSpan="2" className="ds">Max Wear at Last Overhaul</td>
                                </tr>
                                <tr className="r">
                                    <td className="da">{d.B48}</td>
                                    <td className="da">{d.C48}</td>
                                    <td className="da">{d.D48}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ds">1</td>
                                    <td className="da">{d.B49}</td>
                                    <td className="da">{d.C49}</td>
                                    <td className="da">{d.D49}</td>
                                    <td className="da">{d.E49}</td>
                                    <td className="da">{d.F49}</td>
                                    <td className="da">{d.G49}</td>
                                    <td className="da">{d.H49}</td>
                                    <td className="da">{d.I49}</td>
                                    <td className="da"></td>
                                    <td className="da">{d.K49}</td>
                                    <td className="da">{d.L49}</td>
                                    <td className="da">{d.M49}</td>
                                    <td className="da">{d.N49}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ds">2</td>
                                    <td className="dp">{d.B50}</td>
                                    <td className="dp">{d.C50}</td>
                                    <td className="dp">{d.D50}</td>
                                    <td className="dp">{d.E50}</td>
                                    <td className="dp">{d.F50}</td>
                                    <td className="dp">{d.G50}</td>
                                    <td className="dp">{d.H50}</td>
                                    <td className="dp">{d.I50}</td>
                                    <td className="du"></td>
                                    <td className="dp">{d.K50}</td>
                                    <td className="dp">{d.L50}</td>
                                    <td className="dp">{d.M50}</td>
                                    <td className="dp">{d.N50}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ds">3</td>
                                    <td className="da">{d.B51}</td>
                                    <td className="da">{d.C51}</td>
                                    <td className="da">{d.D51}</td>
                                    <td className="da">{d.E51}</td>
                                    <td className="da">{d.F51}</td>
                                    <td className="da">{d.G51}</td>
                                    <td className="da">{d.H51}</td>
                                    <td className="da">{d.I51}</td>
                                    <td className="da"></td>
                                    <td className="da">{d.K51}</td>
                                    <td className="da">{d.L51}</td>
                                    <td className="da">{d.M51}</td>
                                    <td className="da">{d.N51}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ds">4</td>
                                    <td className="dp">{d.B52}</td>
                                    <td className="dp">{d.C52}</td>
                                    <td className="dp">{d.D52}</td>
                                    <td className="dp">{d.E52}</td>
                                    <td className="dp">{d.F52}</td>
                                    <td className="dp">{d.G52}</td>
                                    <td className="dp">{d.H52}</td>
                                    <td className="dp">{d.I52}</td>
                                    <td className="du"></td>
                                    <td className="dp">{d.K52}</td>
                                    <td className="dp">{d.L52}</td>
                                    <td className="dp">{d.M52}</td>
                                    <td className="dp">{d.N52}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ds">5</td>
                                    <td className="da">{d.B53}</td>
                                    <td className="da">{d.C53}</td>
                                    <td className="da">{d.D53}</td>
                                    <td className="da">{d.E53}</td>
                                    <td className="da">{d.F53}</td>
                                    <td className="da">{d.G53}</td>
                                    <td className="da">{d.H53}</td>
                                    <td className="da">{d.I53}</td>
                                    <td className="da"></td>
                                    <td className="da">{d.K53}</td>
                                    <td className="da">{d.L53}</td>
                                    <td className="da">{d.M53}</td>
                                    <td className="da">{d.N53}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ds">6</td>
                                    <td className="dp">{d.B54}</td>
                                    <td className="dp">{d.C54}</td>
                                    <td className="dp">{d.D54}</td>
                                    <td className="dp">{d.E54}</td>
                                    <td className="dp">{d.F54}</td>
                                    <td className="dp">{d.G54}</td>
                                    <td className="dp">{d.H54}</td>
                                    <td className="dp">{d.I54}</td>
                                    <td className="du"></td>
                                    <td className="dp">{d.K54}</td>
                                    <td className="dp">{d.L54}</td>
                                    <td className="dp">{d.M54}</td>
                                    <td className="dp">{d.N54}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ds">7</td>
                                    <td className="da">{d.B55}</td>
                                    <td className="da">{d.C55}</td>
                                    <td className="da">{d.D55}</td>
                                    <td className="da">{d.E55}</td>
                                    <td className="da">{d.F55}</td>
                                    <td className="da">{d.G55}</td>
                                    <td className="da">{d.H55}</td>
                                    <td className="da">{d.I55}</td>
                                    <td className="da"></td>
                                    <td className="da">{d.K55}</td>
                                    <td className="da">{d.L55}</td>
                                    <td className="da">{d.M55}</td>
                                    <td className="da">{d.N55}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ds">8</td>
                                    <td className="dp">{d.B56}</td>
                                    <td className="dp">{d.C56}</td>
                                    <td className="dp">{d.D56}</td>
                                    <td className="dp">{d.E56}</td>
                                    <td className="dp">{d.F56}</td>
                                    <td className="dp">{d.G56}</td>
                                    <td className="dp">{d.H56}</td>
                                    <td className="dp">{d.I56}</td>
                                    <td className="du"></td>
                                    <td className="dp">{d.K56}</td>
                                    <td className="dp">{d.L56}</td>
                                    <td className="dp">{d.M56}</td>
                                    <td className="dp">{d.N56}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ds">9</td>
                                    <td className="da">{d.B57}</td>
                                    <td className="da">{d.C57}</td>
                                    <td className="da">{d.D57}</td>
                                    <td className="da">{d.E57}</td>
                                    <td className="da">{d.F57}</td>
                                    <td className="da">{d.G57}</td>
                                    <td className="da">{d.H57}</td>
                                    <td className="da">{d.I57}</td>
                                    <td className="da"></td>
                                    <td className="da">{d.K57}</td>
                                    <td className="da">{d.L57}</td>
                                    <td className="da">{d.M57}</td>
                                    <td className="da">{d.N57}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ds">10</td>
                                    <td className="dp">{d.B58}</td>
                                    <td className="dp">{d.C58}</td>
                                    <td className="dp">{d.D58}</td>
                                    <td className="dp">{d.E58}</td>
                                    <td className="dp">{d.F58}</td>
                                    <td className="dp">{d.G58}</td>
                                    <td className="dp">{d.H58}</td>
                                    <td className="dp">{d.I58}</td>
                                    <td className="du"></td>
                                    <td className="dp">{d.K58}</td>
                                    <td className="dp">{d.L58}</td>
                                    <td className="dp">{d.M58}</td>
                                    <td className="dp">{d.N58}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ds">11</td>
                                    <td className="da">{d.B59}</td>
                                    <td className="da">{d.C59}</td>
                                    <td className="da">{d.D59}</td>
                                    <td className="da">{d.E59}</td>
                                    <td className="da">{d.F59}</td>
                                    <td className="da">{d.G59}</td>
                                    <td className="da">{d.H59}</td>
                                    <td className="da">{d.I59}</td>
                                    <td className="da"></td>
                                    <td className="da">{d.K59}</td>
                                    <td className="da">{d.L59}</td>
                                    <td className="da">{d.M59}</td>
                                    <td className="da">{d.N59}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ds">12</td>
                                    <td className="dp">{d.B60}</td>
                                    <td className="dp">{d.C60}</td>
                                    <td className="dp">{d.D60}</td>
                                    <td className="dp">{d.E60}</td>
                                    <td className="dp">{d.F60}</td>
                                    <td className="dp">{d.G60}</td>
                                    <td className="dp">{d.H60}</td>
                                    <td className="dp">{d.I60}</td>
                                    <td className="du"></td>
                                    <td className="dp">{d.K60}</td>
                                    <td className="dp">{d.L60}</td>
                                    <td className="dp">{d.M60}</td>
                                    <td className="dp">{d.N60}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="11" className="dt">Air cooler / Turbo-Charger</td>
                                    <td colSpan="3" className="dt">Recording Information</td>
                                </tr>
                                <tr className="r">
                                    <td rowSpan="3" className="ds">T/C no.</td>
                                    <td rowSpan="3" className="ds">T/C rpm</td>
                                    <td rowSpan="3" className="ds">Gas In (ºC)</td>
                                    <td rowSpan="3" className="ds">Gas Out (ºC) </td>
                                    <td rowSpan="3" className="ds">Blower In (ºC)</td>
                                    <td rowSpan="3" className="ds">Before Cooler (ºC)</td>
                                    <td rowSpan="3" className="ds">After Cooler (ºC)</td>
                                    <td rowSpan="3" className="ds">Cooling Water In (ºC)</td>
                                    <td rowSpan="3" className="ds">Cooling Water Out (ºC)</td>
                                    <td rowSpan="3" className="ds">Press Drop Across Filter</td>
                                    <td rowSpan="3" className="ds">Press Drop Across Cooler</td>
                                    <td colSpan="3" className="ds">Measured By</td>
                                </tr>
                                <tr className="r">
                                    <td className="dl">Name</td>
                                    <td colSpan="2" className="da">{d.M63}</td>
                                </tr>
                                <tr className="r">
                                    <td className="dl">Rank</td>
                                    <td colSpan="2" className="da">{d.M64}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ds">1</td>
                                    <td className="da">{d.B65}</td>
                                    <td className="da">{d.C65}</td>
                                    <td className="da">{d.D65}</td>
                                    <td className="da">{d.E65}</td>
                                    <td className="da">{d.F65}</td>
                                    <td className="da">{d.G65}</td>
                                    <td className="da">{d.H65}</td>
                                    <td className="da">{d.I65}</td>
                                    <td className="da">{d.J65}</td>
                                    <td className="da">{d.K65}</td>
                                    <td colSpan="3" className="ds">Name of Chief Engineer</td>
                                </tr>
                                <tr className="r">
                                    <td className="ds">2</td>
                                    <td className="dp">{d.B66}</td>
                                    <td className="dp">{d.C66}</td>
                                    <td className="dp">{d.D66}</td>
                                    <td className="dp">{d.E66}</td>
                                    <td className="dp">{d.F66}</td>
                                    <td className="dp">{d.G66}</td>
                                    <td className="dp">{d.H66}</td>
                                    <td className="dp">{d.I66}</td>
                                    <td className="dp">{d.J66}</td>
                                    <td className="dp">{d.K66}</td>
                                    <td colSpan="3" className="da">{d.L66}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ds">3</td>
                                    <td className="da">{d.B67}</td>
                                    <td className="da">{d.C67}</td>
                                    <td className="da">{d.D67}</td>
                                    <td className="da">{d.E67}</td>
                                    <td className="da">{d.F67}</td>
                                    <td className="da">{d.G67}</td>
                                    <td className="da">{d.H67}</td>
                                    <td className="da">{d.I67}</td>
                                    <td className="da">{d.J67}</td>
                                    <td className="da">{d.K67}</td>
                                    <td colSpan="3" className="ds">Name of Superintendent</td>
                                </tr>
                                <tr className="r">
                                    <td className="ds">4</td>
                                    <td className="dp">{d.B68}</td>
                                    <td className="dp">{d.C68}</td>
                                    <td className="dp">{d.D68}</td>
                                    <td className="dp">{d.E68}</td>
                                    <td className="dp">{d.F68}</td>
                                    <td className="dp">{d.G68}</td>
                                    <td className="dp">{d.H68}</td>
                                    <td className="dp">{d.I68}</td>
                                    <td className="dp">{d.J68}</td>
                                    <td className="dp">{d.K68}</td>
                                    <td colSpan="3" className="da">{d.L68}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="14" className="dt">Samples Landed</td>
                                </tr>
                                <tr className="r">
                                    <td rowSpan="2" colSpan="2" className="ds">Total No. of Oil Sample Landed (bottles)</td>
                                    <td rowSpan="2" colSpan="2" className="ds">Number of CDO Samples (bottles)</td>
                                    <td colSpan="2" className="dl">System Oil</td>
                                    <td colSpan="2" className="da">{d.G70}</td>
                                    <td colSpan="2" className="dl">Fuel Oil Before Purifier</td>
                                    <td colSpan="4" className="da">{d.K70}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">New Cylinder Oil</td>
                                    <td colSpan="2" className="da">{d.G71}</td>
                                    <td colSpan="2" className="dl">Fuel Oil After Purifier</td>
                                    <td colSpan="4" className="da">{d.K71}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="da">{d.A72}</td>
                                    <td colSpan="2" className="da">{d.C72}</td>
                                    <td colSpan="2" className="dl">New System Oil</td>
                                    <td colSpan="2" className="da">{d.G72}</td>
                                    <td colSpan="2" className="dl">Fuel Oil Before Engine</td>
                                    <td colSpan="4" className="da">{d.K72}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="14" className="dt">Comments</td>
                                </tr>
                                <tr className="r">
                                    <td rowSpan="5" colSpan="14" className="dat">{d.A74}</td>
                                </tr>
                                <tr className="r"></tr>
                                <tr className="r"></tr>
                                <tr className="r"></tr>
                                <tr className="r"></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

}

export default FobasEngineSSF;
