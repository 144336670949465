import React, { Component } from "react";

class FobasEngineDilution extends Component {

    getPercentage(refValue, varValue, dp) {
        if (refValue > 0 && varValue > 0) {
            return ((refValue - varValue) * 100 / refValue).toFixed(dp) + '%';
        }
        return '-';
    }
    getPercentage2(refValue, varValue, dp) {
        if (refValue > 0 && varValue) {
            return (varValue * 100 / refValue)?.toFixed(dp) + '%';
        }
        return '-';
    }
    render() {
        var d = this.props.data;
        if(!d){
            return (<h2>No data</h2>);
        }
        var r = d.Reports[0];
        var cols = [];
        for (var i = 1; i <= d.Ship.Ship_SSF_NoOfCylinders; i++) {
            cols.push(i);
        }
        var feed_suffix = '';
        switch (d.Ship.Ship_SSF_EngineLubricator?.toUpperCase() ?? '') {
            case 'MAN_ALPHA':
            case 'MAN_ALPHAACC':
                feed_suffix = " (HMI)";
                break;
            case "MAN_CONV":
                feed_suffix = " (Stroke/LCD)";
                break;
            case "SIP":
            case "ECL":
                feed_suffix = " (Stroke)";
                break;
            case "WARTSILA_ELECTRONIC":
            case "WARTSILA_HYDRAULIC":
                feed_suffix = " (Lower/Upper)";
                break;
        }
        return (
            <div id='fullscreen_scroller'>
                <div id='outer'>
                    <div id='inner'>
                         <table cellPadding="0" cellSpacing="0" className="t centreHoriz tpad tstripe">
                             <tbody>
                                <tr key={"title"} className="r">
                                    <td className="ft" rowSpan="2">Measurement</td>
                                    <td className="ft" rowSpan="2">Fuel<br />{r.Report_Fuel_Preferred_Source}</td>
                                    <td className="ft" rowSpan="2">System<br />{r.Report_Oil_SystemPreferred_Source}</td>
                                    <td className="ft" rowSpan="2">New CLO</td>
                                    <td className="ft" colSpan={cols.length}>Used Cylinder (Drain) Oil</td>
                                </tr>
                                <tr key="unitnos" className="r">
                                    {cols.map((item) => <td className="ft">{item}</td>)}
                                </tr>
                                <tr key="feedsetting" className="r">
                                    <td className="ft">Feed Setting{feed_suffix}</td>
                                    <td className="fl"><span className="dmeas">-</span></td>
                                    <td className="fl"><span className="dmeas">-</span></td>
                                    <td className="fl"><span className="dmeas">-</span></td>
                                    {r.Report_Cylinders.map(C => <td className="fl">{C.Cylinder_SSF_FeedRate_IndividualFeedSetting?.toFixed(2)}</td>)}
                                </tr>
                                <tr key="calcium" className="r">
                                    <td className="ft">Calcium<hr /><span className="dperc">(Rough % System Oil)</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Fuel_Preferred_Calcium?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_SystemPreferred_Calcium?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_CylinderNew_Calcium?.toFixed(0)}</span></td>
                                    {r.Report_Cylinders.map(C => <td className="fl"><span className="dmeas">{C.Cylinder_Oil_Drain_Calcium_Raw?.toFixed(0)}</span><hr /><span className="dperc">{this.getPercentage(r.Report_Oil_CylinderNew_Calcium, C.Cylinder_Oil_Drain_Calcium_Raw, 0)}</span></td>)}
                                </tr>
                                <tr key="phosphorus" className="r">
                                    <td className="ft">Phosphorus<hr /><span className="dperc">(Rough % System Oil)</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Fuel_Preferred_Phosphorus?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_SystemPreferred_Phosphorus?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_CylinderNew_Phosphorus?.toFixed(0)}</span></td>
                                    {r.Report_Cylinders.map(C => <td className="fl"><span className="dmeas">{C.Cylinder_Oil_Drain_Phosphorus_Raw?.toFixed(0)}</span><hr /><span className="dperc">{this.getPercentage2(r.Report_Oil_SystemPreferred_Phosphorus, C.Cylinder_Oil_Drain_Phosphorus_Raw - r.Report_Oil_CylinderNew_Phosphorus, 0)}</span></td>)}
                                </tr>
                                <tr key="tbn" className="r trsep">
                                    <td className="ft">TBN<br /><span className="dmeas">(measured)</span><hr /><span className="dperc">(Drop From CLO)</span></td>
                                    <td className="fl"><span className="dmeas">-</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_SystemPreferred_TBN?.toFixed(1)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_CylinderNew_TBN?.toFixed(1)}</span></td>
                                        {r.Report_Cylinders.map(C => <td className="fl">{C.Cylinder_Oil_Drain_TBN?.toFixed(1)}<br /><span className="dmeas">{C.Cylinder_Oil_Drain_TBN_Raw?.toFixed(1)}</span><hr /><span className="dperc">{(r.Report_Oil_CylinderNew_TBN - C.Cylinder_Oil_Drain_TBN_Raw)?.toFixed(1)}</span></td>)}
                                </tr>
                                <tr key="Zinc" className="r">
                                        <td className="ft">Zinc<hr /><span className="dperc">(Rough % System Oil)</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Fuel_Preferred_Zinc?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_SystemPreferred_Zinc?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_CylinderNew_Zinc?.toFixed(0)}</span></td>
                                    {r.Report_Cylinders.map(C => <td className="fl"><span className="dmeas">{C.Cylinder_Oil_Drain_Zinc_Raw?.toFixed(0)}</span><hr /><span className="dperc">{this.getPercentage2(r.Report_Oil_SystemPreferred_Zinc, r.Report_Oil_CylinderNew_Zinc - C.Cylinder_Oil_Drain_Zinc_Raw, 0)}</span></td>)}
                                </tr>
                                <tr key="Nickel" className="r">
                                    <td className="ft">Nickel<hr /><span className="dperc">(Rough % Fuel Ash)</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Fuel_Preferred_Nickel?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_SystemPreferred_Nickel?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_CylinderNew_Nickel?.toFixed(0)}</span></td>
                                    {r.Report_Cylinders.map(C => <td className="fl"><span className="dmeas">{C.Cylinder_Oil_Drain_Nickel_Raw?.toFixed(0)}</span><hr /><span className="dperc">{this.getPercentage2(r.Report_Fuel_Preferred_Nickel, C.Cylinder_Oil_Drain_Nickel_Raw - r.Report_Oil_CylinderNew_Nickel, 0)}</span></td>)}
                                </tr>
                                <tr key="Vanadium" className="r trsep">
                                    <td className="ft">Vanadium<hr /><span className="dperc">(Rough % Fuel Ash)</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Fuel_Preferred_Vanadium?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_SystemPreferred_Vanadium?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_CylinderNew_Vanadium?.toFixed(0)}</span></td>
                                    {r.Report_Cylinders.map(C => <td className="fl"><span className="dmeas">{C.Cylinder_Oil_Drain_Vanadium_Raw?.toFixed(0)}</span><hr /><span className="dperc">{this.getPercentage2(r.Report_Fuel_Preferred_Vanadium, C.Cylinder_Oil_Drain_Vanadium_Raw - r.Report_Oil_CylinderNew_Vanadium, 0)}</span></td>)}
                                </tr>
                                <tr key="Viscosity" className="r">
                                    <td className="ft">Viscosity<br /><span className="dmeas">(measured)</span><hr /><span className="dperc">(% Increase from CLO)</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Fuel_Preferred_Viscosity?.toFixed(2)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_SystemPreferred_Viscosity?.toFixed(2)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_CylinderNew_Viscosity?.toFixed(2)}</span></td>
                                    {r.Report_Cylinders.map(C => <td className="fl"><span className="tl">{C.Cylinder_Oil_Drain_Viscosity?.toFixed(2)}</span><br /><span className="dmeas">{C.Cylinder_Oil_Drain_Viscosity_Raw?.toFixed(2)}</span><hr /><span className="dperc">{this.getPercentage2(r.Report_Oil_CylinderNew_Viscosity, C.Cylinder_Oil_Drain_Viscosity_Raw - r.Report_Oil_CylinderNew_Viscosity, 0)}</span></td>)}
                                </tr>
                                <tr key="Magnesium" className="r trsep">
                                    <td className="ft">Magnesium<br /><span className="dmeas">(measured)</span><hr /><span className="dperc">(% Increase from CLO)</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Fuel_Preferred_Magnesium?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_SystemPreferred_Magnesium?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_CylinderNew_Magnesium?.toFixed(0)}</span></td>
                                    {r.Report_Cylinders.map(C => <td className="fl"><span className="tl">{C.Cylinder_Oil_Drain_Magnesium?.toFixed(0)}</span><br /><span className="dmeas">{C.Cylinder_Oil_Drain_Magnesium_Raw?.toFixed(0)}</span><hr /><span className="dperc">{this.getPercentage2(r.Report_Oil_CylinderNew_Magnesium, C.Cylinder_Oil_Drain_Magnesium_Raw - r.Report_Oil_CylinderNew_Magnesium, 0)}</span></td>)}
                                </tr>
                                <tr key="Water" className="r">
                                    <td className="ft">Water<br /><span className="dmeas">(measured)</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Fuel_Preferred_Water?.toFixed(2)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_SystemPreferred_Water?.toFixed(2)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_CylinderNew_Water?.toFixed(2)}</span></td>
                                    {r.Report_Cylinders.map(C => <td className="fl">{C.Cylinder_Oil_Drain_Water?.toFixed(2)}<br/><span className="dmeas">{C.Cylinder_Oil_Drain_Water_Raw?.toFixed(2)}</span></td>)}
                                </tr>
                                <tr key="Aluminium" className="r">
                                    <td className="ft">Aluminium<br /><span className="dmeas">(measured)</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Fuel_Preferred_Aluminium?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_SystemPreferred_Aluminium?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_CylinderNew_Aluminium?.toFixed(0)}</span></td>
                                    {r.Report_Cylinders.map(C => <td className="fl">{C.Cylinder_Oil_Drain_Aluminium?.toFixed(0)}<br/><span className="dmeas">{C.Cylinder_Oil_Drain_Aluminium_Raw?.toFixed(0)}</span></td>)}
                                </tr>
                                <tr key="Barium" className="r">
                                    <td className="ft">Barium<br /><span className="dmeas">(measured)</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Fuel_Preferred_Barium?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_SystemPreferred_Barium?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_CylinderNew_Barium?.toFixed(0)}</span></td>
                                    {r.Report_Cylinders.map(C => <td className="fl">{C.Cylinder_Oil_Drain_Barium?.toFixed(0)}<br/><span className="dmeas">{C.Cylinder_Oil_Drain_Barium_Raw?.toFixed(0)}</span></td>)}
                                </tr>
                                <tr key="Boron" className="r">
                                    <td className="ft">Boron<br /><span className="dmeas">(measured)</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Fuel_Preferred_Boron?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_SystemPreferred_Boron?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_CylinderNew_Boron?.toFixed(0)}</span></td>
                                    {r.Report_Cylinders.map(C => <td className="fl">{C.Cylinder_Oil_Drain_Boron?.toFixed(0)}<br/><span className="dmeas">{C.Cylinder_Oil_Drain_Boron_Raw?.toFixed(0)}</span></td>)}
                                </tr>
                                <tr key="Chromium" className="r">
                                    <td className="ft">Chromium<br /><span className="dmeas">(measured)</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Fuel_Preferred_Chromium?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_SystemPreferred_Chromium?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_CylinderNew_Chromium?.toFixed(0)}</span></td>
                                    {r.Report_Cylinders.map(C => <td className="fl">{C.Cylinder_Oil_Drain_Chromium?.toFixed(0)}<br/><span className="dmeas">{C.Cylinder_Oil_Drain_Chromium_Raw?.toFixed(0)}</span></td>)}
                                </tr>
                                <tr key="Copper" className="r">
                                    <td className="ft">Copper<br /><span className="dmeas">(measured)</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Fuel_Preferred_Copper?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_SystemPreferred_Copper?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_CylinderNew_Copper?.toFixed(0)}</span></td>
                                    {r.Report_Cylinders.map(C => <td className="fl">{C.Cylinder_Oil_Drain_Copper?.toFixed(0)}<br/><span className="dmeas">{C.Cylinder_Oil_Drain_Copper_Raw?.toFixed(0)}</span></td>)}
                                </tr>
                                <tr key="Dispersancy" className="r">
                                    <td className="ft">Dispersancy<br /><span className="dmeas">(measured)</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Fuel_Preferred_Dispersancy?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_SystemPreferred_Dispersancy?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_CylinderNew_Dispersancy?.toFixed(0)}</span></td>
                                    {r.Report_Cylinders.map(C => <td className="fl"><span className="dmeas">{C.Cylinder_Oil_Drain_Dispersancy_Raw?.toFixed(0)}</span></td>)}
                                </tr>
                                <tr key="Iron" className="r">
                                    <td className="ft">Iron<br /><span className="dmeas">(measured)</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Fuel_Preferred_Iron?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_SystemPreferred_Iron?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_CylinderNew_Iron?.toFixed(0)}</span></td>
                                    {r.Report_Cylinders.map(C => <td className="fl">{C.Cylinder_Oil_Drain_Iron?.toFixed(0)}<br/><span className="dmeas">{C.Cylinder_Oil_Drain_Iron_Raw?.toFixed(0)}</span></td>)}
                                </tr>
                                <tr key="Iron_Magnetic" className="r">
                                    <td className="ft">Iron(Magnetic)<br /><span className="dmeas">(measured)</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Fuel_Preferred_Iron_Magnetic?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_SystemPreferred_Iron_Magnetic?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_CylinderNew_Iron_Magnetic?.toFixed(0)}</span></td>
                                    {r.Report_Cylinders.map(C => <td className="fl">{C.Cylinder_Oil_Drain_Iron_Magnetic?.toFixed(0)}<br/><span className="dmeas">{C.Cylinder_Oil_Drain_Iron_Magnetic_Raw?.toFixed(0)}</span></td>)}
                                </tr>
                                <tr key="Lead" className="r">
                                    <td className="ft">Lead<br /><span className="dmeas">(measured)</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Fuel_Preferred_Lead?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_SystemPreferred_Lead?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_CylinderNew_Lead?.toFixed(0)}</span></td>
                                    {r.Report_Cylinders.map(C => <td className="fl">{C.Cylinder_Oil_Drain_Lead?.toFixed(0)}<br/><span className="dmeas">{C.Cylinder_Oil_Drain_Lead_Raw?.toFixed(0)}</span></td>)}
                                </tr>
                                <tr key="Molybdenum" className="r">
                                    <td className="ft">Molybdenum<br /><span className="dmeas">(measured)</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Fuel_Preferred_Molybdenum?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_SystemPreferred_Molybdenum?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_CylinderNew_Molybdenum?.toFixed(0)}</span></td>
                                    {r.Report_Cylinders.map(C => <td className="fl">{C.Cylinder_Oil_Drain_Molybdenum?.toFixed(0)}<br/><span className="dmeas">{C.Cylinder_Oil_Drain_Molybdenum_Raw?.toFixed(0)}</span></td>)}
                                </tr>
                                <tr key="Silicon" className="r">
                                    <td className="ft">Silicon<br /><span className="dmeas">(measured)</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Fuel_Preferred_Silicon?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_SystemPreferred_Silicon?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_CylinderNew_Silicon?.toFixed(0)}</span></td>
                                    {r.Report_Cylinders.map(C => <td className="fl">{C.Cylinder_Oil_Drain_Silicon?.toFixed(0)}<br/><span className="dmeas">{C.Cylinder_Oil_Drain_Silicon_Raw?.toFixed(0)}</span></td>)}
                                </tr>
                                <tr key="Sodium" className="r">
                                    <td className="ft">Sodium<br /><span className="dmeas">(measured)</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Fuel_Preferred_Sodium?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_SystemPreferred_Sodium?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_CylinderNew_Sodium?.toFixed(0)}</span></td>
                                    {r.Report_Cylinders.map(C => <td className="fl">{C.Cylinder_Oil_Drain_Sodium?.toFixed(0)}<br/><span className="dmeas">{C.Cylinder_Oil_Drain_Sodium_Raw?.toFixed(0)}</span></td>)}
                                </tr>
                                <tr key="SootyInsolubles" className="r">
                                    <td className="ft">SootyInsolubles<br /><span className="dmeas">(measured)</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Fuel_Preferred_SootyInsolubles?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_SystemPreferred_SootyInsolubles?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_CylinderNew_SootyInsolubles?.toFixed(0)}</span></td>
                                    {r.Report_Cylinders.map(C => <td className="fl">{C.Cylinder_Oil_Drain_SootyInsolubles?.toFixed(0)}<br/><span className="dmeas">{C.Cylinder_Oil_Drain_SootyInsolubles_Raw?.toFixed(0)}</span></td>)}
                                </tr>
                                <tr key="Tin" className="r">
                                    <td className="ft">Tin<br /><span className="dmeas">(measured)</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Fuel_Preferred_Tin?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_SystemPreferred_Tin?.toFixed(0)}</span></td>
                                    <td className="fl"><span className="dmeas">{r.Report_Oil_CylinderNew_Tin?.toFixed(0)}</span></td>
                                    {r.Report_Cylinders.map(C => <td className="fl">{C.Cylinder_Oil_Drain_Tin?.toFixed(0)}<br/><span className="dmeas">{C.Cylinder_Oil_Drain_Tin_Raw?.toFixed(0)}</span></td>)}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default FobasEngineDilution;
