import React, { Component } from "react";
import Authorisation from './../components/Authorisation'

class InDetail extends Component {
    componentDidMount() { Authorisation.GetMain()?.onPathChanged(window.location.pathname); }
    render() {
        return (
            <div 
            initial={{opacity: 0.1}}
            animate={{opacity: 1}}
            exit={{opacity: 0.1 }}>
                <div className="summary-short">
                    Our report gives you a <strong>graphical and detailed understanding</strong> of
                    what is happening in the engines of your fleet; if anything is wrong we <strong>tell
                        you what to do about it</strong>. We also provide summarised reports for <strong>Environment,
                            Fuel, Performance, Drain Oil</strong>.
                </div>
                <div className="column-left topoffset">
                    <div className="primary-text">
                        <p>
                            The Flame Diagnostic report is a comprehensive insight into the functioning of your
                            engine. It covers analysis of the combustion process, cylinder oil feed rates, fuel
                            bunkered as well as identification of potential issues such as piston and liner
                            wear, incomplete combustion, piston misalignment, reliability of the assessments
                            as well as the Fuel System and crankcase System Oil. <a href="/Downloads">A sample
                                report is also available from the downloads page.</a>
                        </p>
                        <p>
                            Below are a series of example extracts of sections from reports to give a flavour
                            of the detail that you might expect to see.
                        </p>
                    </div>
                    <h2>
                        Extracts of Sections from a Typical Report</h2>
                    <h3>
                        Findings and Recommendations</h3>
                    <div className="primary-text">
                        The Findings and Recommendations section is a summary of all the observations made
                        in a report together with a series of recommendations for actions to be taken with
                        the engine. For example, it is here that you will find any recommendation to reduce
                        the cylinder oil feed rate and the reason why it can be reduced safely. Also in
                        here will be recommendations on the engine settings, combustion characteristics
                        and other vital information.
                    </div>
                    <h3>
                        Cylinder Lubricator Settings and Feed Rate</h3>
                    <div className="primary-text">
                        <div className="padding clear">
                            <img src="/images/BasicServiceFeedRate.gif" className="shadow-border" alt="Feed Rate" />
                        </div>
                        Above are typical graphs used in the analysis of the Cylinder Lubricator Settings
                        and Feed Rate. These are used to substantiate current lubricator settings and to
                        calculate the feed rate and to ensure that the lubricators are set optimally.
                    </div>
                    <h3>
                        Fuel Oil</h3>
                    <div className="primary-text">
                        <div className="padding clear">
                            <img src="/images/BasicServiceFuelTemp.gif" className="shadow-border" alt="Fuel Temperature" />
                        </div>
                        <div className="bold small-text clear">
                            <ul>
                                <li>Fuel Oil Temperature at 121°C before Main Engine is within recommended range for
                                    current 189cst fuel oil in use. </li>
                                <li>We suggest that fuel temperature at inlet to the fuel pump be maintained at higher
                                    end of the range recommended in DnVPS bunker analysis report, to allow for heat
                                    loss between measuring point and inlet to main engine injection pumps.</li>
                            </ul>
                        </div>
                    </div>
                    <h3>
                        Ambient Condition</h3>
                    <div className="primary-text">
                        <div className="bold small-text clear">
                            <ul>
                                <li>Variations in atmospheric humidity and temperature influence the amount of Water
                                    entering the turbo-chargers with the mass of air required for given engine load.</li>
                                <li>Current high scavenge air temperature after air coolers of 52°C will reduce the
                                    efficiency of water condensation. Maintenance below 40°C after cooler should allow
                                    satisfactory condensation at the air-cooler.</li>
                                <li>Eventual amount of water entrained in the scavenge air will depend on efficiency
                                    of the water separators and drainage arrangements.</li>
                                <li>The high scavenge air temperature at 52°C in the air receiver may have an adverse
                                    effect on quality of combustion.</li>
                                <li>We suggest that scavenge air temperature in the air receiver is maintained close
                                    to 40°C as recommended by engine makers.</li>
                            </ul>
                        </div>
                        In the Ambient Condition section, we are reporting on the effect of humidity, temperature
                        and other environmental conditions on the operation of the engine. The extract above
                        is noting that the scavenge air temperature is too high.
                    </div>
                    <h3>
                        Performance and Combustion</h3>
                    <div className="primary-text">
                        <div className="padding clear">
                            <img src="/images/BasicServicePerformanceEvaluation.gif" className="shadow-border" alt="Performance Evaluation" />
                        </div>
                        An example of a more complex analysis, the Performance Evaluation graph looks at
                        a number of performance characteristics and compares them over a number of reports.
                        These figures are compared with a number of other parameters and empirical data
                        collected for this type of engine to assess the performance of the engine and compile
                        the Performance and Combustion results for the Findings and Recommendations Section.
                    </div>
                    <h3>
                        Cylinder Drain Oil Analysis Trends</h3>
                    <div className="primary-text">
                        <div className="padding clear">
                            <img src="/images/BasicServiceTBN.gif" className="shadow-border" alt="Performance Evaluation" />
                        </div>
                        <div className="small-text bold clear">
                            <ul>
                                <li>The same acid condensation as 14 Dec 06 is expected with current sulphur and reported
                                    engine load.</li>
                                <li>Higher TBN of the CDO than 14th Dec 2006 is expected with current acid condensation
                                    and increased lubricant consumption.</li>
                                <li>Variation in TBN between Units is due to dilution by fuel and system oil contamination.</li>
                                <li>Units 5 and 7 have more contamination than other Units.</li>
                                <li>Unit 4 has less contamination than other Units.</li>
                                <li>Alkaline reserve appears to be adequate for current fuel and engine load when account
                                    is taken of greater contamination of Unit 5 by Fuel and System Oil.</li>
                            </ul>
                        </div>
                        <p>
                            CDO Trends look in detail at the anticipated results for CDO elements, in this case
                            TBN, and are used to predict what should happen. The reality of the situation is
                            compared to the projected results and historical results for the engine. These can
                            then be used to indicate engine malfunctions and potential issues such as wear,
                            poor combustion and over-lubrication.
                        </p>
                        <div>
                            By adding in Fuel Oil analysis from two points in the fuel flow to the engine and
                            System Oil analysis, the Flame Diagnostic report offers 'end to end' reporting identifying
                            several key areas:
                            <ul>
                                <li>Excess contamination of the bunker; including sea water ingress</li>
                                <li>Excess cat-fines in bunker</li>
                                <li>Inefficient purification</li>
                                <li>Contamination in the purifier and/or settling tanks</li>
                                <li>Fuel pump pressure loss</li>
                                <li>Fuel trend analysis</li>
                                <li>Fuel Temperature monitoring</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="column-right topoffset">
                    <img src="/images/details-page.gif" alt="The Flame Marine Diagnostic Report" width="250"
                        height="261" />
                    <div className="caption small-text bold small-topoffset">
                        Flame Diagnostic reports give you a graphical interpretation of what is happening
                        in your engine and actions for rectifying any problems, which are all supported
                        by explanations of the measurements.
                    </div>
                </div>
            </div>
        );
    }
}

export default InDetail;