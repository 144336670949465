import React, { Component } from "react";
import Authorisation from "../components/Authorisation";

class Company extends Component {
    componentDidMount() { Authorisation.GetMain()?.onPathChanged(window.location.pathname); }

    render() {
        return (
            <div>
                <div className="column-left topoffset">
                    <h2>Company Profile</h2>
                    <div className="primary-text">
                        <p>
                            <strong>
                                Flame Marine is a world leading independant provider of Engine Diagnostic
                                services. Our products use a sophisticated analysis of Engine Performance, Cylinder
                                Drain Oil analysis and other factors to let our customers know what is going on
                                inside their slow speed marine diesel engines without a physical inspection. Used
                                by nearly 500 ships world wide, we are based in the Europe, Asia Pacific and Australasia.
                            </strong>
                        </p>
                        <p>
                            Flame personnel are qualified sea-going marine engineers with many years experience
                            of diesel engine operation and maintenance, together with specific experience of
                            diesel engine combustion and lubrication.
                        </p>
                        <p>
                            Our recent history goes back to 1971 when Flame was first established as a shipping
                            company. In 1974 it began investigating combustion and lubrication conditions in
                            marine diesel engines. Its first investigation using cylinder lubricant drain analysis
                            was carried out in 1975 on a Sulzer 5RND68 2-stroke crosshead engine. The results
                            were considered very interesting but the technology appeared to have little practical
                            application until the surge in lubricant feed rates applied to the new generation
                            of long stroke engines.
                        </p>
                        <p>
                            Cylinder lubricant drain analysis has been used by marine lubricant manufacturers
                            since the 1960's to evaluate the performance of new formulations of cylinder lubricants.
                            After stringent laboratory engine testing a new lubricant would be subjected to
                            "field" testing and applied to a pair of cylinders to allow comparison in performance
                            of the new lubricant against an established product. The "field test" would run
                            for about 6 months on board ship and during that period the drain from each cylinder
                            would be analysed in a laboratory and the results from each group of cylinders compared.
                            During its investigation of combustion conditions in the mid 1990's on the long
                            stroke marine engines Flame began again to use cylinder lubricant drain analysis,
                            but, instead of a brief test over 3,000 hours, continued the sampling and analyses
                            for longer periods of 7,000 hours and more. The initial investigations established
                            that many engines were in fact being "over-lubricated" and that the excess lubricant
                            was being burned, in some cases causing heavy piston deposits and even ring seizure.
                        </p>
                        <p>
                            Intensifying the investigations in 1998 Flame extended sampling to all cylinders
                            and it was seen that regular sampling and analysis opened up a new possibilities
                            for monitoring the performance of long stroke cross-head marine diesel engines and
                            reducing operating and maintenance costs.
                        </p>
                    </div>
                </div>
                <div className="column-right">
                    <div className="topoffset">
                        <dl>
                            <dt>European Office</dt>
                            <dd>
                                Freshford House
                                <br />Redcliffe Way
                                <br />Bristol
                                <br />BS1 6NL
                                <br />UK
                            </dd>
                            <dd>
                                <a href="mailto:info@flamemarine.com">info@flamemarine.com</a>
                            </dd>
                        </dl>
                    </div>
                    <div className="topoffset">
                        <dl>
                            <dt>Australasian Office</dt>
                            <dd>
                                <a href="mailto:info@flamemarine.com">info@flamemarine.com</a>
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
        );
    }
}

export default Company;