export class GenerateFuelMap {
    constructor(obj) { this.o = obj.Reports[0]; }
    get D28() { return this.o.Report_Fuel_BeforeEngine_Magnesium; };
    //get D5() { return this.o.Report_Fuel_BeforeEngine_ISOGrade; };
    get C27() { return this.o.Report_Fuel_AfterPurifier_Calcium; };
    get D9() { return this.o.Report_Fuel_BeforeEngine_DateReceived; };
    get B20() { return this.o.Report_Fuel_BeforePurifier_Ash; };
    get B22() { return this.o.Report_Fuel_BeforePurifier_Sodium; };
    get B30() { return this.o.Report_Fuel_BeforePurifier_Zinc; };
    get C16() { return this.o.Report_Fuel_AfterPurifier_MCR; };
    get D13() { return this.o.Report_Fuel_BeforeEngine_Density15C; };
    get D22() { return this.o.Report_Fuel_BeforeEngine_Sodium; };
    get B24() { return this.o.Report_Fuel_BeforePurifier_Silicon; };
    get B23() { return this.o.Report_Fuel_BeforePurifier_Aluminium; };
    get C31() { return this.o.Report_Fuel_AfterPurifier_Phosphorus; };
    get C23() { return this.o.Report_Fuel_AfterPurifier_Aluminium; };
    get B18() { return this.o.Report_Fuel_BeforePurifier_TotalSedimentPotential; };
    get B10() { return this.o.Report_Fuel_BeforePurifier_DateTested; };
    get D27() { return this.o.Report_Fuel_BeforeEngine_Calcium; };
    get C26() { return this.o.Report_Fuel_AfterPurifier_Nickel; };
    get D10() { return this.o.Report_Fuel_BeforeEngine_DateTested; };
    get B13() { return this.o.Report_Fuel_BeforePurifier_Density15C; };
    get B31() { return this.o.Report_Fuel_BeforePurifier_Phosphorus; };
    //get C() { return this.o.Report_Fuel_AfterPurifier_ISOGrade; };
    get D25() { return this.o.Report_Fuel_BeforeEngine_Iron; };
    get B4() { return this.o.Report_Fuel_BeforePurifier_AnalysisLabNo; };
    get D31() { return this.o.Report_Fuel_BeforeEngine_Phosphorus; };
    get B9() { return this.o.Report_Fuel_BeforePurifier_DateReceived; };
    get C10() { return this.o.Report_Fuel_AfterPurifier_DateTested; };
    get B17() { return this.o.Report_Fuel_BeforePurifier_Sulphur; };
    get C13() { return this.o.Report_Fuel_AfterPurifier_Density15C; };
    get B25() { return this.o.Report_Fuel_BeforePurifier_Iron; };
    get D7() { return this.o.Report_Fuel_BeforeEngine_DateSampledLanded; };
    get D18() { return this.o.Report_Fuel_BeforeEngine_TotalSedimentPotential; };
    get C7() { return this.o.Report_Fuel_AfterPurifier_DateSampledLanded; };
    get D6() { return this.o.Report_Fuel_BeforeEngine_SampledDate; };
    get D29() { return this.o.Report_Fuel_BeforeEngine_Lead; };
    get C18() { return this.o.Report_Fuel_AfterPurifier_TotalSedimentPotential; };
    get D8() { return this.o.Report_Fuel_BeforeEngine_PlaceLanded; };
    get C29() { return this.o.Report_Fuel_AfterPurifier_Lead; };
    get D24() { return this.o.Report_Fuel_BeforeEngine_Silicon; };
    get D26() { return this.o.Report_Fuel_BeforeEngine_Nickel; };
    get D21() { return this.o.Report_Fuel_BeforeEngine_Vanadium; };
    get D20() { return this.o.Report_Fuel_BeforeEngine_Ash; };
    get C22() { return this.o.Report_Fuel_AfterPurifier_Sodium; };
    get C14() { return this.o.Report_Fuel_AfterPurifier_Viscosity50C; };
    get B26() { return this.o.Report_Fuel_BeforePurifier_Nickel; };
    get D16() { return this.o.Report_Fuel_BeforeEngine_MCR; };
    get C15() { return this.o.Report_Fuel_AfterPurifier_Water; };
    get C20() { return this.o.Report_Fuel_AfterPurifier_Ash; };
    get C9() { return this.o.Report_Fuel_AfterPurifier_DateReceived; };
    get C6() { return this.o.Report_Fuel_AfterPurifier_SampledDate; };
    //get B() { return this.o.Report_Fuel_BeforePurifier_ISOGrade; };
    get B7() { return this.o.Report_Fuel_BeforePurifier_DateSampledLanded; };
    get B14() { return this.o.Report_Fuel_BeforePurifier_Viscosity50C; };
    get B27() { return this.o.Report_Fuel_BeforePurifier_Calcium; };
    get B15() { return this.o.Report_Fuel_BeforePurifier_Water; };
    get D17() { return this.o.Report_Fuel_BeforeEngine_Sulphur; };
    get D4() { return this.o.Report_Fuel_BeforeEngine_AnalysisLabNo; };
    get C28() { return this.o.Report_Fuel_AfterPurifier_Magnesium; };
    get D30() { return this.o.Report_Fuel_BeforeEngine_Zinc; };
    get C8() { return this.o.Report_Fuel_AfterPurifier_PlaceLanded; };
    get D14() { return this.o.Report_Fuel_BeforeEngine_Viscosity50C; };
    get B8() { return this.o.Report_Fuel_BeforePurifier_PlaceLanded; };
    get B6() { return this.o.Report_Fuel_BeforePurifier_SampledDate; };
    get C24() { return this.o.Report_Fuel_AfterPurifier_Silicon; };
    get C4() { return this.o.Report_Fuel_AfterPurifier_AnalysisLabNo; };
    get D15() { return this.o.Report_Fuel_BeforeEngine_Water; };
    get C17() { return this.o.Report_Fuel_AfterPurifier_Sulphur; };
    get C30() { return this.o.Report_Fuel_AfterPurifier_Zinc; };
    get D23() { return this.o.Report_Fuel_BeforeEngine_Aluminium; };
    get B28() { return this.o.Report_Fuel_BeforePurifier_Magnesium; };
    get B16() { return this.o.Report_Fuel_BeforePurifier_MCR; };
    get B29() { return this.o.Report_Fuel_BeforePurifier_Lead; };
    get C21() { return this.o.Report_Fuel_AfterPurifier_Vanadium; };
    get B21() { return this.o.Report_Fuel_BeforePurifier_Vanadium; };
    get C25() { return this.o.Report_Fuel_AfterPurifier_Iron; };
    get B33() { return this.o.Report_Fuel_BeforePurifier_Chromium; };
    get B34() { return this.o.Report_Fuel_BeforePurifier_Copper; };
    get B35() { return this.o.Report_Fuel_BeforePurifier_Tin; };
    get D33() { return this.o.Report_Fuel_BeforeEngine_Chromium; };
    get D35() { return this.o.Report_Fuel_BeforeEngine_Copper; };
    get D36() { return this.o.Report_Fuel_BeforeEngine_Tin; };
    get D34() { return this.o.Report_Fuel_BeforeEngine_Molybdenum; };
    get B36() { return this.o.Report_Fuel_BeforePurifier_Molybdenum; };
    get C36() { return this.o.Report_Fuel_AfterPurifier_Molybdenum; };
    get C35() { return this.o.Report_Fuel_AfterPurifier_Tin; };
    get C34() { return this.o.Report_Fuel_AfterPurifier_Copper; };
    get C33() { return this.o.Report_Fuel_AfterPurifier_Chromium; };

    get E4() { return this.o.Report_Fuel_AfterNo1Purifier_AnalysisLabNo; };
    get E6() { return this.o.Report_Fuel_AfterNo1Purifier_SampledDate; };
    get E7() { return this.o.Report_Fuel_AfterNo1Purifier_DateSampledLanded; };
    get E8() { return this.o.Report_Fuel_AfterNo1Purifier_PlaceLanded; };
    get E9() { return this.o.Report_Fuel_AfterNo1Purifier_DateReceived; };
    get E10() { return this.o.Report_Fuel_AfterNo1Purifier_DateTested; };
    //get E5() { return this.o.Report_Fuel_AfterNo1Purifier_ISOGrade; };
    //get E14() { return this.o.Report_Fuel_AfterNo1Purifier_Density15C; };
    get E14() { return this.o.Report_Fuel_AfterNo1Purifier_Viscosity50C; };
    get E15() { return this.o.Report_Fuel_AfterNo1Purifier_Water; };
    get E16() { return this.o.Report_Fuel_AfterNo1Purifier_MCR; };
    get E17() { return this.o.Report_Fuel_AfterNo1Purifier_Sulphur; };
    get E18() { return this.o.Report_Fuel_AfterNo1Purifier_TotalSedimentPotential; };
    //get E21() { return this.o.Report_Fuel_AfterNo1Purifier_Ash; };
    get E21() { return this.o.Report_Fuel_AfterNo1Purifier_Vanadium; };
    get E22() { return this.o.Report_Fuel_AfterNo1Purifier_Sodium; };
    get E23() { return this.o.Report_Fuel_AfterNo1Purifier_Aluminium; };
    get E24() { return this.o.Report_Fuel_AfterNo1Purifier_Silicon; };
    get E25() { return this.o.Report_Fuel_AfterNo1Purifier_Iron; };
    get E26() { return this.o.Report_Fuel_AfterNo1Purifier_Nickel; };
    get E27() { return this.o.Report_Fuel_AfterNo1Purifier_Calcium; };
    //get E28() { return this.o.Report_Fuel_AfterNo1Purifier_Magnesium; };
    //get E30() { return this.o.Report_Fuel_AfterNo1Purifier_Lead; };
    get E30() { return this.o.Report_Fuel_AfterNo1Purifier_Zinc; };
    get E31() { return this.o.Report_Fuel_AfterNo1Purifier_Phosphorus; };
    get E33() { return this.o.Report_Fuel_AfterNo1Purifier_Chromium; };
    get E34() { return this.o.Report_Fuel_AfterNo1Purifier_Copper; };
    get E35() { return this.o.Report_Fuel_AfterNo1Purifier_Tin; };
    get E36() { return this.o.Report_Fuel_AfterNo1Purifier_Molybdenum; };
}
