import React, { Component } from "react";
import Authorisation from './../components/Authorisation'

class Lloyds extends Component {
    componentDidMount() { Authorisation.GetMain()?.onPathChanged(window.location.pathname); }
    render() {
        return (
            <div 
            initial={{opacity: 0.1}}
            animate={{opacity: 1}}
            exit={{opacity: 0.1 }}>
                <div className="column-left topoffset">
                    <h2 className="lrTitle">Lloyd's Register and Flame Marine</h2>
                    <div className="primary-text">
                        <p>
                            For many years, <strong>Flame Marine</strong> and <strong>Lloyd's
                                Register</strong> have worked together to deliver <strong>FOBAS Engine</strong> and <strong>FEAP</strong> which
                            were developed from <strong>Flame Marine</strong>'s <strong>Flame Diagnostic</strong> service. This
                            has been an exciting development for the shipping industry with one of the most respected voices
                            in the community using service based upon <strong>Flame Marine</strong> technology.
                        </p>
                        <p>
                            For <strong>Flame Marine</strong>, this partnership has provided many benefits both directly
                            and for its existing customers. From the viewpoint of <strong>Flame Marine</strong>, <strong>Lloyd's
                                Register</strong> offers access to experts in many fields, particularly
                            in the area of fuel and fuel system diagnosis. Many of these experts also sit on
                            or advise standards bodies on the future direction of the marine industry. Their
                            detailed, and well respected, knowledge and overview bring further capability
                            to <strong>Flame Diagnostic</strong> and ratify the credibility of the system.
                        </p>
                        <p>
                            For our customers, this brings the reassurance that one of the major classification
                            societies is using the same technology that our customers use.  Additionally, <strong>Flame Marine</strong> can
                            now draw upon a larger knowledge base to help solve technical issues and, if necessary,
                            recommend or arrange <strong>Lloyd's Register</strong> experts to further investigate
                            any issue that our customers may be having.
                        </p>
                        <p>
                            One question that we have been asked is
                            <br />
                            <strong>'What is the difference between Flame Diagnostic and FOBAS Engine'?</strong>
                        </p>
                        <p>
                            <strong>FOBAS Engine</strong> uses the <strong>Flame Diagnostic</strong> software
                            system to:
                        </p>
                        <ul>
                            <li>Generate simplified reports for chief engineers</li>
                            <li>
                                Provide an 8 point analysis with summary overview
                                <ul>
                                    <li>Fuel oil quality</li>
                                    <li>Scavenge Air Quality</li>
                                    <li>Combustion Condition</li>
                                    <li>Cylinder oil feed</li>
                                    <li>Cylinder drain oil condition</li>
                                    <li>Wear Conditions</li>
                                    <li>System oil condition</li>
                                    <li>Engine performance information</li>
                                </ul>
                            </li>
                            <li>Comment more fully on the fuel system</li>
                            <li>Verify and validate the conclusions drawn</li>
                            <li>
                                Provide a platform to develop and launch further <strong>FOBAS Engine</strong> focussed
                                services
                            </li>
                        </ul>
                        <ul className="lrTitle">
                            <li>
                                <a
                                    href="https://www.lr.org/en/fuel-testing/performance-management/"
                                    target="_blank" rel="noopener noreferrer"
                                >
                                    Visit the <strong>FOBAS Engine</strong> website
                                </a>.
                            </li>
                             <li>
                                <a href="https://www.lr.org/en/" target="_blank" rel="noopener noreferrer">Visit the Lloyd's group home page</a>.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="column-right topoffset lr_logo">
                    <img
                        className="LloydsLogo"
                        src="/images/LR_Green_strip.png"
                        alt="Lloyd's Register logo"
                    />
                </div>
            </div>
        );
    }
}

export default Lloyds;