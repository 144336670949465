import React, { Component } from "react";

class FobasEngineSSF2 extends Component {
    render() {
        var s = this.props.data.Ship;
        var r = this.props.data.Reports[0];
        let acc = '';
        let accunits = '';
        let feed = ['', '', '', '', '', '', '', '', '', '', '', ''];
        let hasunits = false;
        switch (s.Ship_SSF_EngineLubricator) {
            case 'MAN_AlphaACC':
            case 'MAN_AlphaME':
                acc = r.Report_SSF_FeedRate_MAN_Alpha_AccFactor;
                accunits = r.Report_SSF_FeedRate_MAN_Alpha_AccFactorUnits;
                hasunits = true;
                break;
            case 'SIP':
                acc = r.Report_SSF_FeedRate_SIP_Setting;
                accunits = 'g/kWh';
                break;
            case 'Wartsila_JetPulse':
                acc = r.Report_SSF_FeedRate_Wartsila_JetPulse_FeedSetting;
                accunits = r.Report_SSF_FeedRate_Wartsila_JetPulse_FeedSettingUnits;
                hasunits = true;
                break;
            case 'ECL':
                break;
        }
        for (let i = 0; i < r.Report_Cylinders.length; i++) {
            feed[i] = r.Report_Cylinders[i].Cylinder_SSF_FeedRate_IndividualFeedSetting;
        }
        let cw1 = '90px';
        let cw = '70px';
        return (
            <div id='fullscreen_scroller'>
                <div id='outer'>
                    <div id='inner'>
                        <table cellPadding="0" cellSpacing="0" className="t centreHoriz">
                            <colgroup>
                                <col style={{ width: cw1 }} />
                                <col style={{ width: cw }} />
                                <col style={{ width: cw }} />
                                <col style={{ width: cw }} />
                                <col style={{ width: cw }} />
                                <col style={{ width: cw }} />
                                <col style={{ width: cw }} />
                                <col style={{ width: cw }} />
                                <col style={{ width: cw }} />
                                <col style={{ width: cw }} />
                                <col style={{ width: cw }} />
                                <col style={{ width: cw }} />
                                <col style={{ width: cw }} />
                            </colgroup>
                            <tbody>
                                <tr className="r">
                                    <td colSpan="13" className="dt">Collection/ Landing Information</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Ship Name</td>
                                    <td colSpan="5" className="da">{s.Ship_SSF_CurrentShipName}</td>
                                    <td colSpan="3" className="dl">Date Sampled</td>
                                    <td colSpan="3" className="da">{(r.Report_SSF_Collection_SampledDate?.length > 10 ? r.Report_SSF_Collection_SampledDate.slice(0, 10) : r.Report_SSF_Collection_SampledDate)}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">IMO Number</td>
                                    <td colSpan="5" className="da">{s.Ship_SSF_ImoNumber}</td>
                                    <td colSpan="3" className="dl">Port Landed</td>
                                    <td colSpan="3" className="da">{r.Report_SSF_Collection_LandedPort}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Engine</td>
                                    <td colSpan="1" className="da">{s.Ship_SSF_EngineName}</td>
                                    <td colSpan="2" className="dl">Serial No.</td>
                                    <td colSpan="2" className="da">{s.Ship_SSF_EngineSerialNo}</td>
                                    <td colSpan="3" className="dl">Date Landed</td>
                                    <td colSpan="3" className="da">{(r.Report_SSF_Collection_LandedDate?.length > 10 ? r.Report_SSF_Collection_LandedDate.slice(0, 10) : r.Report_SSF_Collection_LandedDate)}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="13" className="dt">Engine Conditions</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Total Engine Hours</td>
                                    <td colSpan="3" className="da">{r.Report_SSF_Conditions_EngineHours}</td>
                                    <td colSpan="1" className="dl">% Load</td>
                                    <td colSpan="2" className="da">{r.Report_SSF_Power_EngineLoad}</td>
                                    <td colSpan="2" className="dl">Engine Speed</td>
                                    <td colSpan="2" className="da">{r.Report_SSF_Conditions_EngineRPM}</td>
                                    <td className="du">RPM</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="13" className="dt">Consumption</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="6" className="ds">Cylinder Lube Oil (CLO)</td>
                                    <td colSpan="7" className="ds">Fuel Oil</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="3" className="dl">CLO Name</td>
                                    <td colSpan="3" className="da">{r.Report_SSF_Consumption_CylinderOilName}</td>
                                    <td colSpan="4" className="dl">FO Consumption</td>
                                    <td colSpan="2" className="da">{r.Report_SSF_Consumption_FuelOilConsumption}</td>
                                    <td colSpan="1" className="da">{r.Report_SSF_Consumption_FuelOilConsumptionUnits}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="3" className="dl">CLO BN</td>
                                    <td colSpan="3" className="da">{r.Report_SSF_Consumption_CylinderOilBN}</td>
                                    <td colSpan="4" className="dl">FOBAS Report Ref. for Fuel in-use</td>
                                    <td colSpan="3" className="da">{r.Report_SSF_FuelBunker_AnalysisLabNo}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="3" className="dl">CLO Consumption</td>
                                    <td colSpan="2" className="da">{r.Report_SSF_Consumption_CylinderOilConsumption}</td>
                                    <td colSpan="1" className="da">{r.Report_SSF_Consumption_CylinderOilConsumptionUnits}</td>
                                    <td colSpan="4" className="dl">Bunkering Date</td>
                                    <td colSpan="3" className="da">{(r.Report_SSF_FuelBunker_BunkeredDate?.length > 10 ? r.Report_SSF_FuelBunker_BunkeredDate.slice(0, 10) : r.Report_SSF_FuelBunker_BunkeredDate)}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="3" className="dl">CLO Specific Feed Rate</td>
                                    <td colSpan="2" className="da">{r.Report_SSF_FeedRate_CylinderOilSpecificFeedRate}</td>
                                    <td colSpan="1" className="da">{r.Report_SSF_FeedRate_CylinderOilFeedRateUnits}</td>
                                    <td colSpan="4" className="dl">Sulphur Content of Fuel in-use</td>
                                    <td colSpan="2" className="da">{r.Report_SSF_FuelBunker_Sulphur}</td>
                                    <td colSpan="1" className="dl">% mass</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="6" rowSpan="2" className="ds">System Oil</td>
                                    <td colSpan="4" className="dl">Temp Before Engine</td>
                                    <td colSpan="2" className="da">{r.Report_SSF_Consumption_FuelOilBeforeEngineTemperature}</td>
                                    <td colSpan="1" className="dl">ºC</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="4" className="dl">Purifier Inlet Temp</td>
                                    <td colSpan="2" className="da">{r.Report_SSF_Consumption_FuelOilBeforePurifierTemperature}</td>
                                    <td colSpan="1" className="dl">ºC</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Lubricant Name</td>
                                    <td colSpan="4" className="da">{r.Report_SSF_Consumption_SystemOilName}</td>
                                    <td colSpan="4" className="dl">Purifier Outlet Temp</td>
                                    <td colSpan="2" className="da">{r.Report_SSF_Consumption_FuelOilAfterPurifierTemperature}</td>
                                    <td colSpan="1" className="dl">ºC</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="13" className="dt">Electronic Lubricator Setting</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="2" className="dl">Feed Rate/ACC</td>
                                    <td colSpan="2" className="da">{acc}</td>
                                    <td colSpan="1" className={(hasunits ? 'da' : 'dl')}>{accunits}</td>
                                    <td colSpan="8" className="dl"></td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="13" className="ds">Units</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="1" className="dl"></td>
                                    <td colSpan="1" className="dlc">1</td>
                                    <td colSpan="1" className="dlc">2</td>
                                    <td colSpan="1" className="dlc">3</td>
                                    <td colSpan="1" className="dlc">4</td>
                                    <td colSpan="1" className="dlc">5</td>
                                    <td colSpan="1" className="dlc">6</td>
                                    <td colSpan="1" className="dlc">7</td>
                                    <td colSpan="1" className="dlc">8</td>
                                    <td colSpan="1" className="dlc">9</td>
                                    <td colSpan="1" className="dlc">10</td>
                                    <td colSpan="1" className="dlc">11</td>
                                    <td colSpan="1" className="dlc">12</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="1" className="dl">Adj Factor</td>
                                    <td colSpan="1" className="da">{feed[0]}</td>
                                    <td colSpan="1" className="da">{feed[1]}</td>
                                    <td colSpan="1" className="da">{feed[2]}</td>
                                    <td colSpan="1" className="da">{feed[3]}</td>
                                    <td colSpan="1" className="da">{feed[4]}</td>
                                    <td colSpan="1" className="da">{feed[5]}</td>
                                    <td colSpan="1" className="da">{feed[6]}</td>
                                    <td colSpan="1" className="da">{feed[7]}</td>
                                    <td colSpan="1" className="da">{feed[8]}</td>
                                    <td colSpan="1" className="da">{feed[9]}</td>
                                    <td colSpan="1" className="da">{feed[10]}</td>
                                    <td colSpan="1" className="da">{feed[11]}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default FobasEngineSSF2;
