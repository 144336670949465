import React, { Component } from "react";
import Authorisation from './../components/Authorisation'

class FAQ extends Component {
    componentDidMount() { Authorisation.GetMain()?.onPathChanged(window.location.pathname); }
    render() {
        return (
            <div >
                <div className="summary">
                    We know you might have some questions, if they aren't answered below, then please
                    send us an <a href="/Company">email</a>.
                </div>
                <div className="summary-detail">
                    <h3>Why do we need this when oil companies say they offer the same service for free?</h3>
                    <div className="primary-text">
                        <p>
                            Our customers who have evaluated the 'free' offerings by oil companies have continued
                            to used the Flame Diagnostic service. The service they have received from the oil
                            companies is extremely limited in its depth. Indeed, it is so limited that few superintendents
                            even bother to look at the results being generated.
                        </p>
                        <p>
                            In contrast, our customers take great interest in the results supplied by Flame
                            Marine and frequently come back to us for further information or to ask us to expound
                            on a point. As an independent company, you can be sure that you will always receive
                            the best impartial advice from Flame Marine. In particular, <strong>we work very hard to minimise your costs related to Cylinder Oil Feed</strong>. This is clearly
                            not in the best interest of the oil companies.
                        </p>
                    </div>
                    <h3>As a Chief Engineer, I feel that Flame Diagnostic threatens my job.</h3>
                    <div className="primary-text">
                        Flame Diagnostic has been created by Marine Engineers for Marine Engineers. It is
                        designed to help you in what is a difficult job, by providing an in depth analysis
                        of your engine in innovative and helpful ways. Flame Diagnostic it not a threat
                        as it provides you with additional information, that you would not be able to get
                        yourself, to pinpoint the units which <strong>need</strong> overhaul and to avoid
                        wasting time doing needless unit overhaul. In this way, it helps you to keep your
                        engines running for longer and at a lower operational cost.
                    </div>
                    <h3>Flame Diagnostic does nothing that I couldn't do myself.</h3>
                    <div className="primary-text">
                        As with many complex analyses, in simple cases the answers appear obvious. However,
                        to assume that you can always apply the simple case is wrong and may cause severe
                        engine damage. Flame Diagnostic has taken 10 years of dedicated research and over
                        50 years of experience by Flame Marine engineers to figure out what is actually
                        going on inside the engine without 'lifting the pistons' and report the results
                        of that back to you. The results are so well respected in the industry that oil
                        companies and engine makers regularly use it for their own information and in support
                        of their research and development; something that if it was simple, they would undertake
                        themselves.
                    </div>
                    <h3>How do I get the most accurate results from Flame Diagnostic?</h3>
                    <div className="primary-text">
                        You need to ensure that you record your performance measurements accurately and
                        that you take all of your samples and performance measurements at the same time
                        and in the way prescribed by the Sampling Procedure. The majority of errors come
                        when the data collected is not recorded accurately, samples are taken at a different
                        time to the Performance data or are not collected in the correct way. Visit the <a href="/Downloads">downloads page</a> for more information.
                    </div>
                </div>
            </div>
        );
    }
}

export default FAQ;