import React, { Component } from "react";

class FobasEngineFiles extends Component {


    render() {

        var data = this.props.data;
        var files = data.Files;
        return (
            <div>
                <table className="table table-hover FobasListTable">
                    <thead>
                        <tr key={"head"}>
                            <th scope="col">Name</th>
                            <th scope="col">Type</th>
                            <th scope="col">Size</th>
                        </tr>
                    </thead>
                    <tbody>
                        {files.map((i) =>
                            <tr key={i.Name}>
                                <td><a href={i.Url} target="_blank" rel="noreferrer">{i.Name}</a></td>
                                <td>{i.Type}</td>
                                <td>{i.Size}</td>
                            </tr>
                        )}
                    </tbody>
                </table>

            </div>
        );
    }
}

export default FobasEngineFiles;
