import React, { Component } from "react";
import { GenerateOilMap } from '../../Support/GenerateOilMap';

class FobasEngineOil extends Component {
 
    render() {
        var r = this.props.data;
          var d = r ? new GenerateOilMap(r) : {};
        return (
            <div id='fullscreen_scroller'>
                <div id='outer'>
                    <div id='inner'>
                        <table cellPadding="0" cellSpacing="0" className="t centreHoriz">
                            <colgroup>
                                <col style={{ width: "177px" }} />
                                <col style={{ width: "68px" }} span="15" />
                            </colgroup>
                            <tbody>
                                <tr className="r">
                                    <td colSpan="16" className="fm">{d.A1}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="16" className="fs"></td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Date Samples Taken</td>
                                    <td colSpan="3" className="fl">{(d.B3?.length > 10 ? d.B3.slice(0, 10) : d.B3)}</td>
                                    <td colSpan="3" className="ft">Date on Sample Bottle</td>
                                    <td colSpan="3" className="fl">{(d.H3?.length > 10 ? d.H3.slice(0, 10) : d.H3)}</td>
                                    <td rowSpan="5" colSpan="6" className="fl"></td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Date Samples Landed</td>
                                    <td colSpan="3" className="fl">{(d.B4?.length > 10 ? d.B4.slice(0, 10) : d.B4)}</td>
                                    <td rowSpan="4" colSpan="6" className="fl"></td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Place Landed</td>
                                    <td colSpan="3" className="fl">{d.B5}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Date Samples Received</td>
                                    <td colSpan="3" className="fl">{(d.B6?.length > 10 ? d.B6.slice(0, 10) : d.B6)}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Date Test Completed</td>
                                    <td colSpan="3" className="fl">{(d.B7?.length > 10 ? d.B7.slice(0, 10) : d.B7)}</td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="16" className="fl"></td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Cylinder No:</td>
                                    <td className="ft">1</td>
                                    <td className="ft">2</td>
                                    <td className="ft">3</td>
                                    <td className="ft">4</td>
                                    <td className="ft">5</td>
                                    <td className="ft">6</td>
                                    <td className="ft">7</td>
                                    <td className="ft">8</td>
                                    <td className="ft">9</td>
                                    <td className="ft">10</td>
                                    <td className="ft">11</td>
                                    <td className="ft">12</td>
                                    <td className="ft">New CLO</td>
                                    <td className="ft">System Oil</td>
                                    <td className="ft">New System Oil</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Lab Number</td>
                                    <td className="fl">{d.B10}</td>
                                    <td className="fl">{d.C10}</td>
                                    <td className="fl">{d.D10}</td>
                                    <td className="fl">{d.E10}</td>
                                    <td className="fl">{d.F10}</td>
                                    <td className="fl">{d.G10}</td>
                                    <td className="fl">{d.H10}</td>
                                    <td className="fl">{d.I10}</td>
                                    <td className="fl">{d.J10}</td>
                                    <td className="fl">{d.K10}</td>
                                    <td className="fl">{d.L10}</td>
                                    <td className="fl">{d.M10}</td>
                                    <td className="fl">{d.N10}</td>
                                    <td className="fl">{d.O10}</td>
                                    <td className="fl">{d.P10}</td>
                                </tr>
                                <tr className="r">
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                </tr>
                                <tr className="r">
                                    <td colSpan="4" className="fs">PHYSICAL PROPERTIES:</td>
                                    <td className="fs"></td>
                                    <td className="fs"></td>
                                    <td className="fs"></td>
                                    <td className="fs"></td>
                                    <td className="fs"></td>
                                    <td className="fs"></td>
                                    <td className="fs"></td>
                                    <td className="fs"></td>
                                    <td className="fs"></td>
                                    <td className="fs"></td>
                                    <td className="fs"></td>
                                    <td className="fs"></td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Viscosity</td>
                                    <td className="fl">{d.B13}</td>
                                    <td className="fl">{d.C13}</td>
                                    <td className="fl">{d.D13}</td>
                                    <td className="fl">{d.E13}</td>
                                    <td className="fl">{d.F13}</td>
                                    <td className="fl">{d.G13}</td>
                                    <td className="fl">{d.H13}</td>
                                    <td className="fl">{d.I13}</td>
                                    <td className="fl">{d.J13}</td>
                                    <td className="fl">{d.K13}</td>
                                    <td className="fl">{d.L13}</td>
                                    <td className="fl">{d.M13}</td>
                                    <td className="fl">{d.N13}</td>
                                    <td className="fl">{d.O13}</td>
                                    <td className="fl">{d.P13}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Water</td>
                                    <td className="fl">{d.B14}</td>
                                    <td className="fl">{d.C14}</td>
                                    <td className="fl">{d.D14}</td>
                                    <td className="fl">{d.E14}</td>
                                    <td className="fl">{d.F14}</td>
                                    <td className="fl">{d.G14}</td>
                                    <td className="fl">{d.H14}</td>
                                    <td className="fl">{d.I14}</td>
                                    <td className="fl">{d.J14}</td>
                                    <td className="fl">{d.K14}</td>
                                    <td className="fl">{d.L14}</td>
                                    <td className="fl">{d.M14}</td>
                                    <td className="fl">{d.N14}</td>
                                    <td className="fl">{d.O14}</td>
                                    <td className="fl">{d.P14}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">TBN </td>
                                    <td className="fl">{d.B15}</td>
                                    <td className="fl">{d.C15}</td>
                                    <td className="fl">{d.D15}</td>
                                    <td className="fl">{d.E15}</td>
                                    <td className="fl">{d.F15}</td>
                                    <td className="fl">{d.G15}</td>
                                    <td className="fl">{d.H15}</td>
                                    <td className="fl">{d.I15}</td>
                                    <td className="fl">{d.J15}</td>
                                    <td className="fl">{d.K15}</td>
                                    <td className="fl">{d.L15}</td>
                                    <td className="fl">{d.M15}</td>
                                    <td className="fl">{d.N15}</td>
                                    <td className="fl">{d.O15}</td>
                                    <td className="fl">{d.P15}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Sooty Insol.</td>
                                    <td className="fl">{d.B16}</td>
                                    <td className="fl">{d.C16}</td>
                                    <td className="fl">{d.D16}</td>
                                    <td className="fl">{d.E16}</td>
                                    <td className="fl">{d.F16}</td>
                                    <td className="fl">{d.G16}</td>
                                    <td className="fl">{d.H16}</td>
                                    <td className="fl">{d.I16}</td>
                                    <td className="fl">{d.J16}</td>
                                    <td className="fl">{d.K16}</td>
                                    <td className="fl">{d.L16}</td>
                                    <td className="fl">{d.M16}</td>
                                    <td className="fl">{d.N16}</td>
                                    <td className="fl">{d.O16}</td>
                                    <td className="fl">{d.P16}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Dispersancy</td>
                                    <td className="fl">{d.B17}</td>
                                    <td className="fl">{d.C17}</td>
                                    <td className="fl">{d.D17}</td>
                                    <td className="fl">{d.E17}</td>
                                    <td className="fl">{d.F17}</td>
                                    <td className="fl">{d.G17}</td>
                                    <td className="fl">{d.H17}</td>
                                    <td className="fl">{d.I17}</td>
                                    <td className="fl">{d.J17}</td>
                                    <td className="fl">{d.K17}</td>
                                    <td className="fl">{d.L17}</td>
                                    <td className="fl">{d.M17}</td>
                                    <td className="fl">{d.N17}</td>
                                    <td className="fl">{d.O17}</td>
                                    <td className="fl">{d.P17}</td>
                                </tr>
                                <tr className="r">
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                    <td className="fl"></td>
                                </tr>
                                <tr className="r">
                                    <td className="fs">SPECTROCHEMICAL ANALYSIS:</td>
                                    <td className="fs"></td>
                                    <td className="fs"></td>
                                    <td className="fs"></td>
                                    <td className="fs"></td>
                                    <td className="fs"></td>
                                    <td className="fs"></td>
                                    <td className="fs"></td>
                                    <td className="fs"></td>
                                    <td className="fs"></td>
                                    <td className="fs"></td>
                                    <td className="fs"></td>
                                    <td className="fs"></td>
                                    <td className="fs"></td>
                                    <td className="fs"></td>
                                    <td className="fs"></td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Iron</td>
                                    <td className="fl">{d.B20}</td>
                                    <td className="fl">{d.C20}</td>
                                    <td className="fl">{d.D20}</td>
                                    <td className="fl">{d.E20}</td>
                                    <td className="fl">{d.F20}</td>
                                    <td className="fl">{d.G20}</td>
                                    <td className="fl">{d.H20}</td>
                                    <td className="fl">{d.I20}</td>
                                    <td className="fl">{d.J20}</td>
                                    <td className="fl">{d.K20}</td>
                                    <td className="fl">{d.L20}</td>
                                    <td className="fl">{d.M20}</td>
                                    <td className="fl">{d.N20}</td>
                                    <td className="fl">{d.O20}</td>
                                    <td className="fl">{d.P20}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Lead</td>
                                    <td className="fl">{d.B21}</td>
                                    <td className="fl">{d.C21}</td>
                                    <td className="fl">{d.D21}</td>
                                    <td className="fl">{d.E21}</td>
                                    <td className="fl">{d.F21}</td>
                                    <td className="fl">{d.G21}</td>
                                    <td className="fl">{d.H21}</td>
                                    <td className="fl">{d.I21}</td>
                                    <td className="fl">{d.J21}</td>
                                    <td className="fl">{d.K21}</td>
                                    <td className="fl">{d.L21}</td>
                                    <td className="fl">{d.M21}</td>
                                    <td className="fl">{d.N21}</td>
                                    <td className="fl">{d.O21}</td>
                                    <td className="fl">{d.P21}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Copper</td>
                                    <td className="fl">{d.B22}</td>
                                    <td className="fl">{d.C22}</td>
                                    <td className="fl">{d.D22}</td>
                                    <td className="fl">{d.E22}</td>
                                    <td className="fl">{d.F22}</td>
                                    <td className="fl">{d.G22}</td>
                                    <td className="fl">{d.H22}</td>
                                    <td className="fl">{d.I22}</td>
                                    <td className="fl">{d.J22}</td>
                                    <td className="fl">{d.K22}</td>
                                    <td className="fl">{d.L22}</td>
                                    <td className="fl">{d.M22}</td>
                                    <td className="fl">{d.N22}</td>
                                    <td className="fl">{d.O22}</td>
                                    <td className="fl">{d.P22}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Chromium</td>
                                    <td className="fl">{d.B23}</td>
                                    <td className="fl">{d.C23}</td>
                                    <td className="fl">{d.D23}</td>
                                    <td className="fl">{d.E23}</td>
                                    <td className="fl">{d.F23}</td>
                                    <td className="fl">{d.G23}</td>
                                    <td className="fl">{d.H23}</td>
                                    <td className="fl">{d.I23}</td>
                                    <td className="fl">{d.J23}</td>
                                    <td className="fl">{d.K23}</td>
                                    <td className="fl">{d.L23}</td>
                                    <td className="fl">{d.M23}</td>
                                    <td className="fl">{d.N23}</td>
                                    <td className="fl">{d.O23}</td>
                                    <td className="fl">{d.P23}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Aluminium</td>
                                    <td className="fl">{d.B24}</td>
                                    <td className="fl">{d.C24}</td>
                                    <td className="fl">{d.D24}</td>
                                    <td className="fl">{d.E24}</td>
                                    <td className="fl">{d.F24}</td>
                                    <td className="fl">{d.G24}</td>
                                    <td className="fl">{d.H24}</td>
                                    <td className="fl">{d.I24}</td>
                                    <td className="fl">{d.J24}</td>
                                    <td className="fl">{d.K24}</td>
                                    <td className="fl">{d.L24}</td>
                                    <td className="fl">{d.M24}</td>
                                    <td className="fl">{d.N24}</td>
                                    <td className="fl">{d.O24}</td>
                                    <td className="fl">{d.P24}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Nickel</td>
                                    <td className="fl">{d.B25}</td>
                                    <td className="fl">{d.C25}</td>
                                    <td className="fl">{d.D25}</td>
                                    <td className="fl">{d.E25}</td>
                                    <td className="fl">{d.F25}</td>
                                    <td className="fl">{d.G25}</td>
                                    <td className="fl">{d.H25}</td>
                                    <td className="fl">{d.I25}</td>
                                    <td className="fl">{d.J25}</td>
                                    <td className="fl">{d.K25}</td>
                                    <td className="fl">{d.L25}</td>
                                    <td className="fl">{d.M25}</td>
                                    <td className="fl">{d.N25}</td>
                                    <td className="fl">{d.O25}</td>
                                    <td className="fl">{d.P25}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Silver</td>
                                    <td className="fl">{d.B26}</td>
                                    <td className="fl">{d.C26}</td>
                                    <td className="fl">{d.D26}</td>
                                    <td className="fl">{d.E26}</td>
                                    <td className="fl">{d.F26}</td>
                                    <td className="fl">{d.G26}</td>
                                    <td className="fl">{d.H26}</td>
                                    <td className="fl">{d.I26}</td>
                                    <td className="fl">{d.J26}</td>
                                    <td className="fl">{d.K26}</td>
                                    <td className="fl">{d.L26}</td>
                                    <td className="fl">{d.M26}</td>
                                    <td className="fl">{d.N26}</td>
                                    <td className="fl">{d.O26}</td>
                                    <td className="fl">{d.P26}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Tin</td>
                                    <td className="fl">{d.B27}</td>
                                    <td className="fl">{d.C27}</td>
                                    <td className="fl">{d.D27}</td>
                                    <td className="fl">{d.E27}</td>
                                    <td className="fl">{d.F27}</td>
                                    <td className="fl">{d.G27}</td>
                                    <td className="fl">{d.H27}</td>
                                    <td className="fl">{d.I27}</td>
                                    <td className="fl">{d.J27}</td>
                                    <td className="fl">{d.K27}</td>
                                    <td className="fl">{d.L27}</td>
                                    <td className="fl">{d.M27}</td>
                                    <td className="fl">{d.N27}</td>
                                    <td className="fl">{d.O27}</td>
                                    <td className="fl">{d.P27}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Silicon</td>
                                    <td className="fl">{d.B28}</td>
                                    <td className="fl">{d.C28}</td>
                                    <td className="fl">{d.D28}</td>
                                    <td className="fl">{d.E28}</td>
                                    <td className="fl">{d.F28}</td>
                                    <td className="fl">{d.G28}</td>
                                    <td className="fl">{d.H28}</td>
                                    <td className="fl">{d.I28}</td>
                                    <td className="fl">{d.J28}</td>
                                    <td className="fl">{d.K28}</td>
                                    <td className="fl">{d.L28}</td>
                                    <td className="fl">{d.M28}</td>
                                    <td className="fl">{d.N28}</td>
                                    <td className="fl">{d.O28}</td>
                                    <td className="fl">{d.P28}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Boron</td>
                                    <td className="fl">{d.B29}</td>
                                    <td className="fl">{d.C29}</td>
                                    <td className="fl">{d.D29}</td>
                                    <td className="fl">{d.E29}</td>
                                    <td className="fl">{d.F29}</td>
                                    <td className="fl">{d.G29}</td>
                                    <td className="fl">{d.H29}</td>
                                    <td className="fl">{d.I29}</td>
                                    <td className="fl">{d.J29}</td>
                                    <td className="fl">{d.K29}</td>
                                    <td className="fl">{d.L29}</td>
                                    <td className="fl">{d.M29}</td>
                                    <td className="fl">{d.N29}</td>
                                    <td className="fl">{d.O29}</td>
                                    <td className="fl">{d.P29}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Sodium</td>
                                    <td className="fl">{d.B30}</td>
                                    <td className="fl">{d.C30}</td>
                                    <td className="fl">{d.D30}</td>
                                    <td className="fl">{d.E30}</td>
                                    <td className="fl">{d.F30}</td>
                                    <td className="fl">{d.G30}</td>
                                    <td className="fl">{d.H30}</td>
                                    <td className="fl">{d.I30}</td>
                                    <td className="fl">{d.J30}</td>
                                    <td className="fl">{d.K30}</td>
                                    <td className="fl">{d.L30}</td>
                                    <td className="fl">{d.M30}</td>
                                    <td className="fl">{d.N30}</td>
                                    <td className="fl">{d.O30}</td>
                                    <td className="fl">{d.P30}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Phosphorous</td>
                                    <td className="fl">{d.B31}</td>
                                    <td className="fl">{d.C31}</td>
                                    <td className="fl">{d.D31}</td>
                                    <td className="fl">{d.E31}</td>
                                    <td className="fl">{d.F31}</td>
                                    <td className="fl">{d.G31}</td>
                                    <td className="fl">{d.H31}</td>
                                    <td className="fl">{d.I31}</td>
                                    <td className="fl">{d.J31}</td>
                                    <td className="fl">{d.K31}</td>
                                    <td className="fl">{d.L31}</td>
                                    <td className="fl">{d.M31}</td>
                                    <td className="fl">{d.N31}</td>
                                    <td className="fl">{d.O31}</td>
                                    <td className="fl">{d.P31}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Zinc</td>
                                    <td className="fl">{d.B32}</td>
                                    <td className="fl">{d.C32}</td>
                                    <td className="fl">{d.D32}</td>
                                    <td className="fl">{d.E32}</td>
                                    <td className="fl">{d.F32}</td>
                                    <td className="fl">{d.G32}</td>
                                    <td className="fl">{d.H32}</td>
                                    <td className="fl">{d.I32}</td>
                                    <td className="fl">{d.J32}</td>
                                    <td className="fl">{d.K32}</td>
                                    <td className="fl">{d.L32}</td>
                                    <td className="fl">{d.M32}</td>
                                    <td className="fl">{d.N32}</td>
                                    <td className="fl">{d.O32}</td>
                                    <td className="fl">{d.P32}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Calcium</td>
                                    <td className="fl">{d.B33}</td>
                                    <td className="fl">{d.C33}</td>
                                    <td className="fl">{d.D33}</td>
                                    <td className="fl">{d.E33}</td>
                                    <td className="fl">{d.F33}</td>
                                    <td className="fl">{d.G33}</td>
                                    <td className="fl">{d.H33}</td>
                                    <td className="fl">{d.I33}</td>
                                    <td className="fl">{d.J33}</td>
                                    <td className="fl">{d.K33}</td>
                                    <td className="fl">{d.L33}</td>
                                    <td className="fl">{d.M33}</td>
                                    <td className="fl">{d.N33}</td>
                                    <td className="fl">{d.O33}</td>
                                    <td className="fl">{d.P33}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Barium</td>
                                    <td className="fl">{d.B34}</td>
                                    <td className="fl">{d.C34}</td>
                                    <td className="fl">{d.D34}</td>
                                    <td className="fl">{d.E34}</td>
                                    <td className="fl">{d.F34}</td>
                                    <td className="fl">{d.G34}</td>
                                    <td className="fl">{d.H34}</td>
                                    <td className="fl">{d.I34}</td>
                                    <td className="fl">{d.J34}</td>
                                    <td className="fl">{d.K34}</td>
                                    <td className="fl">{d.L34}</td>
                                    <td className="fl">{d.M34}</td>
                                    <td className="fl">{d.N34}</td>
                                    <td className="fl">{d.O34}</td>
                                    <td className="fl">{d.P34}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Magnesium</td>
                                    <td className="fl">{d.B35}</td>
                                    <td className="fl">{d.C35}</td>
                                    <td className="fl">{d.D35}</td>
                                    <td className="fl">{d.E35}</td>
                                    <td className="fl">{d.F35}</td>
                                    <td className="fl">{d.G35}</td>
                                    <td className="fl">{d.H35}</td>
                                    <td className="fl">{d.I35}</td>
                                    <td className="fl">{d.J35}</td>
                                    <td className="fl">{d.K35}</td>
                                    <td className="fl">{d.L35}</td>
                                    <td className="fl">{d.M35}</td>
                                    <td className="fl">{d.N35}</td>
                                    <td className="fl">{d.O35}</td>
                                    <td className="fl">{d.P35}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Titanium</td>
                                    <td className="fl">{d.B36}</td>
                                    <td className="fl">{d.C36}</td>
                                    <td className="fl">{d.D36}</td>
                                    <td className="fl">{d.E36}</td>
                                    <td className="fl">{d.F36}</td>
                                    <td className="fl">{d.G36}</td>
                                    <td className="fl">{d.H36}</td>
                                    <td className="fl">{d.I36}</td>
                                    <td className="fl">{d.J36}</td>
                                    <td className="fl">{d.K36}</td>
                                    <td className="fl">{d.L36}</td>
                                    <td className="fl">{d.M36}</td>
                                    <td className="fl">{d.N36}</td>
                                    <td className="fl">{d.O36}</td>
                                    <td className="fl">{d.P36}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Molybdenum</td>
                                    <td className="fl">{d.B37}</td>
                                    <td className="fl">{d.C37}</td>
                                    <td className="fl">{d.D37}</td>
                                    <td className="fl">{d.E37}</td>
                                    <td className="fl">{d.F37}</td>
                                    <td className="fl">{d.G37}</td>
                                    <td className="fl">{d.H37}</td>
                                    <td className="fl">{d.I37}</td>
                                    <td className="fl">{d.J37}</td>
                                    <td className="fl">{d.K37}</td>
                                    <td className="fl">{d.L37}</td>
                                    <td className="fl">{d.M37}</td>
                                    <td className="fl">{d.N37}</td>
                                    <td className="fl">{d.O37}</td>
                                    <td className="fl">{d.P37}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">Vanadium</td>
                                    <td className="fl">{d.B38}</td>
                                    <td className="fl">{d.C38}</td>
                                    <td className="fl">{d.D38}</td>
                                    <td className="fl">{d.E38}</td>
                                    <td className="fl">{d.F38}</td>
                                    <td className="fl">{d.G38}</td>
                                    <td className="fl">{d.H38}</td>
                                    <td className="fl">{d.I38}</td>
                                    <td className="fl">{d.J38}</td>
                                    <td className="fl">{d.K38}</td>
                                    <td className="fl">{d.L38}</td>
                                    <td className="fl">{d.M38}</td>
                                    <td className="fl">{d.N38}</td>
                                    <td className="fl">{d.O38}</td>
                                    <td className="fl">{d.P38}</td>
                                </tr>
                                <tr className="r">
                                    <td className="ft">MIDAS</td>
                                    <td className="fl">{d.B39}</td>
                                    <td className="fl">{d.C39}</td>
                                    <td className="fl">{d.D39}</td>
                                    <td className="fl">{d.E39}</td>
                                    <td className="fl">{d.F39}</td>
                                    <td className="fl">{d.G39}</td>
                                    <td className="fl">{d.H39}</td>
                                    <td className="fl">{d.I39}</td>
                                    <td className="fl">{d.J39}</td>
                                    <td className="fl">{d.K39}</td>
                                    <td className="fl">{d.L39}</td>
                                    <td className="fl">{d.M39}</td>
                                    <td className="fl">{d.N39}</td>
                                    <td className="fl">{d.O39}</td>
                                    <td className="fl">{d.P39}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default FobasEngineOil;
