import React, { Component } from "react";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Authorisation from "../../components/Authorisation";

class FobasEngineComplete extends Component {
    constructor(props){
        super(props);
        this.state = {
            completing: false,
            error:''
        };
    }
    fileUpload = (e) => {
        if (e) {
            e.preventDefault();
            if (e.target?.files && e.target.files.length > 0) {

                let file = e.target.files[0];
                let self = this;

                confirmAlert({
                    self: self,
                    file: file,
                    title: 'Complete Report Using File:',
                    message: file.name + ' [' + Authorisation.niceBytes(file.size) + ']',
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => {

                                self.setState({completing: true});

                                let cred = Authorisation.GetCredentials();
                                // aws-flame-complete
                                let url = 'https://m2ps3dpjsktzcypd5vgb5iicve0jvxdg.lambda-url.eu-west-2.on.aws/'
                                    + '?uid=' + cred.uid
                                    + '&pwd=' + cred.pwd
                                    + '&fn=' + encodeURIComponent(file.name)
                                    + '&id=' + Authorisation.shortGuid(self.props.data.Reports[0].Report_Id);

                                try {
                                    let xhr = new XMLHttpRequest();

                                    xhr.addEventListener("load", function (res) {

                                        var ret = Authorisation.checkError2(res);
                                        if (ret.isError) {
                                            self.setState(
                                                {
                                                    completing: false,
                                                    error: ret.Error,
                                                });
                                        }
                                        else {
                                            window.localStorage.setItem("fobasUpdated", "false");
                                            window.localStorage.setItem("fobasUpdated", "true");
                                            window.close();
                                        }
                                    });
                                    xhr.addEventListener("error", (e) => {
                                        self.setState(
                                            {
                                                completing: false,
                                                error: JSON.stringify(e),
                                            });
                                        // clear file (trick from StackOverflow)
                                        let rand = Math.random().toString(36);
                                        self.setState({ inputKey: rand });
                                    });

                                    xhr.open('post', url, true);
                                    xhr.setRequestHeader("Content-Type", file.type);
                                    xhr.setRequestHeader("x-filename", file.name);
                                    xhr.send(file);
                                }
                                catch (ex) {
                                    self.setState(
                                        {
                                            completing: false,
                                            error: JSON.stringify(ex),
                                        });
                                    // clear file (trick from StackOverflow)
                                    let rand = Math.random().toString(36);
                                    self.setState({ inputKey: rand });
                                }
                            }
                        },
                        {
                            label: 'Cancel',
                            onClick: () => { 
                                let cred = Authorisation.GetCredentials();
                                // clear file (trick from StackOverflow)
                                let rand = Math.random().toString(36);
                                self.setState({inputKey: rand});
                            }
                        }
                    ]
                });
            }
        }
    }

    render() {
        var r = this.props.data;

        var s = r.Ship;
        return (
            <div className="completeForm">
                <div className="formError" hidden={!this.state.error}>{this.state.error}</div>
                <div className="form-group">
                    <label hidden={this.state.completing} htmlFor="customFile">Select FOBAS Engine PDF document to complete Report</label>
                    <input hidden={this.state.completing} type="file" className="form-control-file" accept="application/pdf" id="customFile" onChange={(e)=>this.fileUpload(e)} key={this.state.inputKey || ''} />
                    <div hidden={(this.state.completing || !this.state.error)} className="invalid-feedback">{this.state.error}</div>
                    <div hidden={!this.state.completing}><span><span className='spinner-border spinner-border-sm' style={{ marginLeft: 10, marginRight: 5 }} role="status" aria-hidden="true"></span>Completing...</span></div>
                </div>
            </div>
        )
    }
}

export default FobasEngineComplete;
